import { flattenFormPaths, paramsToQueryString } from '..'
import removeDuplicateString from '../removeDuplicateString'

/**
 * Searches document for any anchor elements where the href attribute
 * is hardcoded to the quote verticals listed below,
 * and replaces the href with the form that's
 * passed in as an argument. Retains any query parameters, and utilizes the
 * current origin to construct the new href.
 *  Vertcals:
 * ['get-quote', 'auto-quote',
 * 'medicare-quote', 'health-insurance-quote']
 */

export function replaceHardcodedForms(
  destination:
    | {
        pathname: string
        query?: undefined
      }
    | {
        pathname: string
        query: any
      },
) {
  const quoteVerticals = flattenFormPaths()
  let queryString = ''

  for (const vertical of quoteVerticals) {
    queryString += `[href*="${vertical}"]`
  }

  const replaceQuery = queryString?.replace(/\]\[/g, '],[')
  const anchorSelectors = replaceQuery?.toString()

  const getQuoteBtns: NodeListOf<HTMLAnchorElement> =
    document.querySelectorAll(anchorSelectors)

  const queryParams = formatQueryParams(destination)

  if (getQuoteBtns) {
    getQuoteBtns.forEach((a: HTMLAnchorElement) => {
      const url = new URL(a.href)
      a.setAttribute(
        'href',
        `${window.origin}${destination.pathname}${
          url.search
            ? removeDuplicateString(`${url.search}&${queryParams}`)
            : queryParams
            ? `?${queryParams}`
            : ''
        }`,
      )
    })
  }
}

const formatQueryParams = (destination: any): string => {
  return 'query' in destination ? paramsToQueryString(destination.query) : ''
}
