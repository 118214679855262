/**
 * splitEvery - Splits a list into slices of the specified length.
 * @param items
 * @param n - length to split at
 */
function splitEvery<T>(items: T[], n: number): T[][] {
  const result = []
  let idx = 0
  while (idx < items.length) {
    result.push(items.slice(idx, (idx += n)))
  }
  return result
}

export default splitEvery
