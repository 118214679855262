import * as React from 'react'
import { Wrapper } from './Wrapper'
import { InputBox } from '../../../..'
import type { QuestionProps } from './types'

export const Email: React.FC<QuestionProps> = (props) => {
  const [value, setValue] = React.useState<string | undefined>(undefined)
  const [isValid, setIsValid] = React.useState(true)
  const [isDirty, setIsDirty] = React.useState(false)

  React.useEffect(() => {
    const initialValue = props.leadDataGetter.contactEmail ?? ''

    if (validate(initialValue)) {
      setValue(initialValue)
      setIsValid(true)
      props.setIsValid(true)
    }
  }, [])

  const handleChange = (value: string) => {
    setValue(value)
    setIsDirty(true)

    const newValueIsValid = validate(value)
    if (newValueIsValid) {
      props.leadDataSetter({ key: 'contactEmail', value })
    }

    setIsValid(newValueIsValid)
    props.setIsValid(newValueIsValid)
  }

  return (
    <Wrapper>
      <label className="sr-only" id="qf-email--label">
        Email
      </label>
      <InputBox
        id="qf-email"
        value={value}
        labelId="qf-email--label"
        error={isDirty && !isValid}
        errorMsg="Please enter your Email."
        name="qf-email"
        maxLength={100}
        onChange={handleChange}
        placeholder="email@provider.com"
        type="email"
      />
    </Wrapper>
  )
}

function validate(value: string): boolean {
  return (
    !!value
      ?.toLowerCase()
      ?.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ) ?? false
  )
}
