import * as React from 'react'
import { Wrapper } from './Wrapper'
import { Button } from '../../../..'
import type { QuestionProps } from './types'

export const Vehicles: React.FC<QuestionProps> = (props) => {
  const [selected, setSelected] = React.useState(false)

  React.useEffect(() => {
    const initialValue = props.leadDataGetter['secondVehicle']
    if (initialValue === undefined) {
      props.leadDataSetter({ key: 'secondVehicle', value: '0' })
    }
    setSelected(initialValue === '1')
  }, [])

  const handleClick = () => {
    const newSelectedValue = !selected
    setSelected(newSelectedValue)
    props.leadDataSetter({
      key: 'secondVehicle',
      value: newSelectedValue ? '1' : '0',
    })
  }

  return (
    <Wrapper>
      <Button
        id="qf-vehicles"
        name="qf-vehicles"
        classes={`${
          selected ? 'qf-btn--selected' : 'qf-btn--unselected'
        } qf-btn--base`}
        icon={<img alt="car" className="mr-4" src="/static/icons/car.svg" />}
        onClick={handleClick}
        role="transparent"
      >
        Multiple vehicles
      </Button>
    </Wrapper>
  )
}
