import { createContext, Dispatch, SetStateAction } from 'react'
import { RateQuote } from '../../types'
import setContextObjToLocalstorage from '../utils/context/setContextObjToLocalstorage'

export const Context = createContext<RateQuote.AppState>({
  clientLocation: { country: '', state: '', status: '' },
  user: { anonymousId: undefined, category: 'u65' },
  ringba: { numberPoolId: '' },
})

export const setStateContext = ({
  value,
  setState,
}: {
  value: RateQuote.AppState
  setState: Dispatch<SetStateAction<RateQuote.AppState>>
}) => {
  setContextObjToLocalstorage(value)
  setState(value)
}
