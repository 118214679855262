import React from 'react'

export interface FormLabelProps {
  children: any
  textAlign?: 'center' | 'left'
  className?: string
  for?: string
  as?: React.ElementType
}

export const FormLabel = (props: FormLabelProps) => {
  const {
    as: Component = 'h1',
    children,
    textAlign = 'center',
    className = '',
  } = props

  return (
    <Component
      tabIndex={1}
      {...props}
      className={`${className} min-h-8 md:min-h-9 text-blue-navy font-circular mx-auto w-full max-w-screen-lg text-2xl md:text-3xl ${
        textAlign === 'center' ? 'text-center' : 'text-left'
      }`}
    >
      {children}
    </Component>
  )
}
