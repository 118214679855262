import * as React from 'react'
import { Wrapper } from './Wrapper'
import { InputBox } from '../../../..'
import type { QuestionProps } from './types'
import { dobMask } from '@/utils'

export const Dob: React.FC<QuestionProps> = (props) => {
  const [value, setValue] = React.useState<string | undefined>(undefined)
  const [isValid, setIsValid] = React.useState(true)
  const [isDirty, setIsDirty] = React.useState(false)
  const getInitialValue = () => {
    if (
      !props.leadDataGetter.birthDay ||
      !props.leadDataGetter.birthMonth ||
      !props.leadDataGetter.birthYear
    )
      return ''
    return (
      props.leadDataGetter.birthDay +
      '/' +
      props.leadDataGetter.birthMonth +
      '/' +
      props.leadDataGetter.birthYear
    )
  }
  React.useEffect(() => {
    const initialValue = getInitialValue()

    if (validate(initialValue)) {
      setValue(initialValue)
      setIsValid(true)
      props.setIsValid(true)
    }
  }, [])

  const handleChange = (value: string) => {
    setValue(value)
    setIsDirty(true)
    const date = value.split('/')
    const bday = date[1]
    const bmonth = date[0]
    const byear = date[2]
    const newValueIsValid = validate(value)
    if (newValueIsValid) {
      props.leadDataSetter({ key: 'birthDay', value: bday })
      props.leadDataSetter({ key: 'birthMonth', value: bmonth })
      props.leadDataSetter({ key: 'birthYear', value: byear })
    }

    setIsValid(newValueIsValid)
    props.setIsValid(newValueIsValid)
  }

  return (
    <Wrapper>
      <label className="sr-only" id="qf-dob--label">
        Date Of Birth
      </label>
      <InputBox
        id="qf-dob"
        value={value}
        labelId="qf-dob--label"
        error={isDirty && !isValid}
        errorMsg="Please enter your Phone Number."
        name="qf-dob"
        onChange={handleChange}
        placeholder="01/01/2000"
        mask={dobMask}
        type="number"
      />
    </Wrapper>
  )
}

function validate(value: string): boolean {
  return (
    !!value
      ?.toLowerCase()
      ?.match(/^(\([0-9]{3}\)\s*|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/) ?? false
  )
}
