import React, { FC } from 'react'

export interface ProgressCounterProps {
  progress: {
    step: number
    denominator: number
  }
}

export const ProgressCounter: FC<ProgressCounterProps> = ({ progress }) => {
  const { step, denominator } = progress
  const validatedStep = step < 1 ? 1 : step > denominator ? denominator : step

  const renderPct = true
  return (
    <>
      <p
        aria-label="form-panel-root"
        className="text-blue-primary min-h-7 text-lg"
      >
        {renderPct ? (
          <Percent numerator={validatedStep - 1} denominator={denominator} />
        ) : (
          <Step numerator={validatedStep} denominator={denominator} />
        )}
      </p>
    </>
  )
}

const Step = ({
  numerator,
  denominator,
}: {
  numerator: number
  denominator: number
}) => (
  <span>
    <b className="font-medium">Step {numerator}</b> / {denominator}
  </span>
)

const Percent = ({
  numerator,
  denominator,
}: {
  numerator: number
  denominator: number
}) => (
  <span>
    <b className="font-medium">
      {Math.round((numerator / denominator) * 100)}%
    </b>{' '}
    Complete
  </span>
)
