import React from 'react'

export function Banner() {
  const [showBanner, setShowBanner] = React.useState(true)
  const dismissBanner = () => setShowBanner(false)
  return (
    <>
      {showBanner && (
        <div className="sm:mb-18 xxl:max-w-fit relative mx-2 my-8 grid max-w-fit grid-flow-col grid-rows-1 gap-x-4 rounded-lg bg-red-200 text-red-800 shadow-md sm:mx-auto sm:mt-0">
          <p className="py-8 pr-6 pl-8 text-base sm:py-10 sm:px-14 sm:text-lg">
            If you are outside of an open enrollment period and do not have a
            qualifying life event, you may not be eligible to elect or change
            coverage.
          </p>
          <button
            className="absolute right-0 mt-2 mr-2 h-7 w-7 sm:mt-3 sm:mr-3"
            onClick={dismissBanner}
            type="button"
          >
            <span className="sr-only">Dismiss</span>
            <div className="inline-block">
              <img alt="close icon" src="/static/icons/icon-close-alert.svg" />
            </div>
          </button>
        </div>
      )}
    </>
  )
}
