import React from 'react'
import { PortableText } from '@portabletext/react'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import 'swiper/css'

import { Pagination } from '@/components'
import { Card } from './Card'
import { Sanity } from '../../../../types/sanity/index'

export function Quotes({
  quotes,
  title,
}: {
  quotes: Sanity.Home['quotes']
  title: Sanity.Home['quotesHeadline']
}) {
  Swiper.use([Navigation])

  return (
    <div className="px-6 pb-10 sm:px-0">
      <div className="my-10 flex items-center justify-between">
        <PortableText value={title} />
        <Pagination ariaLabel="quotes" />
      </div>
      <ReactSwiper
        navigation={{
          nextEl: '.c-button-next',
          prevEl: '.c-button-prev',
        }}
        loop
        slidesPerView="auto"
      >
        {quotes.map((quote) => (
          <SwiperSlide key={quote._key} className="max-w-max">
            {({ isActive }) => <Card isFeatured={isActive} quote={quote} />}
          </SwiperSlide>
        ))}
      </ReactSwiper>
    </div>
  )
}
