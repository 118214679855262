import React from 'react'
import { NumberFormatBase, PatternFormat } from 'react-number-format'

function NumericFormatWrapper(props: any) {
  const isNumeric = () => {
    if (typeof props.format === 'function') return true
    return false
  }

  if (isNumeric()) {
    return <NumberFormatBase {...props} data-testid="number-format" />
  } else
    return (
      <PatternFormat
        {...props}
        format={props.format}
        value={props.value}
        data-testid="pattern-format"
      />
    )
}

export default NumericFormatWrapper
