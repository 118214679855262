import React, { FC } from 'react'
import { Img, Button } from '../..'
import Link from 'next/link'
import { handlePhoneOut } from '@/utils'
import { Segment } from '../../../../types/segment'
import shouldDisplayTty247 from '@/utils/should-display-tty-247/shouldDisplayTty247'
import TTY from '../../atoms/TTY'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import {
  getDefaultCallCenterValue,
  getIncrementalLiveAgents,
  isCallCenterOpen,
} from '@/utils/call-center'
import { useRouter } from 'next/router'

export interface NavBarProps {
  category: Segment.EventData['Checkout Started']['category']
  phoneNumber: string
  tty: string
}

export const NavBar: FC<NavBarProps> = (props: NavBarProps) => {
  const { category, tty } = props
  const phoneNumber = props.phoneNumber
  const [isOpen, setIsOpen] = React.useState(false)
  const [phoneIcon, setPhoneIcon] = React.useState('phone-cta-dark')
  const [liveAgents, setLiveAgents] = React.useState(0)

  const router = useRouter() 

  React.useEffect(() => {
    setLiveAgents(getDefaultCallCenterValue())
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      if (liveAgents > 10) setLiveAgents(getIncrementalLiveAgents(liveAgents))
    }, 20000)
  }, [liveAgents])

  const shouldHideLiveAgents = router.pathname.includes('/medicare/') //hide agent count on medicare advantage page

  return (
    <>
      <nav
        aria-label="main"
        className="xxxl:py-4 relative z-50 w-full bg-white py-6"
      >
        <div className="max-w-8xl xl:px-22 mx-auto px-5">
          <div className="flex justify-between">
            <div className="z-20 flex items-center lg:grow">
              <div>
                <Link href="/" aria-label="Home page">
                  <Img
                    classes="w-47 h-10"
                    skeleton={{ classes: 'w-47 h-10 bg-transparent' }}
                    src="/static/logos/rq-logo.svg"
                    alt="Rate Quote"
                  />
                </Link>
              </div>
            </div>
            {true ? (
              <div
                className={`${
                  shouldDisplayTty247(category) ? '' : ''
                } flex w-full items-center justify-end sm:flex`}
              >
                <a
                  href={`tel:+${phoneNumber}`}
                  className="text-circular text-blue-navy hover:text-blue-primary flex items-center text-2xl no-underline lg:text-xl"
                  onClick={() => handlePhoneOut({ phoneNumber, category })}
                  onMouseEnter={() => setPhoneIcon('phone-cta-blue')}
                  onMouseLeave={() => setPhoneIcon('phone-cta-dark')}
                >
                  <img
                    alt="phone"
                    className="mr-2 -mt-6 w-4 xl:h-6 xl:w-4"
                    src={`/static/icons/${phoneIcon}.svg`}
                  />
                  <div>
                    <span className="flex flex text-sm sm:flex  md:text-lg">
                      {phoneNumber}
                      {tty}
                    </span>
                    {shouldDisplayTty247(category) ? (
                      <div className="-mt-1 text-xs">
                        Speak with a Licensed Insurance Agent
                      </div>
                    ) : (
                      <div className="-mt-2 text-xs">&nbsp;</div>
                    )}
                    <br />
                    {!shouldHideLiveAgents && (
                      <div className="-mt-8 text-xs">
                        {liveAgents} live agents standing by!
                      </div>
                    )}
                  </div>
                </a>
              </div>
            ) : (
              ''
            )}
            <div className="z-20 flex items-center justify-end">
              {shouldDisplayTty247(category) && (
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className={`text-blue-navy hover:text-blue-primary z-20  flex inline-flex h-9 w-9 items-center justify-center rounded-md sm:hidden`}
                >
                  <div className="flex h-9 w-9 items-center justify-end">
                    {isOpen ? (
                      <>
                        <span className="sr-only">Close Menu</span>
                        <XIcon
                          aria-hidden="true"
                          className={`text-blue-navy z-10 h-9 w-9 fill-current`}
                        />
                      </>
                    ) : (
                      <>
                        <span className="sr-only">Open menu</span>
                        <MenuIcon
                          className={`text-black-bg hover:text-blue-navy z-10 h-7 w-7 fill-current`}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </div>
                </button>
              )}
            </div>
          </div>
          {isOpen && (
            <div
              className="c-bg-blur absolute top-0 z-10 min-w-full rounded-b-sm pt-20 lg:hidden"
              id="mobile-menu"
            >
              <div className="-ml-10 grid justify-items-center pb-10">
                <Button
                  classes={`mt-5 px-5 w-47 mx-auto ${'sm:hidden'} flex`}
                  icon={
                    <img
                      className="w-4-1/2 mr-3-3/4"
                      src="/static/icons/phone-cta-white.svg"
                    />
                  }
                  onClick={() => handlePhoneOut({ phoneNumber, category })}
                >
                  <a
                    className="xs:text-lg text-sm text-white no-underline"
                    href={`tel:+${phoneNumber}`}
                  >
                    {phoneNumber} <TTY category={category} />
                  </a>
                </Button>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  )
}
