/**
 * Use this file to define and export global constants.
 */

module.exports = {
  contextLocalstoragePrefix: 'context--',
  VERTICALS: [
    {
      label: 'Auto Insurance',
      SEO: 'Auto Insurance',
      category: 'auto-insurance',
      defaultAdvertorial: 'savings/auto',
      numberPoolId: 'CAe9c2efd291d14cb0a84f9a7dc31e1fb7',
      forms: [{ isLive: false, slug: 'auto-quote' }],
    },
    {
      label: 'Medicare Advantage',
      SEO: 'Medicare Insurance',
      category: 'health-insurance',
      defaultAdvertorial: 'medicare/call-today',
      numberPoolId: 'CAc6d5da207197409691a33cfe65e06c12',
      organicNumberPoolId: 'CA1e424b322cc7483db6181bd44a289c31',
      forms: [{ isLive: true, slug: 'medicare-quote' }],
      licenseNum: '3001645811',
      smid: 'SMID MULTI-PLAN_SQ7Ld34CQz2024_C',
    },
    {
      label: 'Health Insurance',
      SEO: 'U65 Insurance',
      category: 'u65',
      numberPoolId: 'CA7f53e167253a4d3d8d6c2a19b346676e',
      organicNumberPoolId: 'CA09984c5c65814d3e987d65fcfaecb653',
      defaultAdvertorial: 'health-insurance/coverage',
      forms: [
        { isLive: true, slug: 'health-insurance-quote' },
        { isLive: true, slug: 'get-quote' },
      ],
    },
  ],
  homepageSerializer: {
    _updatedAt: '2024-01-17T03:04:05Z',
    _rev: 'iF5f03cJB84MRno3e2sYXd',
    title: 'Home',
    _createdAt: '2021-07-23T22:15:08Z',
    quotesHeadline: [
      {
        style: 'h3',
        _key: 'ace82bd119fc',
        markDefs: [],
        children: [
          {
            _type: 'span',
            marks: [],
            text: 'In ',
            _key: 'c0a406ad88aa',
          },
          {
            marks: ['blueAccent'],
            text: 'good',
            _key: '1482d29f7d52',
            _type: 'span',
          },
          {
            text: ' company.',
            _key: '5d94c2c6bef8',
            _type: 'span',
            marks: [],
          },
        ],
        _type: 'block',
      },
    ],
    publications: [
      {
        _key: '6c15c3494697',
        _type: 'publication',
        name: 'Forbes',
        logo: 'https://cdn.sanity.io/images/6x0nzsy3/production/12b3b7e79a1ede437498edbe29d86d9cf18568da-114x30.png',
      },
      {
        name: 'USA Today',
        logo: 'https://cdn.sanity.io/images/6x0nzsy3/production/2e63541fddaa8d138447322c43c191cc0d79ac71-226x35.png',
        _key: 'aa368043cece',
        _type: 'publication',
      },
      {
        name: 'The Wall Street Journal',
        logo: 'https://cdn.sanity.io/images/6x0nzsy3/production/378102b67895b61ff1b9941f6449936635f770e6-245x22.png',
        _key: 'f96244097d79',
        _type: 'publication',
      },
      {
        _key: '5ffffee8468b',
        _type: 'publication',
        name: 'CNN',
        logo: 'https://cdn.sanity.io/images/6x0nzsy3/production/3e279f84611c8d0bdd893af514c7d81ae81aae91-96x46.png',
      },
      {
        _key: '76130ecb1e62',
        _type: 'publication',
        name: 'HuffPost',
        logo: 'https://cdn.sanity.io/images/6x0nzsy3/production/ef874a8a86231178a6c912b37a326e859ec48ac6-200x24.png',
      },
    ],
    seoTitle: 'RateQuote',
    quotes: [
      {
        _key: 'd8360bf8dc63',
        quote:
          'I was won over by the ease of use, and ability to compare carriers to find the plan I was looking for. ',
        profile:
          'https://cdn.sanity.io/images/6x0nzsy3/production/f2e77dbdfc0920805db70adb930df61e318651fa-46x46.svg',
        _type: 'quote',
        name: 'Stan Barnhill',
      },
      {
        _type: 'quote',
        name: 'Brittany Gonzalez',
        _key: '1e0993636295',
        quote:
          "I enjoyed the ability to see multiple quotes from different carriers that I didn't even know were available!",
        profile:
          'https://cdn.sanity.io/images/6x0nzsy3/production/2d1d4a6757a46bade66eba4da729c5eb189a9166-46x46.svg',
      },
      {
        _key: 'c3bb6575407f',
        quote:
          'It only took me minutes to compare and find a quote that worked for me from a number of carriers.',
        profile:
          'https://cdn.sanity.io/images/6x0nzsy3/production/26ceb659ad71b306889e6a164c6a977da531b552-46x46.svg',
        _type: 'quote',
        name: 'Jennifer Burns',
      },
      {
        _key: 'e317c4ce1c1c',
        quote:
          'Comparison shopping can be tedious, but I was able to see everything in one place.  It felt like I was guided to a policy instead of having to search for one.',
        profile:
          'https://cdn.sanity.io/images/6x0nzsy3/production/2ab27be150dc985f711452dd4cc78be6883e0aec-46x46.svg',
        _type: 'quote',
        name: 'Adam Park',
      },
      {
        quote:
          "I now have peace of mind that I'm paying the most competitive price for my needs!",
        profile:
          'https://cdn.sanity.io/images/6x0nzsy3/production/2e831c6723f0213f1f745fb6689e213e652ff655-46x46.svg',
        _type: 'quote',
        name: 'Megan Owen ',
        _key: '6f05b4971d26',
      },
      {
        profile:
          'https://cdn.sanity.io/images/6x0nzsy3/production/ac7d9972e9ac3c7f30ee26d225945bba548e93f4-46x46.svg',
        _type: 'quote',
        name: 'Rick Brown',
        _key: 'f73adeffad73',
        quote:
          "Easy to follow and I don't feel bombarded with ads, truly only takes minutes to see quotes.",
      },
      {
        _type: 'quote',
        name: 'Amie Bell',
        _key: 'b5db8bfae11836f4ebbe9383dc767237',
        quote: 'Fast, easy, and a simple way to compare a range of carriers.',
        profile:
          'https://cdn.sanity.io/images/6x0nzsy3/production/212632b9fafd869dfca7d465c0a5acbe63cd4343-46x46.svg',
      },
      {
        profile:
          'https://cdn.sanity.io/images/6x0nzsy3/production/2cf7d8101b4486bcb158cadf01d2fd2253413b99-46x46.svg',
        _type: 'quote',
        name: 'John Burton',
        _key: 'd2fb0304518345f8fc038959047cb253',
        quote:
          'I enjoyed the ability to pick up the phone and in moments speak with a licensed agent that can personalize quotes based on my needs.',
      },
    ],
    leftColumn: [
      {
        _type: 'block',
        style: 'h2',
        _key: '349346490949',
        markDefs: [],
        children: [
          {
            _type: 'span',
            marks: [],
            text: 'What makes ',
            _key: 'bce0292d4d2a',
          },
          {
            _type: 'span',
            marks: ['blueAccent'],
            text: 'RateQuote Health Insurance ',
            _key: 'fde6b61a4103',
          },
          {
            _type: 'span',
            marks: [],
            text: 'different?',
            _key: '2abd7533afc1',
          },
        ],
      },
      {
        markDefs: [],
        children: [
          {
            _type: 'span',
            marks: [],
            text: 'RateQuote Health and Medicare Insurance uses the GreatQuote matching algorithm to help you find the best Health or Medicare coverage. By comparing options from multiple providers, RateQuote aims to save you both time and money. This approach ensures you get the coverage that best suits your needs without being limited to a single insurance provider.',
            _key: '03bb3ef55cf90',
          },
        ],
        _type: 'block',
        style: 'normal',
        _key: '7714b15d8755',
      },
      {
        buttonText: 'Get Started',
        _type: 'button',
        _key: '2ef7ae6ce0ce',
        buttonUrl: 'https://www.ratequote.com/get-quote',
      },
    ],
    heroImage: [
      {
        image: 'https://d1dfazostc6jze.cloudfront.net/health-insurance.webp',
        imageId: 'u65',
        _key: '61a096d2d1fa',
      },
      {
        image: 'https://d1dfazostc6jze.cloudfront.net/medical-insurance.webp',
        imageId: 'health-insurance',
        _key: 'ec4dfce38fb5',
      },
    ],
    _type: 'home',
    _id: 'home',
    rightColumn: [
      {
        _key: 'cb87c925d668',
        title: 'Independent Marketplace Agency',
        _type: 'feature',
        icon: 'https://cdn.sanity.io/images/6x0nzsy3/production/4364b857462f876eee859bdac1bad388626c41f1-46x47.svg',
        description: "Our team of licensed health insurance agents is on standby, ready to assist you in comparing private health insurance options or coverage through the Affordable Care Act Health Insurance Marketplace. Using advanced technology, RateQuote's licensed agents can effortlessly compare policies side-by-side, ensuring you find the best coverage for you and your loved ones.\n\n",
      },
      {
        icon: 'https://cdn.sanity.io/images/6x0nzsy3/production/993b51fae17c22e37180fe8ecb4011de739948e3-46x47.svg',
        description:
          'Our health insurance algorithm does the shopping for you across multiple carriers and products, completely free and with no obligations.',
        _key: '8a073bd15197',
        title: 'AI Healthcare Technology ',
        _type: 'feature',
      },
      {
        title: 'We Care',
        _type: 'feature',
        icon: 'https://cdn.sanity.io/images/6x0nzsy3/production/8130c0ec56c970c1fd29865f93e6b4ea304d3562-46x47.svg',
        description: 'We prioritize your best interests and will only recommend health insurance policies that make sense for you.',
        _key: '463d288440ac',
      },
    ],
    heroContent: [
      {
        style: 'h1',
        _key: '8f436da3d84c',
        markDefs: [],
        children: [
          {
            _type: 'span',
            marks: [],
            text: 'Compare ',
            _key: '956473d675a1',
          },
          {
            _type: 'span',
            marks: ['blueAccent'],
            text: 'Health Insurance',
            _key: '939afb7a474f',
          },
          {
            _type: 'span',
            marks: [],
            text: ' Plans',
            _key: '62fb14ca8558',
          },
        ],
        _type: 'block',
      },
      {
        _type: 'block',
        style: 'normal',
        _key: '97672970ebea',
        markDefs: [],
        children: [
          {
            _type: 'span',
            marks: [],
            text: '5 minutes. Hundreds of health and medicare insurance rates side-by-side. It’s that easy.\n',
            _key: '2b2c11234440',
          },
        ],
      },
      {
        buttonText: 'View Quotes',
        dropdownOptions: [
          {
            _key: '21db17183c6a',
            _type: 'dropdownOption',
            label: 'Health Insurance',
            url: 'https://www.ratequote.com/health-insurance-quote/?step=1',
            value: 'u65',
          },
          {
            _key: '6b5f693cca3f',
            _type: 'dropdownOption',
            label: 'Medicare Insurance',
            url: 'https://www.ratequote.com/medicare-quote/?step=1',
            value: 'health-insurance',
          },
        ],
        _type: 'dropdownAction',
        _key: '0b450f157a4a',
      },
    ],
    includeInSitemap: false,
    seoDescription: 'Compare Insurance Rates',
  },
}
