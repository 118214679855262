import * as Sentry from '@sentry/nextjs'
import { ls } from '../local-storage'

// Formerly getRingbaPhone via sovereign/rq-www
export function getRingbaPhoneNumber() {
  try {
    let item = -1
    let lsJsTag
    if (window.ringba_known_numbers && window?.ringba_known_numbers[0]?.jstag) {
      lsJsTag = ls.getItem(
        `ringbaNumber_${window.ringba_known_numbers[0].jstag}`,
      )
    }
    item = lsJsTag ? lsJsTag.displayNumber : item
    return item
  } catch (e) {
    Sentry.captureException(e)
  }
}
