import React from 'react'
import { PortableTextComponents } from '@portabletext/react'
import { BlockQuote } from './BlockQuote'
import { CustomButton } from './Button'
import { CustomDropdownAction } from './DropdownAction'
import { CustomInputAction } from './InputAction'
import Table from './Table'

/**
 * Custom component to render Portable Text. This is passed to the Provider
 * in _app.tsx to provide default components for each known type of Portable Text.
 *
 * If you need to add a new type of component to the serializer, you can do so here.
 *
 * If you need to render a custom component that deviates from the default here,
 * you can simply override the default component for a given type, e.g:
   <PortableText value={props.value} components={{
      block: {
          normal: (props: any) => (
            <p className="c-custom-styling-for-p">
              {props.children}
            </p>
          ),
        },
      }}
   />

   ^note that you only need to specify the component for the type you want to override.
   All the other components will still render as the default.
 */
export const SanitySerializers: PortableTextComponents = {
  block: {
    blockquote: BlockQuote,
    h1: (props: any) => <h1 className="c-sanity-h1">{props.children}</h1>,
    h2: (props: any) => <h2 className="c-sanity-h2">{props.children}</h2>,
    h3: (props: any) => <h3 className="c-sanity-h3">{props.children}</h3>,
    normal: (props: any) => <p className="c-sanity-p">{props.children}</p>,
    small: (props: any) => <p className="c-sanity-sm">{props.children}</p>,
  },
  list: {
    bullet: ({ children }) => (
      <ul className="font-roboto text-blue-navy ml-8 mb-6 list-disc text-xl font-normal leading-9">
        {children}
      </ul>
    ),
  },
  marks: {
    blueAccent: (props: any) => (
      <span className="text-blue-primary">{props.children}</span>
    ),
    link: (props: any) => (
      <a
        className="text-blue-primary hover:text-blue-navy no-underline "
        href={props.value.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.text}
      </a>
    ),
  },
  types: {
    button: CustomButton,
    dropdownAction: CustomDropdownAction,
    inputAction: CustomInputAction,
    addTable: Table,
  },
}
