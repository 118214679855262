import React, { FC } from 'react'
import { Button, Img } from '../..'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { getCtaDestination, getPhoneNumber } from '@/utils'
import phoneWhite from '../../../public/landing/phone-white.png'
import { Segment } from '../../../../types/segment'
import {
  getDefaultCallCenterValue,
  getIncrementalLiveAgents,
} from '@/utils/call-center'
import { u65PhoneOut, medicarePhoneOut } from '@/clients/segment'

export interface LandingNavBarProps {
  category?: Segment.Category
  onForm?: boolean
  phoneNumber?: string
  results?: boolean
  partnerLogo?: string
}

export const LandingNavbar: FC<LandingNavBarProps> = (
  props: LandingNavBarProps,
) => {
  const { category = 'u65', onForm, results = false, partnerLogo } = props
  const [, setCtaDestination] = React.useState({
    pathname: '/get-quote',
  })
  const router = useRouter()
  const navBarElement = React.useRef(null)
  const [liveAgents, setLiveAgents] = React.useState(0)

  const getStaticPhoneNumber = () => {
    if (router.pathname.includes('/u65')) {
      return '18884711901'
    } else if (router.pathname.includes('/medicare')) {
      return '18774230028'
    }
    return props.phoneNumber || getPhoneNumber(category)
  }

  const phoneNumber = getStaticPhoneNumber()

  React.useEffect(() => {
    const dest = getCtaDestination(category)
    setCtaDestination(dest)
    router.prefetch(dest.pathname)
  }, [category])

  React.useEffect(() => {
    setLiveAgents(getDefaultCallCenterValue())
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      if (liveAgents > 10) setLiveAgents(getIncrementalLiveAgents(liveAgents))
    }, 20000)
  }, [liveAgents])

  return (
    <>
      <nav
        aria-label="main"
        className={`z-50 w-full py-6 ${'bg-white-bg sticky top-0 shadow-md'}`}
        ref={navBarElement}
        style={{
          transition:
            'padding 100ms ease-in-out, margin 100ms ease-in-out, background-color 100ms ease-in-out',
        }}
      >
        <div className="max-w-8xl xl:px-22 mx-auto px-5">
          <div className="flex justify-between">
            <div className="z-20 flex items-center lg:grow">
              <div
                className={`${
                  onForm || results ? 'xxs:block' : 'xs:block'
                } hidden`}
              >
                <Link
                  href="/"
                  aria-label="Home page"
                  className="flex items-center"
                >
                  <Img
                    classes="w-47 h-10"
                    skeleton={{ classes: 'w-47 h-10 bg-transparent' }}
                    src="/static/logos/rq-logo.svg"
                    alt="Rate Quote"
                  />
                  {partnerLogo && (
                    <>
                      <span className="mr-2 -ml-2 text-3xl">+</span>
                      <Img
                        src={partnerLogo}
                        alt="RateQuote Partner"
                        classes="w-28"
                      />
                    </>
                  )}
                </Link>
              </div>
              <div
                className={`${onForm || results ? 'xxs:hidden' : 'xs:hidden'}`}
              >
                <Link
                  href="/"
                  aria-label="Home page"
                  className="flex items-center"
                >
                  <Img
                    classes="h-11 w-11"
                    skeleton={{ classes: 'w-11 h-11 bg-transparent' }}
                    src="/static/logos/rq-logo-no-text.svg"
                    alt="Rate Quote"
                  />
                  {partnerLogo && (
                    <>
                      <span className="ml-2 mr-2 text-3xl">+</span>
                      <Img
                        src={partnerLogo}
                        alt="RateQuote Partner"
                        classes="w-28"
                      />
                    </>
                  )}
                </Link>
              </div>
            </div>
            {!partnerLogo && (
              <div className="absolute right-2 z-20 -mt-2 flex grow items-center justify-end">
                {router.pathname.includes('/u65') ? (
                  <a href={`tel:+18887791573`} className="no-underline">
                    <Button
                      classes="max-w-32 mx-auto text-base px-5 px-3 xxs:px-4 py-0"
                      onClick={() => u65PhoneOut()}
                    >
                      <img src={phoneWhite.src} className="font-dm" />
                      <div className="text-3xxs ml-2 text-left font-light">
                        <div>Call: 1.888.779.1573</div>
                        <div className="-mt-2">8am-6pm EST</div>
                      </div>
                    </Button>
                  </a>
                ) : router.pathname.includes('/medicare') ? (
                  <a href={`tel:+18774230028`} className="no-underline">
                    <Button
                      classes="max-w-32 mx-auto text-base px-5 px-3 xxs:px-4 py-0"
                      onClick={() => medicarePhoneOut()}
                    >
                      <img src={phoneWhite.src} className="font-dm" />
                      <div className="text-3xxs ml-2 text-left font-light">
                        <div>Call: 1.877.423.0028 TTY-711</div>
                        <div className="-mt-2">
                          To Speak with a Licensed Sales Agent
                        </div>
                        <div className="-mt-2">Available M-F 8am - 6pm EST</div>
                      </div>
                    </Button>
                  </a>
                ) : router.pathname.includes('/health') ? (
                  <a href={`tel:+18557134838`} className="no-underline">
                    <Button
                      classes="max-w-32 mx-auto text-base px-5 px-3 xxs:px-4 py-0"
                      onClick={() => medicarePhoneOut()}
                    >
                      <img src={phoneWhite.src} className="font-dm" />
                      <div className="text-3xxs ml-2 text-left font-light">
                        <div>Call: 1.855.713.4838 TTY-711</div>
                        <div className="-mt-2">
                          To Speak with a Licensed Sales Agent
                        </div>
                        <div className="-mt-2">Available M-F 8am - 6pm EST</div>
                      </div>
                    </Button>
                  </a>
                ) : category === 'orca' ? (
                  <a href={`tel:+1.855.713.4838`} className="no-underline">
                    <Button
                      classes="max-w-32 mx-auto text-base px-5 px-3 xxs:px-4 py-0"
                      onClick={() => u65PhoneOut()}
                    >
                      <img src={phoneWhite.src} className="font-dm" />
                      <div className="text-3xxs ml-2 text-left font-light">
                        <div>Call: 18887791573</div>
                        <div className="-mt-2">8am-6pm EST</div>
                      </div>
                    </Button>
                  </a>
                ) : (
                  <a href={`tel:+${phoneNumber}`} className="no-underline">
                    <Button
                      classes="max-w-32 mx-auto text-base px-5 px-3 xxs:px-4 py-0"
                      onClick={() => medicarePhoneOut()}
                    >
                      <img src={phoneWhite.src} className="font-dm" />
                      <div className="text-3xxs ml-2 text-left font-light">
                        <div>
                          Call: {phoneNumber}{' '}
                          {router.pathname.includes('/g-medicare') && 'TTY-711'}
                        </div>
                        <div className="-mt-2">
                          To Speak with a Licensed Sales Agent
                        </div>
                        <div className="-mt-2">Available M-F 8am - 6pm EST</div>
                      </div>
                    </Button>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  )
}
