import React from 'react'
import { NavBar } from './NavBar'
import { StickyCTA } from './StickyCTA'
import { CookieDisclaimer, Footer } from '../..'
import { Segment } from '../../../../types/segment'
import { getPhoneNumber, getLicenseNumFromCategory } from '@/utils'

export interface AdvertorialLayoutProps {
  children: React.ReactNode
  footerLicenseNum: string
  footerSmid: string
  stickyFooterCtaText: string
  tty: string
  category: Segment.EventData['Checkout Started']['category']
}

export function AdvertorialLayout(props: AdvertorialLayoutProps) {
  const { children, stickyFooterCtaText, tty, category } = props
  const phoneNumber = getPhoneNumber(category)

  const [offsetHeight, setOffsetHeight] = React.useState(0)

  React.useEffect(() => {
    const ctaEl = document.getElementById('sticky-cta')

    if (ctaEl) {
      const ctaHeight = ctaEl.getBoundingClientRect().height
      setOffsetHeight(ctaHeight)
    } else {
      setOffsetHeight(0)
    }
  }, [])

  return (
    <div className="bg-white-bg relative flex min-h-screen flex-col overflow-x-clip">
      <NavBar category={category} phoneNumber={phoneNumber} tty={tty} />
      <main className="max-w-8xl lg:px-30 mx-auto flex w-full grow flex-col justify-between px-5 sm:px-10">
        {children}
      </main>
      <CookieDisclaimer />
      <div
        style={{
          marginBottom: `${offsetHeight}px`,
        }}
      >
        <Footer {...getLicenseNumFromCategory(category)} />
      </div>
      {stickyFooterCtaText && (
        <StickyCTA
          category={category}
          phoneNumber={phoneNumber}
          text={stickyFooterCtaText}
          tty={tty}
        />
      )}
    </div>
  )
}
