// utility functions for FormPanel components

import { default as zipcodes, ZipCode } from 'zipcodes'

export const getGoogleMaps = () => {
  const googleMapsPromise = new Promise((resolve) => {
    /*global google*/
    window.resolveGoogleMapsPromise = () => {
      // @ts-ignore
      resolve(google)
      delete window.resolveGoogleMapsPromise
    }

    if (
      !scriptExists(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBXDG6yc2bQUCGmqS_Qe9FX6ZF8fdh8NEA&libraries=places&callback=resolveGoogleMapsPromise',
      )
    ) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBXDG6yc2bQUCGmqS_Qe9FX6ZF8fdh8NEA&libraries=places&callback=resolveGoogleMapsPromise'
      document.head.appendChild(script)
    } else window.resolveGoogleMapsPromise()
  })

  return googleMapsPromise
}

export function getLocation(zipcode: string | null): ZipCode | undefined {
  return zipcode ? zipcodes.lookup(zipcode) : undefined
}

function scriptExists(url: string): boolean {
  const scripts = window.document.getElementsByTagName('script')
  if (scripts) {
    for (let i = 0; i < scripts.length; i++)
      if (scripts[i].src === url) return true
  }
  return false
}

export const STATES = [
  { value: 'AL' },
  { value: 'AK' },
  { value: 'AR' },
  { value: 'AZ' },
  { value: 'CA' },
  { value: 'CO' },
  { value: 'CT' },
  { value: 'DC' },
  { value: 'DE' },
  { value: 'FL' },
  { value: 'GA' },
  { value: 'HI' },
  { value: 'IA' },
  { value: 'ID' },
  { value: 'IL' },
  { value: 'IN' },
  { value: 'KS' },
  { value: 'KY' },
  { value: 'LA' },
  { value: 'MA' },
  { value: 'MD' },
  { value: 'ME' },
  { value: 'MI' },
  { value: 'MN' },
  { value: 'MO' },
  { value: 'MS' },
  { value: 'MT' },
  { value: 'NC' },
  { value: 'ND' },
  { value: 'NE' },
  { value: 'NH' },
  { value: 'NJ' },
  { value: 'NM' },
  { value: 'NV' },
  { value: 'NY' },
  { value: 'OH' },
  { value: 'OK' },
  { value: 'OR' },
  { value: 'PA' },
  { value: 'RI' },
  { value: 'SC' },
  { value: 'SD' },
  { value: 'TN' },
  { value: 'TX' },
  { value: 'UT' },
  { value: 'VA' },
  { value: 'VT' },
  { value: 'WA' },
  { value: 'WI' },
  { value: 'WV' },
  { value: 'WY' },
]
