import React from 'react'
import Link from 'next/link'

export const NavItem = ({
  href,
  text,
  classes = '',
  onClick,
}: {
  href: string
  text: string
  classes?: string
  onClick?: () => void
}) => (
  <Link href={href} className={`c-nav-item-base ${classes}`} onClick={onClick}>
    {text}
  </Link>
)
