const validFirstDigitPhone = (firstDigit: string): string => {
  if (Number(firstDigit) > 1) {
    return firstDigit
  } else return ''
}

export function phoneMask(val: string) {
  const firstDigit = validFirstDigitPhone(val.substring(0, 1))
  const code = val.substring(1, 3)
  const firstPartPhoneNumber = val.substring(3, 6)
  const secondPartPhoneNumber = val.substring(6, 10)

  return (
    (firstDigit.length ? '(' : '') +
    firstDigit +
    code +
    (code.length === 2 ? ') ' : '') +
    firstPartPhoneNumber +
    (firstPartPhoneNumber.length === 3 ? '-' : '') +
    secondPartPhoneNumber
  )
}
