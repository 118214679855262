import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import popupImage from '../../../public/static/other/Popup.png'

const Popup = () => {
  const [showPopup, setShowPopup] = useState(false)
  const phoneNumber = '1.866.791.1509'

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setShowPopup(true)
      }, 180000) // Set to 2mins, adjust as needed
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!showPopup || process.env.NODE_ENV === 'development') return null

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div
        className={`relative rounded text-white ${
          window.innerWidth <= 768 ? 'w-11/12' : 'w-1/3'
        }`}
      >
        <div className="relative">
          <Image
            src={popupImage}
            alt="Popup"
            className="rounded"
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
          <button
            className="z-60 absolute top-2 left-5 text-2xl font-bold text-white shadow-lg"
            onClick={() => setShowPopup(false)}
          >
            x
          </button>
        </div>
        <div className="mt-5 flex justify-center">
          <button
            onClick={handleCallClick}
            className={`bg-blue-primary z-60 animate-zoom transform rounded-xl border-8 border-black py-4 px-10 text-white shadow-2xl transition-transform duration-300 hover:scale-105 hover:bg-blue-700 ${
              window.innerWidth <= 768 ? 'w-full text-xl' : 'w-auto text-lg'
            }`}
          >
            <div className="font-circular whitespace-nowrap text-center font-normal">
              Click to Call Now!
            </div>
            <div className="font-circular whitespace-nowrap text-center font-medium">
              {phoneNumber}
            </div>
          </button>
        </div>
      </div>
      <style jsx>{`
        @keyframes zoom {
          0%,
          100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.05);
          }
        }
        .animate-zoom {
          animation: zoom 3s infinite;
        }
      `}</style>
    </div>
  )
}

export default Popup
