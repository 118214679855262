import React, { useState, FC, useEffect, useRef } from 'react'
import { ErrorMessage } from '../../'
import NumericFormatWrapper from '../NumericFormatWrapper/NumericFormatWrapper'
import { checkInputValidity, getInputMode } from './utils'

export interface InputBoxProps {
  /**
   * alt attribute for icon image
   */
  altIcon?: string
  /**
   * Allows the browser to guide the user in filling out the form field values.
   */
  autoComplete?: string
  /**
   * Unique styling applied to a single input that will not be reused.  Try to apply styling to this child component when possible.
   */
  classes?: string
  /**
   * Input will not be reachable. Be sure to remove required when applying 'disabled'.
   */
  disabled?: boolean
  /**
   * Pass the error state to a single input that will not be reused.  Try to apply error state in the global util checkInputValidity() instead when possible.  If you are going to pass this error prop this you should also pass a unique Error Message with the ErrorMsg prop.
   */
  error?: boolean
  /**
   * Pass a unique error message to the input.  Try to apply error messaging in the global util checkInputValidity() instead when possible.
   */
  errorMsg?: string
  hasError?: React.Dispatch<React.SetStateAction<boolean>>
  /**
   * The path to an icon which will be placed at the beginning of the input.
   */
  iconLeft?: string
  /**
   * Required and unique for each input.  Useful for built-in html accessibilty and makes the item individually reachable on the page.  In general should be all lowercase and is used to identify DOM elements.
   */
  id: string
  /**
   * inputMode is pulled as default from the 'type' assigned but can be overridden here if needed.
   */
  inputMode?:
    | 'text'
    | 'decimal'
    | 'numeric'
    | 'tel'
    | 'email'
    | 'url'
    | 'search'
    | 'none'
  /**
   * Passes to the input aria-labelby
   */
  labelId?: string
  /**
   * if the component has a mask it will render the maskedinput instead with the mask
   */
  mask?: string | ((val: string) => string)
  /**
   *  Restriction on number of value items accepted.
   */
  maxLength?: number
  /**
   * Required and unqiue for each input.  In general the name should be all lowercase and is thought of as a key in the form field values.
   */
  name: string
  /**
   * Required function to handle the value entered into the parent because the onChange will pass the value of the event.target.value to the component. For example, will likely setState() or pass the value somewhere from the input.
   */
  onChange: (value: string) => void
  /**
   * Function triggered when user clicks inside input
   */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  /**
   * Function triggered when user clicks the enter button
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  /**
   * Unique regex pattern restriction to be applied to the input. This will give a hard restriction from entering the value so it is best to pass validation to the global util of checkInputValidity() when possible.
   */
  pattern?: string
  /**
   * Often thought of as helper text inside the input to give an example of format accepted.
   * */
  placeholder?: string
  /**
   * Some input boxes require values entered and some do not.
   */
  required?: boolean
  /**
   * Access HTML built-in spellchecker.  It will only appy the red scriggly lines after word entry completion.
   */
  spellCheck?: boolean
  /**
   * HTML type is important for accessibilty.  Eventhough many HTML type features are disabled in this React app, it is still important both for screen reader input usability.  Should be given in all lowercase.
   * @default text
   */
  type?:
    | 'date'
    | 'datetime-local'
    | 'color'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'name'
    | 'number'
    | 'password'
    | 'range'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
  /**
   * Value of input.
   */
  value: string | number | readonly string[] | undefined
}

export const InputBox: FC<InputBoxProps> = (props: InputBoxProps) => {
  const {
    altIcon = '',
    autoComplete = 'off',
    classes,
    disabled,
    error = false,
    errorMsg = 'Please enter a valid value.',
    hasError,
    iconLeft,
    id,
    inputMode,
    labelId,
    mask,
    maxLength,
    name,
    onChange,
    onFocus,
    onKeyDown,
    pattern,
    placeholder,
    required,
    spellCheck,
    type = 'text',
    value,
  } = props

  const [errMsg, setErrMsg] = useState(errorMsg)
  const [active, setActive] = useState(false)
  const [valid, setValid] = useState(true)
  const derivedInputMode = getInputMode(type, inputMode)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (error) {
      setValid(false)
      hasError && hasError(true)
      inputRef.current?.focus()
    }
  }, [error])

  const handleOnBlur = () => {
    const invalid = checkInputValidity(value, type, errorMsg, required)
    setErrMsg(invalid || errorMsg)
    invalid ? setValid(false) : setValid(true)
    setActive(false)
  }

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => onChange(event.target.value as string)

  const handlekeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key === 'Meta' || e.key === 'Control' || e.key === 'Backspace') &&
      inputRef.current
    ) {
      const inputValue = inputRef.current.value
      if (inputValue !== value) onChange(inputValue)
    }
  }

  return (
    <div className="flex w-full flex-col items-start">
      <div className="min-h-12 relative h-full w-full">
        {iconLeft && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <img
              src={iconLeft}
              alt={altIcon}
              className="h-5 w-5 text-gray-400"
            />
          </div>
        )}
        {mask ? (
          <NumericFormatWrapper
            format={mask}
            mask="_"
            getInputRef={inputRef}
            className={`rounded-xs font-roboto min-h-12 h-12 w-full border border-solid p-4 focus:outline-none ${
              (error || !valid) && !active
                ? 'border-red-error focus:border-red-error'
                : 'border-grayscale-5 hover:border-blue-navy focus:border-blue-primary'
            }
          ${disabled ? 'bg-grayscale-3 hover:border-grayscale-5' : ''}
          ${classes} ${iconLeft ? 'pl-10 ' : 'pl-4'}`}
            disabled={disabled}
            id={id}
            inputMode={derivedInputMode}
            maxLength={maxLength}
            name={name}
            pattern={pattern}
            placeholder={placeholder}
            onBlur={handleOnBlur}
            onFocus={onFocus}
            onClick={() => setActive(true)}
            onChange={handleChange}
            onKeyUp={handlekeyup}
            onKeyDown={onKeyDown}
            spellCheck={spellCheck ? spellCheck : false}
            value={(value as string) || ''}
          />
        ) : (
          <input
            aria-labelledby={labelId}
            autoComplete={autoComplete}
            className={`rounded-xs font-roboto min-h-12 h-12 w-full border border-solid p-4 focus:outline-none ${
              (error || !valid) && !active
                ? 'border-red-error focus:border-red-error'
                : 'border-grayscale-5 hover:border-blue-navy focus:border-blue-primary'
            }
          ${disabled ? 'bg-grayscale-3 hover:border-grayscale-5' : ''}
          ${classes} ${iconLeft ? 'pl-10 ' : 'pl-4'}`}
            disabled={disabled}
            id={id}
            ref={inputRef}
            inputMode={derivedInputMode}
            maxLength={maxLength}
            name={name}
            pattern={pattern}
            placeholder={placeholder}
            onBlur={handleOnBlur}
            onFocus={onFocus}
            onClick={() => setActive(true)}
            onChange={handleChange}
            onKeyUp={handlekeyup}
            onKeyDown={onKeyDown}
            spellCheck={spellCheck ? spellCheck : false}
            type={type}
            value={value || ''}
          />
        )}
      </div>
      {(error || !valid) && !active && <ErrorMessage message={errMsg} />}
    </div>
  )
}
