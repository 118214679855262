import React from 'react'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { RateQuote } from '../../../../types'
import { InputBox } from '../../'
import { phoneMask } from '@/utils'

interface Props_ {
  validate: ({
    key,
    force,
  }: {
    key: string
    force?: boolean | undefined
  }) => boolean
}

interface questionsType {
  label: string
  id: string
  inputMode:
    | 'tel'
    | 'email'
    | 'numeric'
    | 'search'
    | 'text'
    | 'url'
    | 'decimal'
    | 'none'
    | undefined
  name: string
  pattern?: string
  type: 'tel' | 'email'
  mask?: string | ((val: string) => string)
  error?: string
}

type Props = Props_ & Omit<RateQuote.FormPanel, 'input'>

export function ContactMethodPanel(props: Props) {
  const questions: questionsType[] = [
    {
      label: 'Email',
      id: 'email',
      inputMode: 'email',
      name: 'email',
      type: 'email',
      error: 'Please enter a valid email address.',
    },
    {
      label: 'Phone Number',
      id: 'phone',
      inputMode: 'numeric',
      name: 'phone',
      pattern: '[0-9]*',
      type: 'tel',
      mask: phoneMask,
      error: 'Please enter a valid phone number.',
    },
  ]

  return (
    <div className="flex w-full flex-col items-center">
      {questions.map((field) => (
        <div
          key={field.id}
          className="max-w-84 mb-4 flex w-full flex-col items-start"
        >
          <InputBox
            id={`get-quotes--${field.id}`}
            error={!props.validate({ key: field.id })}
            errorMsg={field.error}
            name={`${field.id}`}
            type={field.type}
            mask={field.mask}
            pattern={field.pattern}
            inputMode={field.inputMode}
            value={props.value[field.name]}
            onChange={(val: string) => {
              props.handleInput(val, field.name)
            }}
            placeholder={startCase(toLower(field.label))}
          />
        </div>
      ))}
    </div>
  )
}
