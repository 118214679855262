import { DropdownOption } from './../../../molecules'
import { getAge } from '../../../../utils'

export const AGE_OPTIONS = [
  { label: '', value: '' },
  { label: '18-20', value: 19 },
  { label: '21-24', value: 22 },
  { label: '25-34', value: 30 },
  { label: '35-44', value: 40 },
  { label: '45-54', value: 50 },
  { label: '55-64', value: 60 },
  { label: '65+', value: 65 },
]

export const getAgeOption: (
  day: string | string[] | null,
  month: string | string[] | null,
  year: string | string[] | null,
) => DropdownOption = (day, month, year) => {
  if (
    typeof day !== 'string' ||
    typeof month !== 'string' ||
    typeof year !== 'string'
  ) {
    return AGE_OPTIONS[0]
  }

  const age = getAge(day, month, year)

  if (!age) {
    return AGE_OPTIONS[0]
  }

  if (age >= 65) {
    return AGE_OPTIONS[AGE_OPTIONS.length - 1]
  }

  let index = 0

  for (const option of AGE_OPTIONS) {
    const range = option.label.split('-')
    if (isInRange(age, range[0], range[1])) {
      index = AGE_OPTIONS.indexOf(option)
      break
    } else continue
  }

  return AGE_OPTIONS[index]
}

const isInRange = (v: number, min: string, max: string) =>
  v >= +min && v <= +max

export const parseCredit: (
  leadData: string | string[] | null | undefined,
  selectedCredit: boolean,
) => string = (leadData, selectedCredit: boolean) => {
  // do not change the order of the following if statements
  if (leadData === 'excellent') {
    if (selectedCredit) return 'excellent'
    return 'not_sure'
  }
  if (leadData === 'good') {
    if (selectedCredit) return 'good'
    return 'not_sure'
  }
  if (selectedCredit) return 'good'
  if (leadData) return leadData as string
  return 'not_sure'
}
