import React from 'react'
import { Button } from '../..'

export interface PaginationProps {
  /**
   * Pass a label that describes the content for which the pagination is being used.
   * Example: "reviews" or "resource articles"
   */
  ariaLabel: string
  leftDisabled?: boolean
  onClickLeft?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickRight?: (e: React.MouseEvent<HTMLButtonElement>) => void
  rightDisabled?: boolean
}

export const Pagination = (props: PaginationProps) => {
  const {
    ariaLabel,
    leftDisabled = false,
    onClickLeft,
    onClickRight,
    rightDisabled = false,
  } = props
  return (
    <nav className="flex" aria-label={ariaLabel}>
      <div className="w-55px md:w-18 mr-4 md:mr-5">
        <Button
          aria-label="Get previous section"
          classes="c-button-prev h-8-1/2 md:h-12"
          disabled={leftDisabled}
          icon={
            <img
              className="h-4 md:h-5"
              alt="left"
              src="/static/icons/chevron-l-white.svg"
            />
          }
          onClick={(e) => onClickLeft && onClickLeft(e)}
        />
      </div>
      <div className="w-55px md:w-18">
        <Button
          aria-label="Get next section"
          classes="c-button-next h-8-1/2 md:h-12"
          disabled={rightDisabled}
          icon={
            <img
              className="h-4 md:h-5"
              alt="right"
              src="/static/icons/chevron-r-white.svg"
            />
          }
          onClick={(e) => onClickRight && onClickRight(e)}
        />
      </div>
    </nav>
  )
}
