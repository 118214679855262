/* eslint-disable sort-keys */
import compact from 'lodash/fp/compact'
import identity from 'lodash/fp/identity'
import isEmpty from 'lodash/fp/isEmpty'
import pickBy from 'lodash/fp/pickBy'

import { MediaAlpha, Segment } from '../../../../types'
import { LeadData } from '@/hooks/use-lead-data/types'
import { UserContext } from '@/hooks'
import { tcpaConsentText } from '@/utils/tcpa/tcpa.consent-text'

export function parseLeadData(
  leadData: Partial<LeadData.All>,
  userContext: UserContext,
  category: Segment.Category,
):
  | Partial<MediaAlpha.Auto>
  | Partial<MediaAlpha.Medicare>
  | Partial<MediaAlpha.U65> {
  const data = pickBy(identity, {
    contact: formatName(leadData.contactFirstName, leadData.contactLastName),
    email: leadData.contactEmail,
    phone: leadData.contactPhoneNumber,
    address: leadData.contactAddress,
    zip: leadData.contactZip || leadData.zipcode,
    leadid_id: userContext.leadId,
    tcpa: leadData.contactPhoneNumber && {
      call_consent: '1',
      sms_consent: '1',
      text: tcpaConsentText(category ? category : ''),
      url: 'https://www.ratequote.com/privacy-policy/',
    },
  })

  if (leadData.contactAptSteOther) {
    data['address_2'] = leadData.contactAptSteOther
  }

  switch (category) {
    case 'auto-insurance': {
      const vehicles = parseVehicleData(leadData)

      const autoLead = pickBy(identity, {
        home_ownership: leadData.homeowner,
        vehicles: vehicles,
        drivers: parseDriverData(leadData),
        incidents: parseIncidents(leadData),
        num_vehicles: vehicles?.length === 2 ? '2' : '1',
        currently_insured: leadData.autoInsuranceCurrent ? '1' : '0',
        current_company:
          leadData.autoInsuranceProvider &&
          parseCompany(leadData.autoInsuranceProvider),
        military_affiliation: leadData.military,
      })
      return { ...data, ...autoLead } as Partial<MediaAlpha.Auto>
    }

    case 'health-insurance': {
      const medicareLead = pickBy(identity, {
        currently_insured: leadData.medicareInsuranceCurrent ? '1' : '0',
        birth_date: formatDate(
          leadData.birthYear,
          leadData.birthMonth,
          leadData.birthDay,
        ),
        gender: leadData.gender,
        coverage_type: parseMedicareType(leadData.medicareCoverageType),
        tobacco: leadData.tobacco,
      })
      return { ...data, ...medicareLead } as Partial<MediaAlpha.Medicare>
    }

    case 'u65': {
      const u65Lead = pickBy<Partial<MediaAlpha.U65>>(identity, {
        county: leadData.contactCounty,
        primary: pickBy<Partial<MediaAlpha.U65['primary']>>(identity, {
          name: formatName(leadData.contactFirstName, leadData.contactLastName),
          gender: leadData.gender,
          birth_date: formatDate(
            leadData.birthYear,
            leadData.birthMonth,
            leadData.birthDay,
          ),
          height: parseBiometrics(leadData.height),
          weight: parseBiometrics(leadData.weight),
          tobacco: leadData.tobacco,
        }),
        spouse: parseSpouse(leadData),
        children: parseChildren(leadData),
        pregnant: leadData.pregnant,
        household_income: parseHouseholdIncome(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          leadData.householdIncome!,
        ),
        major_condition_aids_hiv: leadData.majorConditionAidsHiv,
        major_condition_alcohol_drug_abuse:
          leadData.majorConditionAlcoholDrugAbuse,
        major_condition_alzheimers_dementia:
          leadData.majorConditionAlzheimersDementia,
        major_condition_asthma: leadData.majorConditionAsthma,
        major_condition_cancer: leadData.majorConditionCancer,
        major_condition_chronic_pain: leadData.majorConditionChronicPain,
        major_condition_clinical_depression:
          leadData.majorConditionClinicalDepression,
        major_condition_diabetes: leadData.majorConditionDiabetes,
        major_condition_emphysema: leadData.majorConditionEmphysema,
        major_condition_epilepsy: leadData.majorConditionEpilepsy,
        major_condition_heart_attack: leadData.majorConditionHeartAttack,
        major_condition_heart_disease: leadData.majorConditionHeartDisease,
        major_condition_hepatitis_liver: leadData.majorConditionHepatitisLiver,
        major_condition_high_blood_pressure:
          leadData.majorConditionHighBloodPressure,
        major_condition_high_cholesterol:
          leadData.majorConditionHighCholesterol,
        major_condition_kidney_disease: leadData.majorConditionKidneyDisease,
        major_condition_mental_illness: leadData.majorConditionMentalIllness,
        major_condition_multiple_sclerosis:
          leadData.majorConditionMultipleSclerosis,
        major_condition_pulmonary_disease:
          leadData.majorConditionPulmonaryDisease,
        major_condition_respiratory_disorder:
          leadData.majorConditionPulmonaryDisease,
        major_condition_stroke: leadData.majorConditionStroke,
        major_condition_ulcers: leadData.majorConditionUlcers,
        major_condition_vascular_disease:
          leadData.majorConditionVascularDisease,
        major_condition_other: leadData.majorConditionOther,
        qualifying_life_event: parseQle(leadData.qualifyingLifeEvent),
        qualifying_life_event_date: formatDate(
          leadData.qualifyingLifeEventYear,
          leadData.qualifyingLifeEventMonth,
          leadData.qualifyingLifeEventDay,
        ),
        currently_insured: leadData.healthInsuranceCurrent,
        current_company: parseHealthInsuranceCompany(
          leadData.healthInsuranceProvider,
        ),
        coverage_type: parseHealthInsuranceCoverageType(
          leadData.healthInsuranceCoverageType,
        ),
      })

      return { ...data, ...u65Lead } as Partial<MediaAlpha.U65>
    }

    default:
      return data
  }
}

function formatDate(
  year?: string,
  month?: string,
  day?: string,
): string | undefined {
  return year && month && day ? `${year}-${month}-${day}` : undefined
}

function formatName(first?: string, last?: string): string | undefined {
  const entries = compact([first, last])
  return isEmpty(entries) ? undefined : entries.join(' ')
}

export function parseBiometrics(metric: string | undefined) {
  if (!metric) return undefined
  return Number(metric)
}

export function parseChildren(leadData: Partial<LeadData.HealthInsurance>) {
  const children: MediaAlpha.U65['children'] = []
  if (!leadData.dependents) return undefined
  for (const dependent of leadData.dependents) {
    children.push({
      name: formatName(dependent.firstName, dependent.lastName) || '',
      gender: dependent.gender,
      birth_date: formatDate(
        dependent.birthYear,
        dependent.birthMonth,
        dependent.birthDay,
      ),
      height: parseBiometrics(dependent.height),
      weight: parseBiometrics(dependent.weight),
      tobacco: dependent.tobacco,
    })
  }
  return children
}

function parseCreditRating(
  value?: LeadData.Auto['creditScore'],
): 'excellent' | 'good' | 'average' | 'poor' | undefined {
  if (value != null && value != 'not_sure') return value
}

function parseEducation(
  value?: LeadData.Auto['education'],
):
  | 'incomplete'
  | 'high school'
  | 'some college'
  | 'associate'
  | 'bachelor'
  | 'master'
  | 'phd'
  | undefined {
  if (value != null) return value
}

function parseLicenseStatus(
  status?: LeadData.Auto['driverLicense'],
): 'active' | 'none' | undefined {
  if (status == null) return
  return status === '1' ? 'active' : 'none'
}

function parseMaritalStatus(
  status?: LeadData.Auto['married'],
): 'married' | 'single' | undefined {
  if (status == null) return
  return status === '1' ? 'married' : 'single'
}

export function parseQle(
  qle: LeadData.HealthInsurance['qualifyingLifeEvent'],
): MediaAlpha.U65['qualifying_life_event'] | undefined {
  if (!qle) return undefined
  const values = {
    none: 'none',
    marriage_divorce: 'marriage or divorce',
    job_loss_change: 'job loss / job change',
    permanent_move: 'permanent move',
    birth_adoption_child: 'birth or adoption of a child',
    other: 'other',
  } as const

  return values[qle] || undefined
}

export function parseDriverData(
  leadData: Partial<LeadData.Auto>,
): MediaAlpha.Auto['drivers'] | undefined {
  const driverInfo = pickBy(identity, {
    birth_date: formatDate(
      leadData.birthYear,
      leadData.birthMonth,
      leadData.birthDay,
    ),
    credit_rating: parseCreditRating(leadData.creditScore),
    education: parseEducation(leadData.education),
    driver: formatName(leadData.contactFirstName, leadData.contactLastName),
    gender: leadData.gender,
    license_status: parseLicenseStatus(leadData.driverLicense),
    marital_status: parseMaritalStatus(leadData.married),
    sr_22: leadData.driverSr22,
  })

  return isEmpty(driverInfo)
    ? undefined
    : [
        {
          relationship: 'self',
          ...(parseVehicleData(leadData) ? { primary_vehicle: '0' } : {}),
          ...driverInfo,
        },
      ]
}

function parseIncidents(
  leadData: Partial<LeadData.Auto>,
): MediaAlpha.Auto['incidents'] | undefined {
  const incidents = {
    accident: leadData.incidentsAccident,
    claim: leadData.incidentsClaim,
    dui: leadData.incidentsDui,
    suspension: leadData.incidentsSuspension,
    ticket: leadData.incidentsTickets,
  } as const

  const result = compact(
    Object.entries(incidents).map(([k, v]) =>
      v === '1' ? { driver: '0', type: k } : undefined,
    ),
  )

  return isEmpty(result) ? undefined : (result as MediaAlpha.Auto['incidents'])
}

export function parseSpouse(leadData: Partial<LeadData.HealthInsurance>) {
  if (!leadData.spouse) return undefined
  return pickBy(identity, {
    name: formatName(leadData.spouse?.firstName, leadData.spouse?.lastName),
    gender: leadData.spouse?.gender,
    birth_date: formatDate(
      leadData.spouse?.birthYear,
      leadData.spouse?.birthMonth,
      leadData.spouse?.birthDay,
    ),
    height: parseBiometrics(leadData.spouse?.height),
    weight: parseBiometrics(leadData.spouse?.weight),
    tobacco: leadData.spouse?.tobacco,
  })
}

function parseVehicleData(leadData: Partial<LeadData.Auto>) {
  const vehicle1Info = pickBy(identity, {
    make: leadData.vehicleMake1,
    model: leadData.vehicleModel1,
    year: leadData.vehicleYear1,
  })

  const vehicle2Info = pickBy(identity, {
    make: leadData.vehicleMake2,
    model: leadData.vehicleModel2,
    year: leadData.vehicleYear2,
  })

  return isEmpty(vehicle1Info)
    ? undefined
    : isEmpty(vehicle2Info)
    ? [{ primary_driver: '0', ...vehicle1Info }]
    : [
        { primary_driver: '0', ...vehicle1Info },
        { primary_driver: '0', ...vehicle2Info },
      ]
}

export function parseCompany(
  k: LeadData.Auto['autoInsuranceProvider'],
): MediaAlpha.Auto['current_company'] {
  if (!k) return 'other'
  const values = {
    '21st_century_insurance': '21st century',
    aig: 'aig',
    allstate_insurance: 'allstate',
    american_family_insurance: 'american family',
    country_financial: 'country insurance and financial services',
    esurance: 'esurance',
    farmers_insurance: 'farmers',
    geico: 'geico',
    liberty_mutual_insurance: 'liberty mutual',
    metlife_auto_and_home: 'metlife',
    nationwide_general_insurance: 'nationwide',
    progressive: 'progressive',
    state_farm_county: 'state farm',
    travelers_insurance_company: 'travelers',
    usaa: 'usaa',
    other: 'other',
  } as const

  return values[k] || 'other'
}

export function parseHealthInsuranceCompany(
  k: LeadData.HealthInsurance['healthInsuranceProvider'],
): MediaAlpha.U65['current_company'] | undefined {
  if (!k) return undefined
  const values = {
    aetna: 'AETNA',
    aflac: 'Aflac',
    american_family_insurance: 'American Family Insurance',
    american_republic_insurance: 'American Republic Insurance',
    assurant: 'Assurant',
    atlantis_health_plan: 'Atlantis Health Plan',
    blue_cross_blue_shield: 'Blue Cross Blue Shield',
    celtic_insurance: 'Celtic Insurance',
    cigna: 'CIGNA',
    farm_bureau_insurance: 'Farm Bureau Insurance',
    golden_rule_insurance: 'Golden Rule Insurance',
    health_net: 'Health Net',
    health_plus_of_america: 'Health Plus of America',
    healthmarkets: 'Healthmarkets',
    humana: 'Humana',
    insphere: 'InSphere',
    kaiser_permanente: 'Kaiser Permanente',
    lifewise_health_plan: 'LifeWise Health Plan',
    metlife_insurance: 'Metlife Insurance',
    oxford_health_plans: 'Oxford Health Plans',
    pacificare: 'PacifiCare',
    state_farm_insurance: 'State Farm Insurance',
    time_insurance: 'Time Insurance',
    tufts_health_plan: 'Tufts Health Plan',
    unicare: 'Unicare',
    united_american_insurance: 'United American Insurance',
    united_healthcare: 'United HealthCare',
    vista_health_plan: 'Vista Health Plan',
    other: 'Other',
  } as const

  return values[k] || 'Other'
}

export function parseHealthInsuranceCoverageType(
  k: LeadData.HealthInsurance['healthInsuranceCoverageType'],
): MediaAlpha.U65['coverage_type'] {
  if (!k) return undefined
  const values = {
    individual_family: 'Individual Family',
    short_term: 'Short Term',
    cobra: 'COBRA',
    discount_plan: 'Discount Plan',
    medicaid: 'Medicaid',
    maternity: 'Maternity Only',
    dental: 'Dental Only',
    vision: 'Vision Only',
    prescription: 'Prescription Only',
    other: 'Other Health',
  } as const

  return values[k] || 'Other Health'
}

export function parseMedicareType(
  k: LeadData.Medicare['medicareCoverageType'],
): MediaAlpha.Medicare['coverage_type'] {
  if (!k) return undefined
  const values = {
    medicare_advantage: 'Medicare Advantage',
    medicare_supplemental: 'Medicare Supplemental',
    medicare_part_d: 'Medicare Part D',
    other_medicare: 'Other Medicare',
  } as const

  return values[k] || undefined
}

function parseHouseholdIncome(
  k: LeadData.HealthInsurance['householdIncome'],
): MediaAlpha.U65['household_income'] {
  if (!k) return 19999
  const values = {
    '47000_and_over': 47000,
    '29000-46999': 40000,
    '12000-28999': 20000,
    below_12000: 11999,
  } as const

  return values[k]
}
