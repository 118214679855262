import React from 'react'
import { Segment } from '../../../../types'

interface Props {
  category: Segment.Category
}
const TTY = (props: Props) => {
  const { category } = props
  if (category !== 'health-insurance') return null
  return (
    <span className="ba whitespace-nowrap">&nbsp;| TTY 711, 8am - 6pm</span>
  )
}

export default TTY
