import React from 'react'
import { SelectGrid } from '../../'
import { handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

export function EducationPanel(props: RateQuote.FormPanel) {
  const { showBtnLoader } = props

  return (
    <SelectGrid
      columns="grid-cols-1"
      itemContainerClasses="max-w-xs mx-auto"
      items={[
        [
          { label: 'Incomplete', value: 'incomplete' },
          { label: 'High School', value: 'high school' },
          { label: 'Some College', value: 'some college' },
          { label: 'Associate Degree', value: 'associate' },
          { label: 'Bachelors Degree', value: 'bachelor' },
          { label: 'Masters Degree', value: 'master' },
          { label: 'PhD', value: 'phd' },
        ],
      ]}
      loading={showBtnLoader}
      onChange={handleGridButtonSelect(props.input, props.handleInput)}
      value={props.value || ''}
    />
  )
}
