import * as Sentry from '@sentry/nextjs'
import {
  requestPersonalInformation,
  updatePersonalInformation,
} from '../../clients/segment'
import {
  suppressAndDeleteUser,
  suppressUser,
} from '../../clients/segment/regulations'

interface UserData {
  firstName: string
  lastName: string
  email: string
}

export async function handleDataDeletion(email: string) {
  await suppressAndDeleteUser(email)
}

export async function handleDoNotSell(userData: UserData): Promise<void> {
  try {
    const { firstName, lastName, email } = userData

    updatePersonalInformation(email, {
      'do-not-sell': true,
      'first-name': firstName,
      'last-name': lastName,
      email,
      name: `${firstName} ${lastName}`,
    })

    await suppressUser(email)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export async function handleInfoRequest(userData: UserData) {
  const { firstName, lastName, email } = userData
  updatePersonalInformation(email, {
    'first-name': firstName,
    'last-name': lastName,
    email,
    name: `${firstName} ${lastName}`,
  })
  requestPersonalInformation()
}
