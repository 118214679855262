import React from 'react'
import { PortableText, PortableTextComponents } from '@portabletext/react'
import { TextHighlight } from '../..'
import { Sanity } from '../../../../types/sanity'
export interface FeatureSectionProps {
  /**
   * The title of the page
   */
  title?: string
  /**
   * An array of numbers corresponding to coloring of substrings in the title
   * Example: emphasisIndex = [1,2] will colorize 'home plans' in 'Compare home plans."
   */
  emphasisIndex?: number[]
  /**
   * The subtitle of the page below title
   */
  subtitle?: string
  /**
   * The alignment of the title and subtitle
   */
  align?: 'left' | 'center'
  /**
   * Add optional custom classes to the title to override the default styling
   */
  titleClasses?: string
  /**
   * Add optional custom classes to the subtitle to override the default styling
   */
  subtitleClasses?: string
  /**
   * Add optional CTA to the default styling
   */
  CTA?: React.ReactNode

  /**
   * Add optional PortableText component to be used as content instead use `title`, `subtitle`, `emphasisIndex`, for example.
   */
  portableText?: Sanity.PortableText[]
}

export const FeatureSection = (props: FeatureSectionProps) => {
  const {
    title = '',
    subtitle,
    emphasisIndex,
    align,
    titleClasses = '',
    subtitleClasses = '',
    CTA,
    portableText,
  } = props

  const CustomTextComponents: PortableTextComponents = React.useMemo(
    () => ({
      block: {
        h1: (props) => (
          <h1 className={`c-feature-title ${titleClasses}`}>
            {props.children}
          </h1>
        ),
        normal: (props) => (
          <p
            className={`c-feature-subtitle ${
              align === 'center' ? 'mx-auto text-center' : 'max-w-xl text-left'
            } ${subtitleClasses}`}
          >
            {props.children}
          </p>
        ),
      },
    }),
    [portableText],
  )

  return (
    <div>
      <header className="flex flex-col items-center lg:items-start">
        <div
          className={`lg:mb-13 mx-auto ${
            align === 'center' ? 'text-center' : 'xxl:mx-24 text-left sm:mx-12'
          }`}
        >
          {portableText ? (
            <PortableText
              value={portableText}
              components={CustomTextComponents}
            />
          ) : (
            <>
              <h1 className={`c-feature-title ${titleClasses}`}>
                <TextHighlight title={title} emphasisIndex={emphasisIndex} />
              </h1>
              {subtitle && (
                <p
                  className={`c-feature-subtitle ${
                    align === 'center'
                      ? 'mx-auto text-center'
                      : 'max-w-xl text-left'
                  }  ${subtitleClasses}`}
                >
                  {subtitle}
                </p>
              )}
              <div
                className={`max-w-sm ${align === 'center' ? 'mx-auto' : ''}`}
              >
                {CTA}
              </div>
            </>
          )}
        </div>
      </header>
    </div>
  )
}

export default FeatureSection
