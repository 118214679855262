import { Age } from './Age'
import { Dob } from './Dob'
import { AutoInsuranceProvider } from './AutoInsuranceProvider'
import { CreditScore } from './CreditScore'
import { Homeowner } from './Homeowner'
import { Income } from './Income'
import { Gender } from './Gender'
import { Vehicles } from './Vehicles'
import { ZipCode } from './ZipCode'
import { Tobacco } from './Tobacco'
import { FirstName } from './FirstName'
import { LastName } from './LastName'
import { Email } from './Email'
import { PhoneNumber } from './PhoneNumber'

export default Object.freeze({
  Age,
  AutoInsuranceProvider,
  CreditScore,
  Gender,
  Homeowner,
  Income,
  Vehicles,
  ZipCode,
  Tobacco,
  FirstName,
  LastName,
  Email,
  Dob,
  PhoneNumber,
})
