import * as React from 'react'
import { Wrapper } from './Wrapper'
import { Dropdown, DropdownOption } from '../../../..'
import type { QuestionProps } from './types'

const OPTIONS: DropdownOption[] = [
  {
    label: 'Male',
    value: 'm',
  },
  {
    label: 'Female',
    value: 'f',
  },
]

type GenderType = 'm' | 'f'

export const Gender: React.FC<QuestionProps> = (props) => {
  const [value, setValue] = React.useState<DropdownOption | undefined>(
    undefined,
  )

  React.useEffect(() => {
    const initialValue = props.leadDataGetter['gender']
    const initialOption = OPTIONS.find(
      (option) => option.value === initialValue,
    ) || { label: '', value: '' }

    setValue(initialOption)
    props.setIsValid(isValid(initialOption))
  }, [])

  const handleChange = (value: DropdownOption) => {
    setValue(value as { label: string; value: GenderType })

    if (value.value !== '') {
      props.leadDataSetter({
        key: 'gender',
        value: value.value as GenderType,
      })

      props.setIsValid(true)
    } else {
      props.setIsValid(false)
    }
  }

  return (
    <Wrapper>
      <label className="sr-only" id="qf-gender--label">
        Gender
      </label>
      <Dropdown
        id="qf-gender"
        labelId="qf-gender--label"
        name="qf-gender"
        onChange={handleChange}
        placeholder="Gender"
        options={OPTIONS}
        value={{ value: value?.label ?? '' }}
      />
    </Wrapper>
  )
}

function isValid(selectedOption: DropdownOption) {
  return selectedOption.value !== ''
}
