import React from 'react'
import { Button } from '../../atoms'
import { handlePhoneOut } from '@/utils'
import { Segment } from '../../../../types/segment'
import { isCallCenterOpen } from '@/utils/call-center'

export interface MiddleCTAProps {
  /**
   * The category of the page
   */
  category: Segment.EventData['Checkout Started']['category']
  /**
   * The text to be rendered in the CTA
   */
  description: string
  phoneNumber: string
  /**
   * Optional text to be displayed beneath the button
   */
  subtext?: string
  /**
   * The title of the CTA
   */
  title: string
  tty: string
}

export const MiddleCTA = (props: MiddleCTAProps) => {
  const { category, description, phoneNumber, subtext, title, tty } = props

  return (
    <div className="bg-blue-lightblue-6 border-blue-lightblue-7 w-full rounded-lg border">
      <div className="md:max-w-152 xxs:mx-6 mx-3 my-auto py-12 text-center md:m-auto">
        <h2 className="text-blue-navy pb-6 font-bold">{title}</h2>
        <p className="text-grayscale-5 font-roboto mx-4 pb-10 text-xl leading-8 md:mx-0 md:pb-10 md:leading-7">
          {description}
        </p>
        {isCallCenterOpen() && (
          <div className="xxs:w-fit mx-auto w-full">
            <a
              className="xxs:px-4 px-3 text-white no-underline"
              href={`tel:+${phoneNumber}`}
            >
              <Button
                classes={tty ? 'text-base xs:text-lg' : ''}
                icon={
                  <img
                    className={`${
                      tty ? 'xs:mr-4 xxs:inline mr-2 hidden' : 'mr-4'
                    }  h-6 w-5`}
                    alt="phone"
                    src="/static/icons/telephone-white.svg"
                  />
                }
                onClick={() => handlePhoneOut({ phoneNumber, category })}
                role="primary"
              >
                {phoneNumber}
                {tty}
              </Button>
            </a>
          </div>
        )}
        {subtext && (
          <p className="text-grayscale-5 font-roboto pt-6 text-sm">{subtext}</p>
        )}
      </div>
    </div>
  )
}
