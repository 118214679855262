/**
 * Break-up an array into smaller chunks. See `lodash/chunk`.
 */
export function chunk<T>(array: Array<T>, size = 1): Array<Array<T>> {
  const chunks = []

  let index = 0
  while (index < array.length) {
    chunks.push(array.slice(index, index + size))
    index += size
  }

  return chunks
}
