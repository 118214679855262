import React, { useEffect, useState } from 'react'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { RateQuote } from '../../../../../types'
import { InputBox } from '../../..'
import { FormLabel } from '../../../templates/Form/Label'
import convertInchesToFeetInches from '../../../../utils/convert-mesure/convertInchesToFeetInches'
import convertFeetInchesToInches from '../../../../utils/convert-mesure/convertFeetInchesToInches'

interface Props_ {
  userDidSubmit: boolean
  validate: ({
    key,
    force,
  }: {
    key: string
    force?: boolean | undefined
  }) => boolean
}

type questionType = {
  title: string
  col: string
  fields: {
    label: string
    id: string
    name: 'hInches' | 'hFeet' | 'weight'
    maxlength: number
  }[]
}[]

type Props = Props_ & Omit<RateQuote.FormPanel, 'input'>

const QUESTIONS: questionType = [
  {
    title: 'Height',
    col: 'grid-cols-2',
    fields: [
      {
        label: 'Feet',
        id: 'height-feet',
        name: 'hFeet',
        maxlength: 1,
      },
      {
        label: 'Inches',
        id: 'height-inches',
        name: 'hInches',
        maxlength: 2,
      },
    ],
  },
  {
    title: 'Weight',
    col: '',
    fields: [
      {
        label: 'Pounds',
        id: 'weight',
        name: 'weight',
        maxlength: 4,
      },
    ],
  },
]

export function HeightWeight(props: Props) {
  const [hasError, setHasError] = useState(false)
  const [value, setValue] = useState<{
    height?: string
    hFeet: string
    hInches: string
    weight: string
  }>({
    height: props.value.height || '',
    hFeet: '',
    hInches: '',
    weight: props.value.weight || '',
  })

  useEffect(() => {
    const height = parseInt(props.value['height'] || '')
    const { feet, inches } = convertInchesToFeetInches(height)

    return setValue({ ...value, hFeet: String(feet), hInches: String(inches) })
  }, [])

  const onChange = (val: string, field: any) => {
    if (field.id.includes('weight')) {
      setValue({ ...value, [field.id]: val })
      props.handleInput(val, field.name)
    } else if (field.id.includes('height')) {
      const newValue: any = { ...value, [field.name]: val }
      const height =
        convertFeetInchesToInches(newValue['hFeet'], newValue['hInches']) + ''

      props.handleInput(height, 'height')
      setValue(newValue)
    }
  }

  return (
    <div className="mb-2 grid gap-3 sm:grid-cols-2">
      {QUESTIONS.map((section) => {
        return (
          <div className="grid w-full text-left" key={section.title}>
            <FormLabel
              as="label"
              className="sm:leading-300 md:leading-302 text-center sm:mb-6 sm:text-left"
            >
              {section.title}
            </FormLabel>
            <div className={`grid gap-2 ${section.col}`}>
              {section.fields.map((field) => (
                <div className={`h-20 ${hasError && `mb-14`}`} key={field.id}>
                  {field.label}
                  <InputBox
                    error={!props.validate({ key: toLower(section.title) })}
                    errorMsg={`Please enter a valid number.`}
                    hasError={setHasError}
                    id={`get-quotes--${field.id}`}
                    maxLength={field.maxlength}
                    name={field.id}
                    onChange={(val: string) => onChange(val, field)}
                    pattern="[0-9]*"
                    placeholder={startCase(toLower(field.label))}
                    type="tel"
                    value={value[field.name]}
                  />
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}
