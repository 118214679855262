import flow from 'lodash/fp/flow'
import toString from 'lodash/fp/toString'
import split from 'lodash/fp/split'
import last from 'lodash/fp/last'
import splitEvery from './split-every'

interface Option {
  label: string
  value: string
}
/**
 * builds an array of years grouped by decade in descending order
 * @param startYear This is the first option in the list
 * @param listLength Quantity of year options beginning from `startYear`
 */
export function buildYearGroups(startYear: number, listLength: number) {
  if (listLength < 1) {
    throw '`listLength` should be greater than 0.'
  }
  const options = renderOptions(startYear, listLength)
  const lastDigitOfStartYear = flow(toString, split(''), last, (x) =>
    Number(x),
  )(startYear)
  const headItems = options.slice(0, lastDigitOfStartYear + 1)
  const tailItems = splitEvery<Option>(
    options.slice(lastDigitOfStartYear + 1),
    10,
  )
  return headItems.length > 0 ? [headItems, ...tailItems] : [...tailItems]
}

function renderOptions(start: number, length: number) {
  return [...Array(length).keys()].map((idx) => {
    const value = (start - idx).toString()
    return {
      label: value,
      value,
    }
  })
}
