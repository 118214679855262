import { DropdownOption } from '../../components/molecules'
import { LeadData } from '../../hooks/use-lead-data/types'

type BirthDay = LeadData.All['birthDay']
type BirthMonth = LeadData.All['birthMonth']
type BirthYear = LeadData.All['birthYear']

type BirthDate = {
  birthDay: BirthDay
  birthMonth: BirthMonth
  birthYear: BirthYear
}

/**
 * Pass in an age to get an estimated date of birth in return.
 */

export const getBirthDate: (age: DropdownOption) => BirthDate = (ageOption) => {
  const age = +ageOption.value
  const now = Date.now()
  const nowDate = new Date(now)
  const estBirthDate = new Date(
    nowDate.setFullYear(nowDate.getFullYear() - age),
  )
  return {
    birthDay: estBirthDate.getDate().toString() as BirthDay,
    birthMonth: (estBirthDate.getMonth() + 1)
      .toString()
      .padStart(2, '0') as BirthMonth,
    birthYear: estBirthDate.getFullYear().toString(),
  }
}
