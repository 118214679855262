import React from 'react'
import { Img, SelectGrid } from '../../'
import {
  getAutoInsuranceCompanies,
  getCategoryFromForm,
  getHealthInsuranceCompanies,
  handleGridButtonSelect,
  parsePath,
} from '@/utils'
import { RateQuote } from '../../../../types'
import { useRouter } from 'next/router'
import map from 'lodash/map'

export function InsuranceProviderPanel(props: RateQuote.FormPanel) {
  const { showBtnLoader } = props
  const [items, setItems] = React.useState<any[][]>([[]])
  const router = useRouter()
  const form = parsePath(router.query)
  const category = getCategoryFromForm(form)
  const isU65 = category === 'u65'

  const sort = (a: any, b: any) => {
    if (a.label.toLowerCase() < b.label.toLowerCase() && a.label !== 'Other') {
      return -1
    }
    if (a.label.toLowerCase() > b.label.toLowerCase() || a.label === 'Other') {
      return 1
    }
    return 0
  }

  React.useEffect(() => {
    const insuranceCompanies = isU65
      ? getHealthInsuranceCompanies
      : getAutoInsuranceCompanies

    if (isU65) {
      const popularCompanies = insuranceCompanies?.slice(0, 10)?.sort(sort)
      const otherCompanies = insuranceCompanies?.slice(10)?.sort(sort)
      setItems([popularCompanies, otherCompanies])
    } else {
      setItems([insuranceCompanies])
    }
  }, [])

  return (
    <SelectGrid
      columns="grid-cols-1 sm:grid-cols-2"
      itemClasses="justify-start sm:px-3 text-left sm:leading-none"
      items={renderInsuranceProviderOption(items, isU65)}
      loading={showBtnLoader}
      showSearchMatch={isU65}
      showMoreIndex={1}
      onChange={handleGridButtonSelect(props.input, props.handleInput)}
      value={props.value || ''}
    />
  )
}

function renderInsuranceProviderOption(
  items: RateQuote.HealthInsuranceProvider[][],
  isU65: boolean,
) {
  const [imgLoaded, setImgLoaded] = React.useState(false)

  return map(items, function (nested: any) {
    return map(nested, function (element: any) {
      return {
        icon:
          ['other', 'geico'].includes(element.value) || isU65 ? (
            <div
              aria-hidden
              className={`${isU65 ? 'min-w-5' : 'min-w-15'} -my-8 mr-2 h-4`}
            />
          ) : (
            <Img
              alt=""
              classes="max-w-15 -my-8 mr-2"
              skeleton={{ classes: 'mr-2 min-w-15 min-h-4 h-4 w-15' }}
              src={`/insurance-companies/${element.img}.svg`}
              alreadyLoaded={imgLoaded}
              setAlreadyLoaded={setImgLoaded}
            />
          ),
        label: element.label,
        value: element.value,
      }
    })
  })
}
