export function handleGridButtonSelect(
  inputRef: React.RefObject<HTMLInputElement>,
  handleInput: (e: string) => void,
) {
  return (e: string) => {
    const currentInput = inputRef.current as HTMLInputElement
    currentInput.focus({ preventScroll: true })
    currentInput.value = e
    handleInput(e)
  }
}
