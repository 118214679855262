import React from 'react'

import { DependentsAddView, DependentsInitialView } from '..'
import { RateQuote } from '../../../../../types'

interface Props extends RateQuote.FormPanel {
  anyDependents: string | null
  handleSubmit: (e: string) => void
  setAnyDependents: React.Dispatch<React.SetStateAction<string | null>>
  setDependentType: React.Dispatch<React.SetStateAction<string | undefined>>
  setShowEditView: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddDependentsPanel = (props: Props) => {
  const {
    anyDependents,
    handleInput,
    handleSubmit,
    input,
    setAnyDependents,
    setDependentType,
    setShowEditView,
    showBtnLoader,
    value,
  } = props

  const { healthInsuranceDependents, healthInsuranceSpouse } = value

  const hasChildren =
    healthInsuranceDependents !== undefined && healthInsuranceDependents !== '0'
  const hasSpouse =
    healthInsuranceSpouse !== undefined && healthInsuranceSpouse !== '0'

  const handleChange = (e: string) => {
    setAnyDependents(e)
    e !== '1' && handleSubmit(e)
  }

  return (
    <>
      {anyDependents === '1' ? (
        <>
          <DependentsAddView
            dependentType={setDependentType}
            handleInput={handleInput}
            hasDependent={hasSpouse}
            label="Spouse"
            name="spouse"
            showEditView={setShowEditView}
          />
          <DependentsAddView
            dependentType={setDependentType}
            handleInput={handleInput}
            hasDependent={hasChildren}
            label="Child"
            name="dependent"
            showEditView={setShowEditView}
          />
        </>
      ) : (
        <DependentsInitialView
          anyDependents={anyDependents}
          handleChange={handleChange}
          input={input}
          showBtnLoader={showBtnLoader}
        />
      )}
    </>
  )
}
