import React from 'react';
import { useRouter } from 'next/router';
import Questions from './Questions';
import { Button } from '../../..';
import { useLeadDataReducer } from '../../../../hooks';
import { getFormFromCategory, handlePhoneOut } from '../../../../utils';
import { chunk } from '../../../../utils/array';
import { Segment } from '../../../../../types/segment';
import { Sanity } from '../../../../../types/sanity';
import { isCallCenterOpen } from '@/utils/call-center';

export interface Props {
  category: Segment.Category;
  phoneNumber: string;
  headline?: string;
  buttonText?: string;
  ctaText?: string;
  initialQuestions: NonNullable<Sanity.AdvertorialPage['quickFormQuestions']>;
}

type Question = {
  key: keyof typeof Questions;
  isRequired: boolean;
  isValid: boolean;
};

type InternalState = {
  questions: Question[];
  form: {
    isValid: boolean;
  };
};

const reducer = (
  state: InternalState,
  action: { index: number; isValid: boolean },
) => {
  state.questions[action.index].isValid = action.isValid;
  state.form.isValid = state.questions.every(
    (question) => !question.isRequired || question.isValid,
  );

  return { ...state };
};

const prepareQuestions = (questions: Props['initialQuestions']) =>
  questions.map((question) => ({
    key: question.question.key as keyof typeof Questions,
    isRequired: !!question.isRequired,
    isValid: !question.isRequired,
  }));

export const QuickForm: React.FC<Props> = (props) => {
  // setting default values for props from Sanity, since they are all optional
  const {
    category,
    phoneNumber,
    headline = '',
    buttonText = 'Compare Carriers',
    ctaText = 'Speak with a licensed sales agent at',
    initialQuestions,
  } = props;

  const preparedQuestions = initialQuestions ? prepareQuestions(initialQuestions) : [];
  const [state, dispatch] = React.useReducer(reducer, {
    questions: preparedQuestions,
    form: { isValid: false },
  });

  const router = useRouter();
  const form = getFormFromCategory(category, true) || 'get-quote';
  const [leadData, updateLeadData] = useLeadDataReducer();

  const handleIsValid = (index: number) => (value: boolean) => {
    dispatch({ index, isValid: value });
  };

  const handleSubmit = () => {
    if (state.form.isValid) {
      router.push(`/${form}/results`);
    }
  };

  const renderQuestions = (
    { key }: { key: keyof typeof Questions },
    index: number,
  ) => {
    const Question = Questions[key];
    const zIndex = 100 + (initialQuestions?.length || 0 - index);

    return (
      <div key={key} style={{ zIndex }} className="w-full">
        <Question
          leadDataGetter={leadData}
          leadDataSetter={updateLeadData}
          setIsValid={handleIsValid(index)}
        />
      </div>
    );
  };

  return (
    <form
      className="c-bg-blur py-15 lg:pt-17 lg:px-37 px-5-1/2 flex flex-col justify-center rounded-xl border-2 border-white sm:border-4 sm:px-10 lg:pb-12"
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
    >
      <h3 className="text-3-1/2xl text-center md:text-4xl">{headline}</h3>
      {initialQuestions && initialQuestions.length > 0 && (
        <div className="max-w-71 lg:pt-13 mx-auto flex w-full flex-col justify-center pt-10 pb-12 lg:max-w-none">
          {chunk(state.questions, 3).map((row, rowIdx) => (
            <React.Fragment key={rowIdx}>
              {rowIdx > 0 ? (
                <hr className="border-blue-navy border-opacity-7 my-6 w-full border" />
              ) : null}
              <div className="flex w-full flex-col justify-center space-y-3 lg:flex-row lg:space-y-0 lg:space-x-5">
                {row.map((args, colIdx) =>
                  renderQuestions(args, rowIdx * 3 + colIdx),
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {/* jornaya: */}
      <input
        tabIndex={-1}
        readOnly={true}
        autoComplete="off"
        id="leadid_token"
        name="universal_leadid"
        type="hidden"
        value=""
      />
      <div className="mx-auto mb-px max-w-fit">
        <Button
          type="submit"
          classes={!state.form.isValid ? 'border-current' : ''}
          disabled={!state.form.isValid}
        >
          {buttonText}
        </Button>
      </div>
      {isCallCenterOpen() && (
        <div className="mt-5 w-full text-center text-xl leading-10">
          <span className="text-blue-navy opacity-40">{ctaText.trim()} </span>
          <a
            className="whitespace-nowrap"
            href={`tel:+${phoneNumber}`}
            onClick={() => handlePhoneOut({ phoneNumber, category })}
          >
            <img
              className="mr-3-3/4 w-4-1/2 ml-3 inline"
              alt="phone"
              src="/static/icons/phone-cta-blue.svg"
            />
            <span className="text-blue-primary inline text-lg font-bold">
              {phoneNumber}
            </span>
          </a>
        </div>
      )}
    </form>
  );
};
