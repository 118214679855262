import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import {
  FOOTER_NAV_COMPANY,
  FOOTER_NAV_LEGAL,
  FOOTER_NAV_PRODUCT,
} from './utils'
import { Disclaimer } from '@/components/atoms/Disclaimer'

interface FooterProps {
  category: string
  licenseNum?: string
  smid?: string
}

export const Footer = (props: FooterProps) => {
  const router = useRouter()
  const isAdvantagePage = router.asPath === '/medicare/advantage/'
  const { category, licenseNum, smid } = props

  const sharedClasses = 'font-circular text-white text-sm text-opacity-50'
  const year = new Date().getFullYear()

  const FooterNavItem = ({ href, text }: { href: string; text: string }) => (
    <Link
      href={href}
      className={`hover:text-blue-primary py-2 ${sharedClasses}`}
    >
      {text}
    </Link>
  )

  return (
    <footer className="bg-blue-navy z-10 flex py-7 sm:py-9 md:py-12">
      <nav
        aria-label="footer"
        className="max-w-8xl xxl:px-14 xxxl:px-29 mx-auto w-full px-5 xl:px-12"
      >
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="flex h-full flex-col md:w-2/5">
            <Link href="/">
              <img
                alt="rate quote logo"
                className="h-10 w-48"
                src="/static/logos/rq-logo-white.svg"
              />
            </Link>

            <p
              className={`mt-3 ml-1 hidden tracking-wide ${
                category === 'health-insurance' ? '' : 'md:block'
              } ${sharedClasses}`}
            >
              &copy;{year} RateQuote LLC
            </p>
            <span className="w-full max-w-lg">
              <Disclaimer
                category={category}
                licenseNum={licenseNum}
                smid={isAdvantagePage ? 'SMID MULTIPLAN_RQLNDR_C' : smid}
              />
            </span>
          </div>
          <div className="flex flex-col text-white md:w-1/2 md:flex-row">
            <div className="mt-6 md:mt-0 md:ml-6 md:w-1/3">
              <h6 className="mb-2 text-sm uppercase tracking-widest opacity-80">
                company
              </h6>
              <hr className="mb-1 w-full border-white opacity-25" />
              <div className="flex flex-col">
                {FOOTER_NAV_COMPANY.map((item) => {
                  return (
                    <FooterNavItem
                      href={item.href}
                      key={item.name}
                      text={item.name}
                    />
                  )
                })}
              </div>
            </div>
            <div className="mt-6 md:mt-0 md:ml-6 md:w-1/3">
              <h6 className="mb-2 text-sm uppercase tracking-widest opacity-80">
                products
              </h6>
              <hr className="mb-1 w-full border-white opacity-25" />
              <div className="flex flex-col">
                {FOOTER_NAV_PRODUCT.map((item) => {
                  return (
                    <FooterNavItem
                      href={item.href}
                      key={item.name}
                      text={item.name}
                    />
                  )
                })}
              </div>
            </div>
            <div className="mt-6 md:mt-0 md:ml-6 md:w-1/3">
              <h6 className="mb-2 text-sm uppercase tracking-widest opacity-80">
                legal
              </h6>
              <hr className="mb-1 w-full border-white opacity-25" />
              <div className="flex flex-col">
                {FOOTER_NAV_LEGAL.map((item) => {
                  return (
                    <FooterNavItem
                      href={item.href}
                      key={item.name}
                      text={item.name}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute bottom-24 right-0 tracking-wide">
          <img
            src="/static/logos/bbb-base-white.svg"
            alt="Better Business Bureau® Certified Business"
          />
        </div>
        <p
          className={`mt-6 tracking-wide md:mt-0 md:text-right ${
            category === 'health-insurance' ? '' : 'md:hidden'
          } ${sharedClasses}`}
        >
          &copy;{year} RateQuote LLC
        </p>
      </nav>
    </footer>
  )
}
