import React, { useState } from 'react'
import { ErrorMessage } from '../../atoms'
import { Button, InputAction } from '../../index'
import {
  DropdownSuggestion,
  DropdownSuggestionOption,
} from '../../molecules/DropdownSuggestion'

export interface SearchMatchProps {
  autocompleteOnChange: (
    item: DropdownSuggestionOption[] | undefined,
    searchValue: string,
  ) => void
  onClick: (
    items: DropdownSuggestionOption[] | undefined,
    searchValue: string,
  ) => void
  options: DropdownSuggestionOption[]
  placeholder?: string
}

export const SearchMatch = (props: SearchMatchProps) => {
  const { autocompleteOnChange, onClick, options, placeholder } = props
  const [items, setItems] = useState<DropdownSuggestionOption[] | undefined>(
    undefined,
  )
  const [searchValue, setSearchValue] = useState('')
  const [error, setError] = useState(false)

  return (
    <div className="z-50 mx-auto py-4 sm:max-w-sm ">
      <InputAction
        buttonComponent={
          <Button
            icon={
              <img
                tabIndex={-1}
                className="mr-2 block h-6 w-5"
                src="/static/icons/search.svg"
              />
            }
            onClick={() => {
              const _error =
                searchValue.length > 0 && !!items && items.length === 0
              setError(_error)
              onClick(_error ? options : items, searchValue)
            }}
          >
            Search
          </Button>
        }
        inputComponent={
          <DropdownSuggestion
            id="search-match"
            placeholder={placeholder}
            // labelid="search-match"
            // name="search-match"
            options={options}
            onSelect={(items, searchValue) => {
              const _error =
                searchValue.length > 0 && !!items && items.length === 0
              setError(_error)
              if (_error) {
                return onClick(options, '')
              }
              onClick(items, searchValue)
            }}
            onChange={(items, searchValue) => {
              setItems(items)
              setSearchValue(searchValue)
              autocompleteOnChange(items, searchValue)
            }}
          />
        }
      />
      {error && <ErrorMessage message="No results found" />}
    </div>
  )
}
