export const FOOTER_NAV_COMPANY = [
  {
    name: 'About Us',
    href: '/about/',
  },
  {
    name: 'Guides',
    href: '/guides/',
  },
  {
    name: 'Partner With Us',
    href: '/partner/',
  },
  {
    name: 'Marketing Partners',
    href: '/marketing-partners/',
  },
]

export const FOOTER_NAV_LEGAL = [
  {
    name: 'Terms & Conditions',
    href: '/terms-conditions/',
  },
  {
    name: 'Privacy Policy',
    href: '/privacy-policy/',
  },
  {
    name: 'Do Not Sell My Information',
    href: '/do-not-sell-my-personal-information/',
  },
  {
    name: 'Site Map',
    href: '/sitemap/',
  },
  {
    name: 'Non-Discrimination Statement',
    href: '/non-discrimination-statement/',
  },
  {
    name: 'Accessibility',
    href: '/accessibility/',
  },
]

export const FOOTER_NAV_PRODUCT = [
  {
    name: 'Health Insurance',
    href: '/health-insurance-quote/',
  },
  {
    name: 'Medicare',
    href: '/medicare-quote/',
  },
]
