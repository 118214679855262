import * as React from 'react'
import { Wrapper } from './Wrapper'
import { Button } from '../../../..'
import type { QuestionProps } from './types'

export const CreditScore: React.FC<QuestionProps> = (props) => {
  const [selected, setSelected] = React.useState(false)

  React.useEffect(() => {
    const initialValue = props.leadDataGetter['creditScore']

    if (initialValue === undefined) {
      props.leadDataSetter({ key: 'creditScore', value: 'not_sure' })
      setSelected(false)
    } else {
      setSelected(isGoodValue(initialValue))
    }
  }, [])

  const handleClick = () => {
    const isNowSelected = !selected
    setSelected(isNowSelected)

    if (isNowSelected) {
      props.leadDataSetter({
        key: 'creditScore',
        value: 'good',
      })
    } else {
      props.leadDataSetter({
        key: 'creditScore',
        value: 'not_sure',
      })
    }
  }

  return (
    <Wrapper>
      <Button
        id="qf-credit-score"
        name="qf-credit-score"
        classes={`${
          selected ? 'qf-btn--selected' : 'qf-btn--unselected'
        } qf-btn--base`}
        icon={
          <img alt="checkmark" className="mr-4" src="/static/icons/check.svg" />
        }
        onClick={handleClick}
        role="transparent"
      >
        Good credit
      </Button>
    </Wrapper>
  )
}

function isGoodValue(value: string | undefined) {
  return value === 'excellent' || value === 'good'
}
