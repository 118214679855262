import { isExists } from 'date-fns'
import { getAge } from '../form-helpers'

export const validateZipcode = (value: number | string | null) => {
  return /^\d{5}$/.test(value as string)
}

const isPastDate = (date: { day: string; month: string; year: string }) => {
  const currentDate = new Date()
  const parsedDate = new Date(date.year + '/' + date.month + '/' + date.day)

  return currentDate > parsedDate
}
/**
 * @param date
 * @param validations: validation keys to test against `date` parameter; these validations are pre-determined
 * @returns {boolean} Truthy if every validation in `validations` is truthy or falsy if at least one validation is `false`
 */
export const validateAge = (
  date: {
    day: string
    month: string
    year: string
  },
  validations: Array<'age-high' | 'age-low' | 'age-under64'>,
): boolean => {
  const validate = (validationKey: string) => {
    const age = getAge(date.day, date.month, date.year) || 0
    const dobIsValidHigh = age <= 120 ? true : false
    const dobIsValidLow = age >= 18 ? true : false
    const dobIsValidUnder64 = age < 64 ? true : false
    switch (validationKey) {
      case 'age-high':
        return dobIsValidHigh
      case 'age-low':
        return dobIsValidLow
      case 'age-under64':
        return dobIsValidUnder64
      default:
        return false
    }
  }

  return validations.map((key) => validate(key)).every((i) => i)
}

/**
 * @param date
 * @param validations: validation keys to test against `date` parameter; these validations are pre-determined
 * @returns {boolean} Truthy if every validation in `validations` is truthy or falsy if at least one validation is `false`
 */
export type DateValidations = Array<
  'date-day' | 'date-month' | 'date-year' | 'date-exists' | 'future-date'
>

export const validateDate = (
  date: {
    day: string
    month: string
    year: string
  },
  validations: DateValidations,
): boolean => {
  const validate = (validationKey: string) => {
    const dateExists = isExists(
      Number(date.year),
      Number(date.month) - 1,
      Number(date.day),
    )

    switch (validationKey) {
      case 'date-exists':
        return dateExists
      case 'date-day':
        return /(^\d{2}$)/.test(date.day)
      case 'date-month':
        return /(^\d{2}$)/.test(date.month)
      case 'date-year':
        return /(^\d{4}$)/.test(date.year)
      case 'future-date':
        return isPastDate(date)
      default:
        return false
    }
  }

  return validations.map((key) => validate(key)).every((i) => i)
}
