import React from 'react'
import { HeightWeight } from './HeightWeight'
import { FormLabel } from '../../../templates/Form/Label'
import { TobaccoPanel } from '../../..'
import { RateQuote } from '../../../../../types'

interface Props_ {
  handleSelect: (val: string, key?: string | undefined) => void
  userDidSubmit: boolean
  validate: ({
    key,
    force,
  }: {
    key: string
    force?: boolean | undefined
  }) => boolean
}

type Props = Props_ & RateQuote.FormPanel

export const TobaccoHeightWeightPanel = (props: Props) => {
  const { handleInput, handleSelect, input, userDidSubmit, validate, value } =
    props
  const { tobacco, biometrics } = value

  return (
    <div className="flex w-full flex-col items-center">
      <div className="border-blue-navy w-full border-b border-opacity-50 pb-6">
        <HeightWeight
          value={{ height: biometrics.height, weight: biometrics.weight }}
          handleInput={handleInput}
          userDidSubmit={userDidSubmit}
          validate={validate}
        />
      </div>
      <div className="flex w-full flex-col py-6">
        <FormLabel
          className="sm:leading-300 md:leading-302 mb-3 sm:mb-6"
          as={'label'}
        >
          Tobacco Use?
        </FormLabel>
        <TobaccoPanel
          handleInput={handleSelect}
          value={tobacco}
          input={input}
          required
          userDidSubmit={userDidSubmit}
        />
      </div>
    </div>
  )
}
