import React from 'react'

import { InputBox } from '../../atoms'
import { DateValidations, validateAge, validateDate } from '@/utils'
import { RateQuote } from '../../../../types'

interface Props_ {
  optional?: boolean
  requireAdult?: boolean
  userDidSubmit: boolean
  noFutureDate?: boolean
}

type Props = Props_ & Omit<RateQuote.FormPanel, 'input'>

export function DatePanel(props: Props) {
  const {
    handleInput,
    optional = false,
    requireAdult = false,
    userDidSubmit,
    noFutureDate,
    value,
  } = props

  const fields = [
    {
      id: 'date_month',
      key: ['date-month'],
      label: 'Month',
      length: 2,
      name: 'month',
      placeholder: 'MM',
    },
    {
      id: 'date_day',
      key: ['date-day'],
      label: 'Day',
      length: 2,
      name: 'day',
      placeholder: 'DD',
    },
    {
      id: 'date_year',
      key: ['date-year'],
      label: 'Year',
      length: 4,
      name: 'year',
      placeholder: 'YYYY',
    },
  ]

  const validation: DateValidations = ['date-day', 'date-month', 'date-year']
  if (noFutureDate) validation.push('future-date')
  const inputIsValid = validateDate(value, validation)

  const showError = (field: { key: string[] }): boolean =>
    (userDidSubmit &&
      optional &&
      value.day &&
      value.month &&
      value.year &&
      !validateDate(value, field.key as DateValidations)) ||
    (userDidSubmit &&
      !optional &&
      !validateDate(value, field.key as DateValidations))

  return (
    <>
      <div className="flex gap-x-4">
        {fields.map((field) => (
          <div key={field.id}>
            <div id={`get-quotes-label--${field.id}`} className="c-form-label">
              {field.label}
            </div>
            <InputBox
              error={showError(field)}
              errorMsg={`Please enter a ${field.length} digit ${field.name}.`}
              id={`get-quotes--${field.id}`}
              inputMode="numeric"
              maxLength={field.length}
              name={`${field.id}`}
              onChange={(val: string) => {
                handleInput(val, field.name)
              }}
              placeholder={field.placeholder}
              value={value[field.name]}
            />
          </div>
        ))}
      </div>
      {userDidSubmit &&
        inputIsValid &&
        (!validateDate(value, ['date-exists', 'future-date']) ||
          !validateAge(value, ['age-high'])) && (
          <div className="text-red-error mt-6">Please enter a valid date.</div>
        )}
      {userDidSubmit &&
        !inputIsValid &&
        noFutureDate &&
        !validateDate(value, ['future-date']) && (
          <div className="text-red-error mt-6">Please enter a valid date.</div>
        )}
      {userDidSubmit &&
        inputIsValid &&
        validateDate(value, ['date-exists']) &&
        !validateAge(value, ['age-low']) &&
        requireAdult && (
          <div className="text-red-error mt-6">You must be 18 or older.</div>
        )}
    </>
  )
}
