import React from 'react'
import { toLower } from 'lodash'

import { DatePanel, GenderPanel, InputBox } from '../../'
import { RateQuote } from '../../../../types'

interface Props_ {
  handleInput: (val: string, key?: string | undefined) => void
  handleInputDate: (val: string, key?: string | undefined) => void
  handleInputGender: (val: string, key?: string | undefined) => void
  inputRef: React.RefObject<HTMLInputElement>
  requireAdult?: boolean
  userDidSubmit: boolean
  validate: ({
    key,
    force,
  }: {
    key: string
    force?: boolean | undefined
  }) => boolean
}

type Props = Props_ & Omit<RateQuote.FormPanel, 'input'>

export const ContactNameDOBGenderPanel = (props: Props) => {
  const {
    handleInput,
    handleInputDate,
    handleInputGender,
    inputRef,
    requireAdult,
    userDidSubmit,
    validate,
    value,
  } = props
  const { birthDate, gender, name } = value

  const questions = [
    {
      id: 'first-name',
      inputMode: 'text',
      label: 'First Name',
      name: 'fName',
      type: 'name',
    },
    {
      id: 'last-name',
      inputMode: 'text',
      label: 'Last Name',
      name: 'lName',
      type: 'name',
    },
  ]

  return (
    <div className="flex w-full flex-col items-center">
      <div className="border-blue-navy flex w-full flex-col gap-x-4 border-b border-opacity-50 py-6">
        <p className="mb-6 text-2xl capitalize tracking-wider">name</p>
        <div className="flex space-x-4">
          {questions.map((field: any) => (
            <div
              className="flex w-full flex-col items-start pb-4"
              key={field.id}
            >
              <div className="text-lg">{field.label}</div>
              <InputBox
                error={!validate({ key: field.id })}
                errorMsg={`Please enter a ${toLower(field.label)}.`}
                id={`health-insurance-quotes--${field.id}`}
                inputMode={field.inputMode}
                mask={field.mask}
                name={`${field.id}`}
                onChange={(val: string) => {
                  handleInput(val, field.name)
                }}
                pattern={field.pattern}
                type={field.type}
                value={name[field.name]}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="border-blue-navy w-full border-b border-opacity-50 pt-4 pb-10">
        <p className="mb-6 text-2xl capitalize tracking-wider">date of birth</p>
        <DatePanel
          handleInput={handleInputDate}
          requireAdult={requireAdult}
          userDidSubmit={userDidSubmit}
          value={birthDate}
          noFutureDate
        />
      </div>
      <div className="w-full pt-4">
        <p className="mb-6 text-2xl capitalize tracking-wider">gender</p>
        <GenderPanel
          handleInput={handleInputGender}
          input={inputRef}
          userDidSubmit={userDidSubmit}
          value={gender}
        />
      </div>
    </div>
  )
}
