import {
  getDefaultAdvertorialFromCategory,
  getFormFromCategory,
  pathForNextStep,
} from '../'
import { Segment } from '../../../types/segment'

export const getCtaDestination = (
  category: Segment.Category | undefined,
  currStep = 0,
  params?: any,
): { pathname: string; query?: string } => {
  // get form
  const form = getFormFromCategory(category)
  // get advertorial
  const advertorial = getDefaultAdvertorialFromCategory(category)
  // return form if it exists
  // if not, return advertorial if it exists,
  // if not, return 'get-quote' form as last resort
  return form
    ? pathForNextStep(currStep, form, params)
    : advertorial
    ? advertorial
    : pathForNextStep(currStep, 'get-quote', params)
}
