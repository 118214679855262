import * as Sentry from '@sentry/nextjs'
import { LeadData } from '../../hooks/use-lead-data/types'
import { segment } from '../../utils'
import { v4 as uuid } from 'uuid'
import { useLeadData } from '../../hooks'

declare global {
  interface Window {
    ringba: any
    ringba_uuid: any
    ringba_known_numbers: any
    _rgba: any
    _rgba_tags: any
    FB: any
  }
}

//ringba id counter
let functionCallCounter = 1
let wasCurrentAdded = false
let wasProviderAdded = false

export const addInsuranceCurrentToRingba = (
  insuranceCurrent: LeadData.Auto['autoInsuranceCurrent'],
) => {
  if (insuranceCurrent && !wasCurrentAdded) {
    try {
      wasCurrentAdded = true
      const _insuranceCurrent: 'true' | 'false' =
        insuranceCurrent == '1' ? 'true' : 'false'
      window.ringba.addTags([{ insuranceCurrent: _insuranceCurrent }])
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}

export const addInsuranceProviderToRingba = (
  insuranceProvider: LeadData.Auto['autoInsuranceProvider'],
) => {
  if (insuranceProvider && !wasProviderAdded) {
    try {
      wasProviderAdded = true
      window.ringba.addTags([{ insuranceProvider }])
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}

function getGclid() {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('gclid') || null
}

export async function addSegmentIdToRingba() {
  let iteration = 0
  const segmentId = await segment.getAnonymousId()
  let gclid = getGclid() // Retrieve the gclid parameter from the URL

  const interval: ReturnType<typeof setTimeout> = await setInterval(
    async () => {
      if (window && window.ringba) {
        if (gclid) {
          window.ringba.addTags([{ gclid: gclid }])
          gclid = null
        }

        if (segmentId) {
          window.ringba.addTags([{ segmentId: segmentId }, { test: isTest() }])
          return clearInterval(interval)
        } else {
          console.error('Could not retrieve segmentId')
          window.ringba.addTags([{ test: isTest() }])
          // if iteration is over 5 secs user probably has some sort of adblock
          return clearInterval(interval)
        }
      }
      if (iteration >= 10) {
        Sentry.captureException('Could not load ringba')
        return clearInterval(interval)
      }
      iteration++
    },
    500,
  )
}

/*
 * set ids to be send to ringba
 * Formerly setRingbaIds via sovereign/rq-www
 */

export function setOrderId() {
  const orderId = uuid() //order_id to use in the handle_phone_out event

  const newOrderId: any = {}

  newOrderId[`orderId${functionCallCounter}`] = orderId

  if (window && window.ringba) window.ringba.addTags([newOrderId])

  //iterate orderId
  functionCallCounter++
  if (functionCallCounter > 10) {
    Sentry.captureException(
      'User clicked phone number >10 times in one session',
    )
  }
  return orderId
}

export function addUserInformationToRingba() {
  const [autoInsuranceProvider] = useLeadData('autoInsuranceProvider')
  const [autoInsuranceCurrent] = useLeadData('autoInsuranceCurrent')
  addInsuranceCurrentToRingba(
    autoInsuranceCurrent as LeadData.Auto['autoInsuranceCurrent'],
  )
  addInsuranceProviderToRingba(
    autoInsuranceProvider as LeadData.Auto['autoInsuranceProvider'],
  )
}

const isTest = () => {
  return process.env.DEPLOYMENT_TARGET !== 'production'
}
