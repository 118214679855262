/* eslint-disable @typescript-eslint/no-unused-vars */
export namespace LeadData {
  // The Base interface defines keys that are common to all leads,
  // regardless of the vertical.
  interface Base {
    contactAddress: string
    contactAptSteOther: string | undefined
    contactCity: string
    contactCounty: string | undefined
    contactEmail: string
    contactFirstName: string
    contactLastName: string
    contactPhoneNumber: string
    contactState: string
    contactZip: string
    contactZipSuffix: string | undefined
    zipcode: string
  }

  // The Auto interface defines keys that are specific to the
  // auto forms.
  interface Auto extends Base {
    autoInsuranceCurrent: '0' | '1'
    autoInsuranceProvider?:
      | '21st_century_insurance'
      | 'aig'
      | 'allstate_insurance'
      | 'american_family_insurance'
      | 'country_financial'
      | 'esurance'
      | 'farmers_insurance'
      | 'geico'
      | 'liberty_mutual_insurance'
      | 'metlife_auto_and_home'
      | 'nationwide_general_insurance'
      | 'progressive'
      | 'state_farm_county'
      | 'travelers_insurance_company'
      | 'usaa'
      | 'other'
    birthDay: Day
    birthMonth: Month
    birthYear: string
    creditScore?: 'excellent' | 'good' | 'average' | 'poor' | 'not_sure'
    driverLicense?: '0' | '1'
    driverSr22?: '0' | '1'
    education?:
      | 'incomplete'
      | 'high school'
      | 'some college'
      | 'associate'
      | 'bachelor'
      | 'master'
      | 'phd'
    gender: 'm' | 'f'
    homeowner?: '0' | '1'
    incidentsAccident?: '0' | '1'
    incidentsClaim?: '0' | '1'
    incidentsDui?: '0' | '1'
    incidentsSuspension?: '0' | '1'
    incidentsTickets?: '0' | '1'
    married?: '0' | '1'
    military?: '0' | '1'
    secondVehicle?: '0' | '1'
    vehicleMake1?: string
    vehicleModel1?: string
    vehicleYear1?: string
    vehicleMake2?: string
    vehicleModel2?: string
    vehicleYear2?: string
  }

  // The HealthBase interface defines keys that are common to all health-related leads,
  // including Medicare and U65.
  interface HealthBase extends Base {
    birthDay?: Day
    birthMonth?: Month
    birthYear?: string
    gender?: 'm' | 'f'
    tobacco: '0' | '1'
  }

  // The Medicare interface defines keys that are specific to the
  // Medicare form, `medicare-quote`.
  interface Medicare extends HealthBase {
    medicareInsuranceCurrent: '0' | '1'
    medicareCoverageType?:
      | 'medicare_advantage'
      | 'medicare_supplemental'
      | 'medicare_part_d'
      | 'other_medicare'
  }

  // The HealthInsurance interface defines keys that are specific to the
  // U65 form, `health-insurance-quote`.
  interface HealthInsurance extends HealthBase {
    dependents?: Dependent[]
    healthInsuranceCurrent?: '0' | '1'
    healthInsuranceCoverageType?: HealthInsuranceCoverageType
    healthInsuranceProvider?: HealthInsuranceProviders
    healthInsuranceDependents?: string // how many children/dependents
    healthInsuranceSpouse?: '0' | '1' // is spouse included in plan?
    height?: string
    householdIncome:
      | '47000_and_over'
      | '29000-46999'
      | '12000-28999'
      | 'below_12000'
    majorConditionAidsHiv?: '0' | '1'
    majorConditionAlcoholDrugAbuse?: '0' | '1'
    majorConditionAlzheimersDementia?: '0' | '1'
    majorConditionAsthma?: '0' | '1'
    majorConditionCancer?: '0' | '1'
    majorConditionChronicPain?: '0' | '1'
    majorConditionClinicalDepression?: '0' | '1'
    majorConditionDiabetes?: '0' | '1'
    majorConditionEmphysema?: '0' | '1'
    majorConditionEpilepsy?: '0' | '1'
    majorConditionHeartAttack?: '0' | '1'
    majorConditionHeartDisease?: '0' | '1'
    majorConditionHepatitisLiver?: '0' | '1'
    majorConditionHighBloodPressure?: '0' | '1'
    majorConditionHighCholesterol?: '0' | '1'
    majorConditionKidneyDisease?: '0' | '1'
    majorConditionMentalIllness?: '0' | '1'
    majorConditionMultipleSclerosis?: '0' | '1'
    majorConditionPulmonaryDisease?: '0' | '1'
    majorConditionRespiratoryDisorder?: '0' | '1'
    majorConditionStroke?: '0' | '1'
    majorConditionUlcers?: '0' | '1'
    majorConditionVascularDisease?: '0' | '1'
    majorConditionOther?: '0' | '1'
    pregnant?: '0' | '1'
    qualifyingLifeEvent?:
      | 'none'
      | 'marriage_divorce'
      | 'job_loss_change'
      | 'permanent_move'
      | 'birth_adoption_child'
      | 'other'
    qualifyingLifeEventDay?: Day
    qualifyingLifeEventMonth?: Month
    qualifyingLifeEventYear?: string
    spouse?: Dependent
    weight?: string
  }

  interface Dependent {
    birthDay?: Day
    birthMonth?: Month
    birthYear?: string
    firstName?: string
    gender?: 'm' | 'f'
    height?: string
    lastName?: string
    tobacco?: '0' | '1'
    weight?: string
  }

  type All = Auto & Medicare & HealthInsurance
}

type Day =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31'

type Month =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'

type HealthInsuranceCoverageType =
  | 'individual_family'
  | 'short_term'
  | 'cobra'
  | 'discount_plan'
  | 'medicaid'
  | 'maternity'
  | 'dental'
  | 'vision'
  | 'prescription'
  | 'other'

type HealthInsuranceProviders =
  | 'aetna'
  | 'aflac'
  | 'american_family_insurance'
  | 'american_republic_insurance'
  | 'assurant'
  | 'atlantis_health_plan'
  | 'blue_cross_blue_shield'
  | 'celtic_insurance'
  | 'cigna'
  | 'farm_bureau_insurance'
  | 'golden_rule_insurance'
  | 'health_net'
  | 'health_plus_of_america'
  | 'healthmarkets'
  | 'humana'
  | 'insphere'
  | 'kaiser_permanente'
  | 'lifewise_health_plan'
  | 'metlife_insurance'
  | 'oxford_health_plans'
  | 'pacificare'
  | 'state_farm_insurance'
  | 'time_insurance'
  | 'tufts_health_plan'
  | 'unicare'
  | 'united_american_insurance'
  | 'united_healthcare'
  | 'vista_health_plan'
  | 'other'
