import * as Sentry from '@sentry/nextjs'
import { ls } from '../'

export const clearRingbaPoolInstance = () => {
  // Remove Ringba instances on Window
  try {
    delete window.ringba
    delete window.ringba_known_numbers
    delete window._rgba
    delete window._rgba_tags
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  }

  // Remove Ringba instances in Local Storage
  try {
    Object.keys(localStorage).map((key) => {
      if (key.includes('ringbaNumber_')) {
        ls.removeItem(key)
      }
    })
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  }

  // Remove any existing Ringba script before injecting a new one
  const prevScript = document.getElementById('ringba_script')

  if (prevScript) {
    prevScript.remove()
  }
}
