import React from 'react'
import { Button } from '../..'

export interface SelectGridItemProps {
  /**
   * Add optional custom classes to override the default styling
   */
  classes?: string
  /**
   * The item's label
   */
  label: string
  /**
   * Optional icon to display to the left of the label
   */
  icon?: React.ReactNode
  index?: number
  loading?: boolean
  /**
   * Action to occur when the Item is selected
   * */
  onChange: () => void
  /**
   * Pass a React state to track whether or not the Item has been selected
   * */
  selected: boolean
}

export function SelectGridItem(props: SelectGridItemProps) {
  const {
    classes = '',
    label,
    icon,
    loading = false,
    onChange,
    selected,
    index,
  } = props
  const [style, setStyle] = React.useState(
    'bg-white-primary border-blue-navy border-opacity-10',
  )

  React.useEffect(() => {
    selected
      ? setStyle('bg-blue-lightblue-6 border-blue-lightblue-7')
      : setStyle('bg-white-primary border-blue-navy border-opacity-10')
  }, [selected])
  return (
    <Button
      {...props}
      classes={`c-select-grid-item-base ${style} ${classes}`}
      icon={icon}
      tabIndex={1}
      role="primary"
      key={`grid-item-${index}`}
      data-index={`${index}`}
      loader={loading}
      loaderVariant="dark"
      onClick={() => {
        !loading && onChange()
      }}
      selected={selected}
    >
      {label}
    </Button>
  )
}
