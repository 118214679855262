import React, { FC, HTMLAttributes } from 'react'
export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: string | React.ReactNode
  classes?: string
  disabled?: boolean
  /**
   * hides button text
   */
  hideButtonText?: boolean
  /**
   * an icon as a React node
   */
  icon?: React.ReactNode
  /**
   * state to control whether the loading animation is shown
   */
  loader?: boolean
  /**
   * which variant of the loading animation to render
   */
  loaderVariant?: 'light' | 'dark'
  name?: string
  /**
   * On a click, the button fires the function defined and completely
   * owned by the parent. No data is passed, and all side-effects are assumed to
   * be defined by the parent component.
   **/
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * The design role of the button. Defines the background color, font color,
   * and text decoration.
   * @default primary
   */
  role?: 'primary' | 'secondary' | 'transparent'
  selected?: boolean
  type?: 'submit' | 'button' | 'reset'
}

export const Button: FC<ButtonProps> = (props: ButtonProps) => {
  const {
    children,
    classes = '',
    disabled = false,
    hideButtonText,
    icon,
    loader,
    loaderVariant = 'light',
    name,
    onClick,
    role = 'primary',
    selected,
    type = 'button',
  } = props

  const btnRole =
    role === 'primary'
      ? 'c-btn-primary'
      : role === 'secondary'
      ? 'c-btn-secondary'
      : 'c-btn-transparent'

  const [isDisabled, setIsDisabled] = React.useState('')

  React.useEffect(() => {
    disabled ? setIsDisabled('c-btn-disabled') : setIsDisabled('')
  }, [disabled])

  return (
    <button
      {...props}
      role="button"
      aria-selected={selected}
      className={`c-btn-base transition-colors ${isDisabled} ${btnRole} ${classes}`}
      disabled={!!isDisabled}
      name={name}
      onClick={onClick}
      type={type}
    >
      {
        <div
          className={`${
            hideButtonText || (selected && loader)
              ? 'motion-safe:invisible'
              : 'flex items-center justify-center'
          }`}
        >
          {icon && icon}
          {children}
        </div>
      }
      {loader && (
        <>
          {hideButtonText && (
            <img
              alt="loading icon"
              className="c-loader absolute left-0 right-0 m-auto w-6 motion-reduce:hidden"
              src={`/static/other/loading-spinner-${loaderVariant}.svg`}
            />
          )}
          {selected && (
            <img
              alt="loading icon"
              className="c-loader absolute left-0 right-0 m-auto w-6 motion-reduce:hidden"
              src={`/static/other/loading-spinner-${loaderVariant}.svg`}
            />
          )}
        </>
      )}
    </button>
  )
}
