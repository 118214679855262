import React, { useState, useEffect } from 'react'
import { ClickawayListener } from '../..'

export interface DropdownSuggestionOption {
  label: string
  value: string
  icon?: React.ReactNode
}

export interface DropdownSuggestionProps {
  /**
   * Unique styling applied to the container of the entire dropdown.  Try to apply styling to the child component here when possible.
   */
  containerClasses?: string
  /**
   * Unique styling applied to the primary button portion of the dropdown.  Try to apply styling to the child component here when possible.
   */
  btnClasses?: string
  /**
   * Unique styling applied to the dropdown menu portion of the dropdown.  Try to apply styling to the child component here when possible.
   */
  dropdownClasses?: {
    wrapper: string
    menu: string
  }
  /**
   * Dropdown menu will not be reachable. Be sure to remove required when applying 'disabled'.
   */
  disabled?: boolean
  /**
   * Pass an id for the Dropdown menu, required for accessibility.
   */
  id: string
  /**
   * The options that you want to show in the Dropdown menu.
   */
  options: DropdownSuggestionOption[]
  /**
   * Optional function to handle the value entered.  You will likely setState() in the parent with this onChange.
   */
  onChange?: (
    selectedVal: DropdownSuggestionOption[] | undefined,
    searchValue: string,
  ) => void
  /**
   * Optional function to handle the value entered.  You will likely setState() in the parent with this onChange.
   */
  onSelect?: (
    selectedVal: DropdownSuggestionOption[] | undefined,
    searchValue: string,
  ) => void
  /**
   * Often thought of as helper text inside the Dropdown menu to guide them towards opening the dropdown for options.
   * */
  placeholder?: string
  /**
   * Make the dropdown menu data selection mandatory.  There is a default error message if required and not entered.  You can also create a unique error message passed via props.
   */
  required?: boolean
}

export const DropdownSuggestion = (props: DropdownSuggestionProps) => {
  const {
    containerClasses,
    btnClasses,
    dropdownClasses = { wrapper: '', menu: '' },
    disabled,
    id,
    options,
    onChange,
    onSelect,
    placeholder = 'Select One',
  } = props

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredOptions, setFilteredOptions] = useState<
    DropdownSuggestionOption[] | undefined
  >(undefined)

  const onSearch = (value: string, select?: boolean) => {
    setOpen(!select) //if selected closes the menu
    let _filteredOptions = undefined

    setSearch(value)
    if (value.length > 0) {
      _filteredOptions = options.filter((option) =>
        option.label?.match(new RegExp(value, 'i')),
      )
      setFilteredOptions(_filteredOptions)
      if (_filteredOptions) {
        onChange ? onChange(_filteredOptions, value) : null
      }
    } else {
      setFilteredOptions(undefined)
      onChange ? onChange(undefined, value) : null
      onSelect ? onSelect(undefined, value) : null
    }
    if (select) {
      onSelect ? onSelect(_filteredOptions, value) : null
    }
  }

  useEffect(() => {
    if (search.length <= 1 || (filteredOptions && filteredOptions.length === 0))
      setOpen(false)
  }, [filteredOptions])

  const handleSelection = (selectedVal: DropdownSuggestionOption) => {
    onSearch(selectedVal.label, true)
  }

  return (
    <ClickawayListener setState={setOpen}>
      <div className="relative h-full w-full">
        <div
          className={`absolute z-50 h-full w-full ${
            !open ? 'rounded-xs' : 'rounded-t-xs rounded-b-none'
          } font-roboto 
      ${
        disabled
          ? 'bg-grayscale-3 hover:border-grayscale-1 pointer-events-none'
          : 'bg-white'
      } ${containerClasses} `}
          id={id}
        >
          <div
            aria-expanded={open}
            aria-haspopup="true"
            className={`/ rounded-xs   relative z-10 flex h-full w-full items-center rounded-r-none border-solid bg-white 
      px-4 py-3 ${
        !open ? 'rounded-b-xs border-2' : 'rounded-b-none border-2 border-b-0'
      } ${btnClasses} focus:outline-none`}
            tabIndex={-1}
            id={`${id}-options-menu`}
          >
            <div className="relative flex w-full items-center">
              <input
                className="absolute w-full focus:outline-none"
                placeholder={placeholder}
                onKeyUp={(e) => {
                  const element = e.target as HTMLInputElement

                  if (
                    (element.value.length === 0 && e.key === 'Backspace') ||
                    e.key === 'Enter'
                  ) {
                    onSearch(element.value, true)
                  }
                  e.stopPropagation()
                  e.preventDefault()
                }}
                onChange={(e) => {
                  onSearch(e.target.value)
                }}
                value={search}
              />
            </div>
          </div>
          <div
            tabIndex={2}
            className={`/ relative z-0 -mt-0.5 bg-white
                   ${dropdownClasses.wrapper} 
                  ${!open ? 'hidden' : 'flex'} bg-transparent`}
          >
            <div
              id="dropdown-menu"
              className={`rounded-b-xs absolute z-0 max-h-64
                  w-full overflow-y-auto border-2 border-t-0 border-solid bg-white ${dropdownClasses.menu}`}
            >
              <ul
                aria-orientation="vertical"
                aria-labelledby={`${id}-options-menu`}
                className="w-full bg-white py-2"
                role="menu"
              >
                {filteredOptions &&
                  filteredOptions.map((item: DropdownSuggestionOption) => (
                    <li key={item.value} role="menuitem">
                      <button
                        className={`font-roboto hover:bg-blue-lightblue-6 hover:text-blue-lightblue-3 focus:bg-blue-lightblue-6 focus:text-blue-lightblue-3 w-full px-3
                   py-2 text-left text-base font-normal capitalize focus:outline-none
                   `}
                        tabIndex={0}
                        onClick={(e) => {
                          handleSelection(item)
                          e.stopPropagation()
                          e.preventDefault()
                        }}
                      >
                        {item.label || item.value}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ClickawayListener>
  )
}
