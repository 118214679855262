import React from 'react'

export interface InputActionProps {
  buttonComponent: React.ReactElement
  inputClasses?: string
  inputComponent: React.ReactElement
  variant?: 'blue' | 'green'
}

export const InputAction = (props: InputActionProps) => {
  const { inputClasses, variant = 'blue' } = props

  const borderStyle =
    variant === 'blue'
      ? 'border-blue-primary group-hover:border-blue-navy focus:border-blue-primary'
      : 'border-green-primary group-hover:border-green-dark'

  return (
    <div className="group rounded-xs min-h-14 flex w-full">
      <div className={`c-input-action-base ${borderStyle} ${inputClasses}`}>
        {React.cloneElement(props.inputComponent, {
          classes: `${props.inputComponent.props.classes} bg-transparent border-none`,
          containerClasses: `${props.inputComponent.props.classes} h-full bg-transparent border-none`,
          btnClasses: `${props.inputComponent.props.classes} border-none`,
          dropdownClasses: {
            wrapper: `${props.inputComponent.props.classes} -mx-0.5`,
            menu: `${props.inputComponent.props.classes} border-2 -mt-2 pt-2 ${borderStyle}`,
          },
        })}
      </div>
      <div>
        {React.cloneElement(props.buttonComponent, {
          classes: `${
            props.buttonComponent.props.classes
          } rounded-l-none rounded-r-xs border-none h-full ${
            variant === 'green'
              ? 'bg-green-primary group-hover:bg-green-dark text-blue-navy focus-visible:bg-green-primary'
              : 'group-hover:bg-blue-navy'
          }`,
        })}
      </div>
    </div>
  )
}
