/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Disclaimer } from '@/components/atoms/Disclaimer'
import Link from 'next/link'
export interface LandingFooterProps {
  category: string
  smid?: string
  licenseNum?: string
}

export const LandingFooter = ({
  category,
  smid,
  licenseNum,
}: LandingFooterProps) => {
  return (
    <footer className="bg-blue-navy z-10 flex py-7 sm:py-9 md:py-12">
      <nav
        aria-label="footer"
        className="max-w-8xl xxl:px-14 xxxl:px-29 mx-auto w-full px-5 xl:px-12"
      >
        {category === 'u65' ? (
          <div className="font-text font-roboto max-w-7xl flex-col">
            <p className="text-left text-base text-white text-opacity-50">
              By calling the number on your screen or clicking the "View Quotes"
              or "See My GreatQuotes" button on this page, you agree that you
              are expressly consenting via this chat / webform a Licensed
              Insurance Agent of RateQuote or RateQuote Marketing Partner to
              contact you regarding insurance products which may include
              Medicare Advantage, Medicare Supplement, and Medicare Part D plans
              and other health-related services, by telephone using an Automated
              Dialing System call, text, or email or an artificial or
              prerecorded voice or via text/SMS message, even if the telephone
              number is assigned to a landline, a telephone service or other
              service for which the called party is charged. This is a
              solicitation for insurance. RateQuote or RateQuote Marketing
              Partners may also contact you via email using any email address
              you provided. This authorization overrides any previous
              registration on a federal, state or corporate Do Not Call Registry
              or any internal RateQuote or RateQuote Marketing Partner privacy
              or solicitation preference you have previously expressed. You are
              not required to provide this authorization as a condition of
              purchasing goods or services from RateQuote or RateQuote Marketing
              Partners. I understand this request has been initiated by me and
              is an unscheduled contact request. I further understand that this
              request, initiated by me, is my affirmative consent to be
              contacted which is in compliance with all federal and state
              telemarketing and Do-Not-Call laws. Participating sales agencies
              represent Medicare Advantage [HMO, PPO and PFFS] organizations and
              stand-alone PDP prescription drug plans that are contracted with
              Medicare. Enrollment depends on the plan’s contract
              renewal.RateQuote.com is powered by RateQuote.com LLC, a
              non-government entity. Not affiliated with or endorsed by any
              government agency. Callers will be directed to a licensed
              insurance agent. We provide a service that connects you to
              licensed insurance agencies and/or licensed insurance agents who
              will provide you the number of organizations and number of plans
              they offer in your area. Enrollment In the described plan type may
              be limited to certain times of the year unless you qualify for a
              special enrollment period. If you are turning 65 and newly
              eligible for Medicare or on Medicare and moving or losing
              coverage, you may be eligible for a Medicare Plan. Medicare
              Advantage may include access to routine dental, vision, hearing,
              and/or prescription drug coverage. You may be eligible for a low
              monthly plan premium, and RateQuote can help you get the best plan
              for your needs. There may not be a plan in your area that has all
              of these benefits in one plan. We do not offer every plan
              available in your area. Currently we represent 10 organizations
              which offer 3 products in your area. Please contact Medicare.gov,
              1-800-MEDICARE, or your local State Health Insurance Program
              (SHIP) to get information on all of your options. Our licensed
              sales agents will be able to review plan options in your service
              area. Enrollment in the described plan type may be limited to
              certain times of the year unless you qualify for a Special
              Enrollment Period. Participating sales agencies represent Medicare
              Advantage HMO, PPO, and PFFS organizations and stand-alone PDP
              prescription drug plans that are contracted with Medicare.
              Enrollment depends on the plan’s contract renewal. Not all plans
              offer all of these benefits. Benefits may vary by carrier and
              location. Limitations and exclusions may apply.
            </p>
            <div className="mt-4 text-center text-gray-600">
              <p className="flex flex-wrap items-center justify-start gap-1 text-base">
                <Link href="/terms-conditions/" className="hover:text-white">
                  Terms & Conditions
                </Link>
                |
                <Link href="/privacy-policy/" className="hover:text-white">
                  Privacy Policy
                </Link>
                |
                <Link
                  href="/do-not-sell-my-personal-information/"
                  className="hover:text-white"
                >
                  Do Not Sell My Information
                </Link>
                |
                <Link
                  href="/non-discrimination-statement/"
                  className="hover:text-white"
                >
                  Non-Discrimination Statement
                </Link>
                |
                <Link href="/about/" className="hover:text-white">
                  About Us
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <div className="md:full flex-col text-white text-opacity-50">
            <Disclaimer
              smid={smid}
              category={category}
              licenseNum={licenseNum}
            />
            <div className="text-gray-600">
              <p className="flex flex-wrap items-center justify-start gap-1 text-base">
                <Link href="/terms-conditions/" className="hover:text-white">
                  Terms & Conditions
                </Link>
                |
                <Link href="/privacy-policy/" className="hover:text-white">
                  Privacy Policy
                </Link>
                |
                <Link
                  href="/do-not-sell-my-personal-information/"
                  className="hover:text-white"
                >
                  Do Not Sell My Information
                </Link>
                |
                <Link
                  href="/non-discrimination-statement/"
                  className="hover:text-white"
                >
                  Non-Discrimination Statement
                </Link>
                |
                <Link href="/about/" className="hover:text-white">
                  About Us
                </Link>
              </p>
            </div>
          </div>
        )}
      </nav>
    </footer>
  )
}
