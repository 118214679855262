import React from 'react'
import { Sanity } from '../../../../types/sanity'

const Table = ({ value }: { value: { table: Sanity.addTable } }) => {
  const table = value?.table

  const renderTable = table
    ? table.rows.map((row: any, i: number) => {
        return React.createElement(
          'tr',
          { key: i },
          row.cells.map((cell: any, i: number) => {
            return (
              <td key={i} className="border px-4 py-2">
                {cell}
              </td>
            )
          }),
        )
      })
    : null

  return (
    <div className="overflow-auto py-8 pb-16">
      <table className="table-auto">
        <tbody>{renderTable}</tbody>
      </table>
    </div>
  )
}

export default Table
