import { contextLocalstoragePrefix } from '../../constants'
import { ls } from '../local-storage'

const setContextObjToLocalstorage = (obj: any, previousKey = '') => {
  if (!obj) return
  Object.entries(obj).forEach(([key, value]) => {
    if (key !== 'setState') {
      if (typeof obj[key] === 'object' && obj !== null) {
        setContextObjToLocalstorage(obj[key], previousKey + key + '-')
      } else {
        ls.setItem(contextLocalstoragePrefix + previousKey + key, value)
      }
    }
  })
}

export default setContextObjToLocalstorage
