import * as Sentry from '@sentry/nextjs'
import { RateQuote, Segment } from '../../../types'
import { VERTICALS } from '../../constants'
import isOrganicPageView from './is-organic-page-view'

export const categoryHasForm = (category: any): boolean => {
  const forms = VERTICALS.find((v) => v.category === category)?.forms
  return !!forms && forms.filter(getLiveForms).length > 0
}

// returns only live forms when on production.
// otherwise, returns all forms, live or not (so they can be accessed for development)
const getLiveForms = (form: { isLive: boolean; slug: string }): boolean => {
  return process.env.DEPLOYMENT_TARGET === 'production' ? form.isLive : true
}

export const flattenCategories = () =>
  VERTICALS.reduce(
    (acc: string[], v) => (v.category ? [...acc, v.category] : acc),
    [],
  )

export const flattenFormPaths = () =>
  VERTICALS.reduce(
    (acc: string[], v) =>
      v.forms && v.forms.filter(getLiveForms).length > 0
        ? [...acc, ...v.forms.filter(getLiveForms).map((form) => form.slug)]
        : acc,
    [],
  )

export const formIsValid = (form: string): form is RateQuote.Form => {
  // @TODO delete this workaround after u65 long form go-live
  if (form === 'health-insurance-quote') return true

  return VERTICALS.some(
    (v) =>
      v.forms &&
      v.forms.filter(getLiveForms).some((f) => f.slug === form) &&
      v.forms.length > 0,
  )
}

export const getCategoryFromForm = (form: string): Segment.Category => {
  // @TODO delete this workaround after u65 long form go-live
  if (form === 'health-insurance-quote') return 'u65'

  const category = VERTICALS.find(
    (v) => v.forms && v.forms.filter(getLiveForms).some((f) => f.slug === form),
  )?.category
  if (category) {
    return category as Segment.Category
  } else {
    Sentry.captureException(`Could not retrieve category from form: ${form}`)
    return ''
  }
}

export const getDefaultAdvertorialFromCategory = (
  category: Segment.Category | undefined,
): { pathname: string } | null => {
  if (!category) return null
  const advertorial = VERTICALS.find(
    (v) => v.category === category,
  )?.defaultAdvertorial
  if (advertorial) {
    return { pathname: `/${advertorial}` }
  } else return null
}

export const getFormFromCategory = (
  category: Segment.Category | undefined,
  forceLive?: boolean,
): RateQuote.Form | null => {
  if (!category) return null

  const formsUnfiltered = VERTICALS.find((v) => v.category === category)?.forms
  const forms = formsUnfiltered?.filter(
    (form: { isLive: boolean; slug: string }) =>
      forceLive ? true : getLiveForms(form),
  )
  if (forms && forms.length > 0) {
    return forms[0].slug as RateQuote.Form
  } else return null
}

export const getLicenseNumFromCategory = (
  category: Segment.Category,
): { category: string; licenseNum?: string; smid?: string } => {
  const vertical = VERTICALS.find((v) => v.category === category)
  const licenseNum = vertical?.licenseNum
  const smid = vertical?.smid
  if (licenseNum || smid) {
    return { category, licenseNum, smid }
  }
  return { category }
}

export const getRingbaNumberPoolIdFromCategory = (
  category: Segment.Category,
): string => {
  if (
    typeof window != undefined &&
    window.location.pathname.includes('/landing/u65/')
  ) {
    return 'CA7f53e167253a4d3d8d6c2a19b346676e '
  }
  if (
    typeof window != undefined &&
    window.location.pathname.includes('/landing/medicare/')
  ) {
    return 'CAc6d5da207197409691a33cfe65e06c12 '
  }
  if (category === '' || !category) category = 'u65'

  const vertical = VERTICALS.find((v) => v.category === category)
  const numberPoolId =
    isOrganicPageView() && vertical?.organicNumberPoolId
      ? vertical?.organicNumberPoolId
      : vertical?.numberPoolId

  return numberPoolId ? numberPoolId : ''
}

export const getLabelFromCategory = (category: Segment.Category): string => {
  const label = VERTICALS.find((v) => v.category === category)?.label
  if (label) {
    return label
  } else {
    Sentry.captureException(
      `Could not retrieve label from category: ${category}`,
    )
    return ''
  }
}
export const getSEOFromCategory = (
  category: Segment.Category = 'u65',
): string => {
  const SEO = VERTICALS.find((v) => v.category === category)?.SEO
  if (SEO) {
    return SEO
  } else {
    Sentry.captureException(`Could not retrieve SEO from category: ${category}`)
    return ''
  }
}

export const getLabelFromForm = (form: string): string => {
  const label = VERTICALS.find(
    (v) => v.forms && v.forms.filter(getLiveForms).some((f) => f.slug == form),
  )?.label
  if (label) {
    return label
  } else {
    Sentry.captureException(`Could not retrieve label from form: ${form}`)
    return ''
  }
}

export const verifyCategory = (
  category: string | undefined,
): Segment.Category | undefined => {
  if (isValidCategory(category)) return category
  if (category && !isValidCategory(category)) {
    Sentry.captureException(`Invalid category: ${category}`)
    return undefined
  }
  return undefined
}

const isValidCategory = (val: string | undefined): val is Segment.Category => {
  return flattenCategories().some((category) => category === val)
}
