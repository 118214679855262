import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { Button, FeatureSection } from '../../'
import { getCtaDestination } from '@/utils'
import { Context } from '../../../context'
import { RateQuote } from '../../../../types'

export const BottomCTA = () => {
  const router = useRouter()
  const ctx = useContext<RateQuote.AppState>(Context)
  const [ctaDestination, setCtaDestination] = React.useState({
    pathname: '/get-quote',
  })

  React.useEffect(() => {
    const destination = getCtaDestination(ctx.user?.category)
    setCtaDestination(destination)
    router.prefetch(destination.pathname)
  }, [ctx.user?.category])

  return (
    <div className="relative">
      <div className="c-text-wrapper max-w-8xl z-10 w-full rounded-lg bg-white p-5 pt-12">
        <FeatureSection
          CTA={
            <Button
              classes="max-w-32 mx-auto text-base xxs:text-lg px-3 xxs:px-4 py-2.5"
              onClick={() => router.push(ctaDestination)}
            >
              Get Started
            </Button>
          }
          align="center"
          title="Start comparing monthly health insurance premiums and deductibles today, and you could begin saving money right away."
          titleClasses="xxs:mb-8 md:mb-20 xxxl:mb-24 px-14 xs:px-0"
          emphasisIndex={[13, 14]}
        />
      </div>

      <style jsx>{`
        .c-text-wrapper {
          top: calc(26vw);
        }
        @media (min-width: 375px) {
          .c-text-wrapper {
            top: calc(35vw);
          }
        }
        @media (min-width: 480px) {
          .c-text-wrapper {
            top: calc(24vw);
          }
        }
        @media (min-width: 800px) {
          .c-text-wrapper {
            top: calc(21vw);
          }
        }
        @media (min-width: 1024px) {
          .c-text-wrapper {
            top: calc(18vw);
          }
        }
        @media (min-width: 1152px) {
          .c-text-wrapper {
            top: calc(22vw);
          }
        }
        @media (min-width: 1280px) {
          .c-text-wrapper {
            top: calc(18vw);
          }
        }
        @media (min-width: 1440px) {
          .c-text-wrapper {
            top: 288px;
          }
        }
      `}</style>
    </div>
  )
}
