export default function getSEOInformation(category: string, form: string) {
  if (form === 'get-quote' && category === 'u65') {
    return {
      description:
        'Secure your future with comprehensive Health Insurance Quotes for those Under 65. Our easy-to-navigate form delivers personalized quotes to match your health needs. Discover your options today.',
      title: 'RateQuote Get-Quote - U65',
    }
  }

  if (category === 'u65') {
    return {
      description:
        'Explore Health Insurance options for individuals under 65. Start with our simple form to get a tailored quote that fits your lifestyle and budget.',
      title: 'RateQuote Get Quotes - U65',
    }
  }

  if (category === 'health-insurance') {
    return {
      description:
        'Get your Medicare Quote effortlessly. Fill out our straightforward form and receive a customized Medicare plan quote that best suits your healthcare requirements.',
      title: 'RateQuote Get Quotes - Medicare',
    }
  }

  return {
    description:
      'Find the best health insurance quotes tailored to your needs. Whether you’re under 65 or looking for Medicare, our form guides you to personalized insurance solutions.',
    title: 'RateQuote - Get Quotes',
  }
}
