import * as Sentry from '@sentry/nextjs'
import { clearRingbaPoolInstance } from '../../utils'
import { addSegmentIdToRingba } from './set-ringba-tags'

/**
 * NOTE: function was previously called `addRingbaScript`
 */
function loadRingbaScript(numberPoolId: string): void {
  try {
    if (
      (window && window.location.href.includes('landing/g-u65/')) ||
      window.location.href.includes('landing/g-medicare/')
    ) {
      return
    }
    clearRingbaPoolInstance() // clear any existing Ringba scripts

    const script = document.createElement('script')
    script.id = 'ringba_script'
    script.src = `//b-js.ringba.com/${numberPoolId}`
    script.onload = () => addSegmentIdToRingba()
    document.body.append(script)
  } catch (e) {
    Sentry.captureException('Error loading ringba script', {
      extra: { numberPoolId, error: JSON.stringify(e) },
    })
  }
}

export { loadRingbaScript }
