import React from 'react'

import { Button } from '../../../'
import {
  parseChildren,
  parseSpouse,
} from '../../../../clients/media-alpha/parse-lead-data'
import { LeadData } from '../../../../hooks'

interface ViewProps {
  dependents: {
    child: string | string[] | LeadData.Dependent[] | null | undefined
    spouse: string | string[] | LeadData.Dependent | null | undefined
  }
  dependentType: string | undefined
  handleDelete: (key: string, idx?: number) => void
  handleEdit: (value: any, key: string, idx?: number) => void
  setShowEditView: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditDependentsPanel = (props: ViewProps) => {
  const {
    dependents,
    dependentType,
    handleDelete,
    handleEdit,
    setShowEditView,
  } = props

  const [showBtnLoader, setShowBtnLoader] = React.useState<any>({})

  const child = parseChildren({
    dependents: dependents.child as LeadData.Dependent[],
  })
  const spouse = parseSpouse({
    spouse: dependents.spouse as LeadData.Dependent,
  })

  return (
    <div className="my-2 text-left text-lg">
      {dependentType === 'spouse' && (
        <div className="xs:grid-cols-2 mb-4 grid w-full">
          <label className="text-blue-navy my-auto pl-1 pb-2 sm:pl-0 sm:pb-0">
            {spouse?.name}
          </label>
          <div className="grid grid-cols-2 gap-x-2">
            <Button
              classes="c-select-grid-item-base bg-white-primary border-blue-navy border-opacity-10"
              hideButtonText={showBtnLoader['spouse-edit-btn']}
              id="spouse-edit-btn"
              loader={showBtnLoader['spouse-edit-btn']}
              loaderVariant="dark"
              onClick={(val) => {
                handleEdit(val, dependentType)
                setShowBtnLoader({ ...showBtnLoader, 'spouse-edit-btn': true })
              }}
            >
              Edit
            </Button>
            <Button
              classes="c-select-grid-item-base bg-white-primary border-blue-navy border-opacity-10 hover:bg-red-error hover:bg-opacity-20 hover:border-red-error hover:border-opacity-50"
              hideButtonText={showBtnLoader['spouse-remove-btn']}
              id="spouse-remove-btn"
              loader={showBtnLoader['spouse-remove-btn']}
              loaderVariant="dark"
              onClick={() => {
                handleDelete(dependentType)
                setShowBtnLoader({
                  ...showBtnLoader,
                  'spouse-remove-btn': true,
                })
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      )}
      {dependentType === 'dependent' && (
        <>
          {child?.map((child, idx) => (
            <div className="xs:grid-cols-2 mb-4 grid w-full" key={idx}>
              <label className="text-blue-navy my-auto pl-1 pb-2 sm:pl-0 sm:pb-0">
                {child?.name}
              </label>
              <div className="grid grid-cols-2 gap-x-2">
                <Button
                  classes="c-select-grid-item-base bg-white-primary border-blue-navy border-opacity-10"
                  hideButtonText={showBtnLoader[`child-${idx}-edit-btn`]}
                  id={`child-${idx}-edit-btn`}
                  loader={showBtnLoader[`child-${idx}-edit-btn`]}
                  loaderVariant="dark"
                  onClick={(val) => {
                    handleEdit(val, dependentType, idx)
                    setShowBtnLoader({
                      ...showBtnLoader,
                      [`child-${idx}-edit-btn`]: true,
                    })
                  }}
                >
                  Edit
                </Button>
                <Button
                  classes="c-select-grid-item-base bg-white-primary border-blue-navy border-opacity-10 hover:bg-red-error hover:bg-opacity-20 hover:border-red-error hover:border-opacity-50"
                  hideButtonText={showBtnLoader[`child-${idx}-remove-btn`]}
                  id={`child-${idx}-remove-btn`}
                  loader={showBtnLoader[`child-${idx}-remove-btn`]}
                  loaderVariant="dark"
                  onClick={() => {
                    handleDelete(dependentType, idx)
                    setShowBtnLoader({
                      ...showBtnLoader,
                      [`child-${idx}-remove-btn`]: true,
                    })
                  }}
                >
                  Remove
                </Button>
              </div>
            </div>
          ))}
        </>
      )}
      <div className="flex w-full justify-center">
        <Button
          classes="max-w-fit px-10 mt-10"
          hideButtonText={showBtnLoader['cancel-btn']}
          id="cancel-btn"
          loader={showBtnLoader['cancel-btn']}
          onClick={() => {
            setShowBtnLoader({ ...showBtnLoader, 'cancel-btn': true })
            setShowEditView(false)
          }}
          role="primary"
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}
