import dynamic from 'next/dynamic'
import * as React from 'react'

interface FeatureSwitchProps {
  /**
   * Key to use when checking for the feature flag
   */
  featureKey: string
  /**
   * Component to render when active
   */
  renderActive: React.ReactNode
  /**
   * Component to render when not active
   * @default null
   */
  renderInactive?: React.ReactNode
}

/**
 * A feature switch that conditionally renders elements based on saved
 * thresholds.
 *
 * It will look for a value in `window.featureFlags[key]` and if that value
 * exists and is greater than or equal to a randomly generated value between 0
 * and 1 than the children provided for `renderActive` will be rendered.
 * Otherwise the `renderInactive` will be rendered.
 *
 * To use this component you must provide a `featureFlag` key that is used to
 * control the feature. You can do so in pages/_document.tsx in the <script> that
 * sets the window.featureFlags object. The value of the key should be stored as
 * an environment variable so that it can be controlled from the Vercel dashboard.
 */
const FeatureSwitchSSR = (props: FeatureSwitchProps) => {
  const active =
    featureThreshold(props.featureKey) > userThreshold(props.featureKey)
  return <>{active ? props.renderActive : props.renderInactive}</>
}

function featureThreshold(key: string): number {
  try {
    // @ts-ignore
    return window.featureFlags[key] || 0
  } catch {
    return 0
  }
}

function parseCookie(key: string): string | undefined {
  try {
    // @ts-ignore
    return document.cookie.split(/; */).reduce((acc, c) => {
      const [k, ...v] = c.split('=')
      return { ...acc, [k]: v }
    }, {})[`ff_${key}`]
  } catch {
    return
  }
}

function setCookie(key: string, value: number) {
  try {
    document.cookie = `ff_${key}=${value.toFixed(2)}`
  } catch {
    /* don't need nothing from you */
  }
}

function userThreshold(key: string): number {
  const valueFromCookie = parseCookie(key)
  if (valueFromCookie != null) {
    return Number(valueFromCookie)
  } else {
    const randomValue = Math.random()
    setCookie(key, randomValue)
    return randomValue
  }
}

// dynamic exporting solved the hydration bug
const FeatureSwitch = dynamic(Promise.resolve(FeatureSwitchSSR), {
  ssr: false,
})

export { FeatureSwitch, FeatureSwitchSSR }
