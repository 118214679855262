import React from 'react'
import { Button } from '../../atoms'
export interface DialogModalProps {
  onConfirm?: () => void
  onCancel?: () => void
  title?: string
  text?: string
  show?: boolean
}
export function DialogModal(props: DialogModalProps) {
  const { onConfirm, onCancel, show, title, text } = props
  if (!show) return null

  return (
    <div
      className="z-100 fixed inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0 md:items-end">
        <div
          className="bg-gray-primary fixed inset-0 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="relative inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:align-middle">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                {title && (
                  <h3
                    className="font-circular text-2xl font-medium leading-6 text-gray-900 md:text-3xl"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                )}

                <div className="mt-4">
                  {text && (
                    <p className="text-roboto text-gray-primary">{text}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {onConfirm && (
            <div className="justify-between px-4 py-3 sm:flex sm:flex-row-reverse">
              <Button
                classes="md:mx-4 px-0 justify-center"
                onClick={onConfirm}
                role="primary"
              >
                Confirm
              </Button>
              <Button
                classes="md:mx-4 px-0 justify-center"
                onClick={onCancel}
                role="transparent"
              >
                Close
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
