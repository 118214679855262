import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
export interface ErrorProps {
  message: string
}

export const ErrorMessage = (props: ErrorProps) => {
  const { message } = props
  return (
    <div className="text-red-error mx-2 flex items-center py-2">
      <FontAwesomeIcon
        className="pr-1"
        icon={faExclamationCircle}
        style={{ width: '20px', height: '20px' }}
      />
      <p className="text-left text-sm leading-snug">{message}</p>
    </div>
  )
}
