import { toLower } from 'lodash/fp'

interface Placement {
  Source: string
  Campaign: string
}

interface PlacementMap {
  [key: string]: string
}

export const placements = [
  { Campaign: 'connection_auto_parts_mobile', Source: 'bing' },
  { Campaign: 'connection_auto_parts_desktop', Source: 'bing' },
  { Campaign: 'connection_auto_platforms_mobile', Source: 'bing' },
  { Campaign: 'connection_tire_shops_mobile', Source: 'bing' },
  { Campaign: 'connection_car_registration_mobile', Source: 'bing' },
  { Campaign: 'connection_car_wash_mobile', Source: 'bing' },
  { Campaign: 'connection_tire_shops_desktop', Source: 'bing' },
  { Campaign: 'connection_car_wash_desktop', Source: 'bing' },
  { Campaign: 'connection_car_registration_desktop', Source: 'bing' },
  { Campaign: 'connection_auto_platforms_desktop', Source: 'bing' },
  { Campaign: 'connection_auto_platforms_desktop', Source: 'adwords' },
  { Campaign: 'connection_car_registration_desktop', Source: 'adwords' },
  { Campaign: 'connection_car_wash_desktop', Source: 'adwords' },
  { Campaign: 'connection_auto_parts_mobile', Source: 'adwords' },
  { Campaign: 'connection_auto_platforms_mobile', Source: 'adwords' },
  { Campaign: 'connection_auto_parts_desktop', Source: 'adwords' },
  { Campaign: 'connection_car_registration_mobile', Source: 'adwords' },
  { Campaign: 'connection_tire_shops_desktop', Source: 'adwords' },
  { Campaign: 'connection_tire_shops_mobile', Source: 'adwords' },
  { Campaign: 'connection_car_wash_mobile', Source: 'adwords' },
]

export const upperFunnelPlacements = placements.reduce(
  (acc: PlacementMap, val: Placement) => {
    const key = `${toLower(val.Source)}_${toLower(val.Campaign)}`
    acc[key] = 'true'
    return acc
  },
  {},
)
