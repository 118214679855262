import * as React from 'react'
import { Wrapper } from './Wrapper'
import { InputBox } from '../../../..'
import type { QuestionProps } from './types'

export const ZipCode: React.FC<QuestionProps> = (props) => {
  const [value, setValue] = React.useState<string | undefined>(undefined)
  const [isValid, setIsValid] = React.useState(true)
  const [isDirty, setIsDirty] = React.useState(false)

  React.useEffect(() => {
    const initialValue =
      props.leadDataGetter.zipcode ?? props.leadDataGetter.contactZip ?? ''

    if (validate(initialValue)) {
      setValue(initialValue)
      setIsValid(true)
      props.setIsValid(true)
    }
  }, [])

  const handleChange = (value: string) => {
    setValue(value)
    setIsDirty(true)

    const newValueIsValid = validate(value)
    if (newValueIsValid) {
      props.leadDataSetter({ key: 'zipcode', value })
    }

    setIsValid(newValueIsValid)
    props.setIsValid(newValueIsValid)
  }

  return (
    <Wrapper>
      <label className="sr-only" id="qf-zip--label">
        Zip code
      </label>
      <InputBox
        id="qf-zip"
        labelId="qf-zip--label"
        error={isDirty && !isValid}
        errorMsg="Please enter a valid zip code."
        name="qf-zip"
        inputMode="numeric"
        maxLength={5}
        onChange={handleChange}
        pattern="[0-9]*"
        placeholder="Zip Code"
        type="tel"
        value={value}
      />
    </Wrapper>
  )
}

function validate(value: string): boolean {
  return /(^\d{5}$)/.test(value)
}
