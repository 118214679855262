import * as React from 'react'
import qs from 'query-string'
import { snakeCase } from 'lodash/fp'
import { isJSON, ls } from '../../utils'
import { ALL_KEYS } from './keys'
import { LeadData } from './types'

export function useLeadData<K extends keyof LeadData.All>(
  k: K,
): [string | LeadData.All[K] | string[] | null, (v: LeadData.All[K]) => void] {
  return [getInitialValue(k), (v: LeadData.All[K]) => setValue(k, v)]
}

export function useAllLeadData(): Partial<LeadData.All> {
  return ALL_KEYS.reduce((acc: Partial<LeadData.All>, k) => {
    const found = getInitialValue(k)
    if (found != null) {
      // @ts-ignore
      acc[k] = found
    }
    return acc
  }, {})
}

/**
 * Provide a reducer pattern for interacting with lead data as an object.
 *
 * @example
 * const [leadData, updateLeadData] = useLeadDataReducer()
 * //...many minutes later...
 * <input
 *   onChange={(value) => updateLeadData('zipcode', value)}
 *   value={leadData['zipcode']}
 * />
 */
export type LeadDataReducer = ReturnType<typeof useLeadDataReducer>
export function useLeadDataReducer() {
  const initialState = useAllLeadData()

  const reducer = <K extends keyof LeadData.All>(
    state: Partial<LeadData.All>,
    action: {
      key: K
      value: LeadData.All[K]
    },
  ) => {
    state[action.key] = action.value
    setValue(action.key, action.value)

    return { ...state }
  }

  return React.useReducer(reducer, initialState)
}

function getInitialValue<K extends keyof LeadData.All>(
  k: K,
): string | LeadData.All[K] | string[] | null {
  const initialValue = getItemFromUrl(k) || getItemFromLocalStorage(k)
  return initialValue || null
}

function getItemFromLocalStorage<K extends keyof LeadData.All>(
  k: K,
): LeadData.All[K] | null {
  if (!windowExists()) return null
  return ls.getItem(k as string) as LeadData.All[K] | null
}

function getItemFromUrl<K extends keyof LeadData.All>(
  k: K,
): string[] | string | null | undefined {
  if (!windowExists()) return
  const found = qs.parse(location.search)[snakeCase(k)]
  return typeof found === 'string'
    ? isJSON(found)
      ? JSON.parse(found)
      : found
    : found
}

export function setValue<K extends keyof LeadData.All>(
  k: K,
  v: LeadData.All[K],
) {
  ls.setItem(k, v)
}

function windowExists(): boolean {
  return typeof window !== 'undefined'
}
