import * as Sentry from '@sentry/nextjs'
import prop from 'lodash/fp/prop'
import { formIsValid } from '../'

export function parsePath(query?: {
  [key: string]: string | string[] | undefined
}): string {
  try {
    if (!query) throw new Error()

    const form = prop('form', query)
    if (typeof form !== 'string') throw new Error()

    if (formIsValid(form)) {
      return form
    } else throw new Error()
  } catch {
    Sentry.captureException(`Invalid query: ${JSON.stringify(query)}`)
    return 'get-quote'
  }
}
