import React from 'react'
import { SelectGrid } from '../../'
import { buildYearGroups, handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

export function VehicleYearPanel(props: RateQuote.FormPanel) {
  const { showBtnLoader } = props
  const startYear = new Date().getFullYear() + 1
  const items = buildYearGroups(startYear, 80)

  return (
    <SelectGrid
      columns={'grid-cols-2 sm:grid-cols-3 md:grid-cols-5'}
      items={items}
      onChange={handleGridButtonSelect(props.input, props.handleInput)}
      loading={showBtnLoader}
      showMoreIndex={2}
      value={props.value || ''}
    />
  )
}
