export default (type?: string): boolean => {
  if (typeof window === 'undefined') return false
  const url = window.location.href
  let indicators = ['']
  if (type === 'google') {
    indicators = ['adwords']
  } else {
    indicators = [
      'gclid',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'kwmt',
      'product',
      'ad_group_id',
      'adwords',
    ]
  }

  return !indicators.some((indicator) => url.includes(indicator))
}
