import * as React from 'react'
import { Wrapper } from './Wrapper'
import { Dropdown, DropdownOption } from '../../../..'
import { getAutoInsuranceCompanies } from '../../../../../utils'

import type { QuestionProps } from './types'
import type { RateQuote } from '../../../../../../types'

const options = getAutoInsuranceCompanies?.map(({ label, value }) => ({
  label,
  value,
}))

export const AutoInsuranceProvider: React.FC<QuestionProps> = (props) => {
  const [value, setValue] = React.useState<DropdownOption | undefined>(
    undefined,
  )

  React.useEffect(() => {
    const initialValue = props.leadDataGetter['autoInsuranceProvider']
    const initialOption = options.find(
      (option) => option.value === initialValue,
    )

    setValue(initialOption)
    props.setIsValid((initialOption?.value as string) !== '')
  }, [])

  const handleChange = (value: DropdownOption) => {
    setValue(value as { label: string; value: RateQuote.AutoInsuranceProvider })

    if (value.value !== '') {
      props.leadDataSetter({
        key: 'autoInsuranceProvider',
        value: value.value as RateQuote.AutoInsuranceProvider,
      })
      props.leadDataSetter({
        key: 'autoInsuranceCurrent',
        value: '1',
      })

      props.setIsValid(true)
    } else {
      props.setIsValid(false)
    }
  }

  return (
    <Wrapper>
      <label className="sr-only" id="qf-insurance-provider--label">
        Current carrier
      </label>
      <Dropdown
        id="qf-insurance-provider"
        labelId="qf-insurance-provider--label"
        name="qf-insurance-provider"
        onChange={handleChange}
        placeholder="Current carrier"
        options={options}
        value={{ value: value?.label ?? '' }}
      />
    </Wrapper>
  )
}
