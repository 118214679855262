import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { Button, InputAction, InputBox } from '../../'
import { Sanity } from '../../../../types/sanity'
import { trackQuoteInput } from '@/clients/segment'
import { useLeadData } from '@/hooks'
import { getCtaDestination, validateZipcode } from '@/utils'
import { RateQuote } from '../../../../types'
import { Context } from '../../../context'

const STEP = 1

/** TODO
 * This is not an ideal solution, as it's too specific of an implementation to get
 * the full value out of using the CMS to control this component.
 * Eventually we need to re-work the Sanity schema for the `inputAction` according
 * to what is possible on the frontend.
 */
export const CustomInputAction = ({ value }: { value: Sanity.InputAction }) => {
  const router = useRouter()
  const ctx = useContext<RateQuote.AppState>(Context)

  const [leadZipcode, setLeadZipcode] = useLeadData<'zipcode'>('zipcode')
  const [zipcode, setZipcode] = React.useState(leadZipcode)

  const onChangeZipCodeInput = (val: string) => {
    if (!/\D/.test(val)) {
      setZipcode(val)
    }
  }

  const onSubmit = () => {
    if (validateZipcode(zipcode as string)) {
      setLeadZipcode(zipcode as string)
      trackQuoteInput({ step: STEP, name: 'zip', value: zipcode as string })
      router.push(getCtaDestination(ctx.user?.category, STEP, { zipcode }))
    } else {
      router.push(getCtaDestination(ctx.user?.category, 0))
    }
  }
  return (
    <div className="mx-auto max-w-xs">
      <InputAction
        buttonComponent={
          <Button id="get-started-btn" onClick={onSubmit}>
            {value.buttonText}
          </Button>
        }
        inputComponent={
          <InputBox
            key="input--zipcode"
            iconLeft="/static/icons/location-spot.svg"
            altIcon="Location pin point icon"
            id="get-started-input"
            inputMode="numeric"
            labelId="get-started-btn"
            maxLength={5}
            name="get-started-input"
            placeholder={value.inputPlaceholder}
            pattern="[0-9]*"
            type="tel"
            onChange={onChangeZipCodeInput}
            value={zipcode as string}
          />
        }
      />
    </div>
  )
}
