import React, { FC, useContext, useState } from 'react'
import SingletonRouter, { Router, useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Footer, NavBar, Disclaimer } from '../..'
import { FormLabel } from './Label'
import { ProgressCounter } from './ProgressCounter'
import { ProgressBar } from './ProgressBar'
import { useBrowserDimensions, useRefDimensions } from '@/hooks'
import {
  getCategoryFromForm,
  getLabelFromForm,
  getLicenseNumFromCategory,
  getPhoneNumber,
  handlePhoneOut,
  ls,
} from '@/utils'
import { DialogModal } from '../../molecules/DialogModal'
import { isUserLeavingForm } from '@/utils/is-user-leaving-form'
import { RateQuote } from '../../../../types'
import { Context } from '../../../context'
import TTY from '../../atoms/TTY'
import { isCallCenterOpen } from '@/utils/call-center'

export interface FormLayoutProps {
  /**
   * input component
   */
  children: React.ReactNode
  /**
   * Disables the "Next Step" button
   */
  disableNextStep?: boolean
  /**
   * Which form is the user on?
   * e.g. "auto-quote" or "get-quote"
   */
  form: string
  /** the form ref for Jornaya - only needed when input component is SelectGrid */
  formRef?: React.RefObject<HTMLFormElement>
  /**
   * Hides the "Next Step" button
   */
  hideNextStep?: boolean
  /**
   * Hides the "Previous Step" button
   */
  hidePrevStep?: boolean
  /** the input ref for Jornaya - only needed when input component is SelectGrid */
  inputRef?: React.RefObject<HTMLInputElement>
  /** the name of the input for Jornaya - only needed when input component is SelectGrid */
  name?: string
  onSubmit?: () => void
  /**
   * Inside this object, pass a numeric value to step which shows which page you are on and a numeric value for denominator to give the total number of form steps possible.
   */
  progress: {
    step: number
    denominator: number
  }
  /**
   * Form's questionLabel
   */
  questionLabel?: string
  /**
   * Which section of the form is the user on?
   * e.g. "Main Driver" or "Vehicle"
   */
  section?: string
  showBtnLoader?: boolean
  showDisclaimer?: boolean
}

export const FormLayout: FC<FormLayoutProps> = (props: FormLayoutProps) => {
  const {
    children,
    disableNextStep,
    formRef,
    hideNextStep = false,
    hidePrevStep = false,
    inputRef,
    name,
    onSubmit,
    questionLabel,
    progress,
    section,
    showBtnLoader,
    showDisclaimer,
  } = props
  const [changeRouteArgs, setChangeRouteArgs] = useState<any>(null)
  const ctx = useContext<RateQuote.AppState>(Context)

  /**
   * Getting the form slug from router rather than using props, since
   * we need it immediately for getting the category and subsequently
   * the Ringba phone number:
   */
  const router = useRouter()
  const { form } = router.query

  const category = getCategoryFromForm((form as string) || props.form)

  React.useEffect(() => {
    if (category && ctx.user?.category !== category) {
      ctx.setState({
        ...ctx,
        user: {
          ...ctx.user,
          category,
        },
      })
    }
  }, [category, ctx.user?.category])

  React.useEffect(() => {
    window.onpopstate = function () {
      const path = window.location.href.split('/')[3]

      if (isUserLeavingForm(path)) {
        setChangeRouteArgs(window.location.href)
        history.go(1)
      }
    }

    window.onbeforeunload = function () {
      const path = window.location.href.split('/')[3]
      // if (
      //   pathOr(
      //     '',
      //     ['event', 'target', 'activeElement', 'href'],
      //     window,
      //   ).startsWith('tel:')
      // ) {
      if (isUserLeavingForm(path)) {
        return
      }
      return 'Changes you have made may not be saved'
    }

    // @ts-ignore
    SingletonRouter.router.change = (...args) => {
      const [, path] = args

      if (isUserLeavingForm(path)) {
        setChangeRouteArgs(args)
      } else {
        //@ts-ignore
        return Router.prototype.change.apply(SingletonRouter.router, args)
      }
    }
    window.scrollTo(0, 0)

    return () => {
      window.onpopstate = () => null
      // @ts-ignore
      delete SingletonRouter.router.change
    }
  }, [])

  /**********************************************************************************
  /* Ensure height of background triangle image element is appropriate for the page
  /**/
  const [height, setHeight] = React.useState(0)

  const target = React.useRef<HTMLDivElement>(null)
  const dimensions = useRefDimensions(target)
  const { browserHeight, browserWidth } = useBrowserDimensions()

  React.useEffect(() => {
    const pageContentEl = document.getElementById('page-content')

    const offset = browserWidth >= 1440 ? -36 : browserWidth >= 1152 ? -8 : 0

    if (pageContentEl) {
      setHeight(pageContentEl.clientHeight + offset)
    }
  }, [browserHeight, browserWidth, dimensions])
  /********************************************************************************/

  /********************************************************************************/
  /* Autofocus for inputs
  /**/
  const containerRef: React.RefObject<HTMLDivElement> | undefined =
    React.useRef(null)

  React.useEffect(() => {
    containerRef.current
      ?.querySelector<HTMLDivElement>('div:not(.jornaya-container)')
      ?.querySelector<HTMLInputElement>(
        'input:first-of-type:not([id*="checkbox"])',
      )
      ?.focus()
  }, [])
  /********************************************************************************/

  const { step, denominator } = progress
  const phoneNumber = getPhoneNumber(category)
  const handleGoBack = () => {
    try {
      ls.setItem('previousStep', step)
      router.back()
    } catch (err) {
      Sentry.captureException(err)
      router.push(`/${form}`)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onSubmit && onSubmit()
  }

  const getButtonText = () => {
    if (step === 1)
      return (
        <span className="flex items-center">
          {category === 'health-insurance'
            ? 'Get Started '
            : 'Get My GreatQuotes'}

          <FontAwesomeIcon className="ml-4 h-4 w-4" icon={faArrowRight} />
        </span>
      )
    if (step === denominator)
      return (
        <span className="flex items-center">
          See My GreatQuote
          <FontAwesomeIcon className="ml-4 h-4 w-4" icon={faArrowRight} />
        </span>
      )
    return 'Next Step'
  }

  return (
    // `overflow-clip` on this outer div prevents the background image from creating excess below the footer,
    // while still allowing the NavBar to be sticky on scroll.
    <div
      className="relative flex min-h-screen flex-col justify-between overflow-clip"
      tabIndex={1}
    >
      <div
        id="bg-triangle"
        className="c-bg-triangle -z-10 xl:pt-2"
        style={{ height: `${height}px` }}
      />
      <div
        id="page-content"
        ref={target}
        className="flex min-h-screen flex-col justify-between"
      >
        <DialogModal
          title="Leave Site?"
          text="Changes you have made may not be saved."
          onConfirm={() => {
            if (changeRouteArgs !== '') {
              // @ts-ignore
              Router.prototype.change.apply(
                SingletonRouter.router,
                changeRouteArgs,
              )
            }

            setChangeRouteArgs(null)
          }}
          onCancel={() => setChangeRouteArgs(null)}
          show={!!changeRouteArgs}
        />
        <NavBar category={category} onForm phoneNumber={phoneNumber} />
        <div className="mx-auto flex h-full w-full max-w-7xl grow flex-col justify-start px-3 md:px-11">
          <ProgressBar progress={progress} />
          <form
            className="mx-auto mt-2 mb-5 flex h-full min-h-full w-full min-w-full max-w-6xl rounded-sm sm:mb-0"
            noValidate
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <div className="c-bg-blur md:pb-18 flex min-w-full flex-col justify-between rounded-md border-4 border-white px-5 pt-9 pb-6 md:rounded-xl md:px-12 md:pt-10">
              <div className="min-w-full text-center md:flex md:flex-col md:justify-between">
                <div className="flex w-full flex-col md:flex-row md:justify-between">
                  <p className="text-blue-navy mb-2.5 text-lg font-light md:mb-0">
                    {getLabelFromForm(props.form)}
                  </p>
                  {section && (
                    <p className="text-blue-primary md:mb-3-1/2 mb-2.5 flex justify-center text-lg font-medium md:mt-24 md:hidden">
                      {section}
                    </p>
                  )}
                  {questionLabel && (
                    <FormLabel className={`sm:leading-300 mb-2.5 md:hidden`}>
                      {questionLabel}
                    </FormLabel>
                  )}

                  <ProgressCounter progress={{ step, denominator }} />
                </div>
                <p
                  className={`${
                    section ? 'hidden md:visible' : 'hidden md:invisible'
                  } text-blue-primary md:mb-3-1/2 mb-2.5 flex justify-center text-lg font-medium md:mt-6 md:block`}
                >
                  {section}
                </p>
              </div>
              <div className="grow">
                {questionLabel && (
                  <FormLabel className={`leading-302 mb-6 hidden md:block`}>
                    {questionLabel}
                  </FormLabel>
                )}

                <div
                  className="mx-auto mt-10 mb-9 w-full max-w-md text-center md:mt-0"
                  ref={containerRef}
                >
                  {children}
                  {/*hidden form that is used to track data for jornaya*/}
                  <div className="jornaya-container">
                    <input
                      tabIndex={-1}
                      ref={inputRef}
                      id={name}
                      name={name}
                      readOnly={true}
                      autoComplete="off"
                      type="text"
                      style={{
                        opacity: 0,
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        height: 1,
                        width: 1,
                        zIndex: -50,
                      }}
                    />
                    <input
                      readOnly={true}
                      autoComplete="off"
                      id="leadid_token"
                      name="universal_leadid"
                      type="hidden"
                      value=""
                    />
                  </div>
                </div>
              </div>
              <div
                className={`m-auto flex w-full min-w-min max-w-sm ${
                  step !== denominator ? 'flex-row' : 'flex-col'
                } justify-between`}
              >
                {!hideNextStep && (
                  <div
                    className={` flex ${
                      progress.step > 1 && step !== denominator
                        ? 'order-2 w-1/2 justify-end'
                        : 'order-1 w-full justify-center'
                    } `}
                  >
                    <Button
                      classes="max-w-fit px-5"
                      disabled={disableNextStep}
                      hideButtonText={showBtnLoader}
                      loader={showBtnLoader}
                      role="primary"
                      type="submit"
                    >
                      {getButtonText()}
                    </Button>
                  </div>
                )}
                {progress.step > 1 && (
                  <div
                    className={`flex w-full ${
                      step === denominator
                        ? 'order-2 mt-2 justify-center'
                        : hideNextStep
                        ? ''
                        : 'order-1 w-1/2 justify-start'
                    }`}
                  >
                    {!showDisclaimer && !hidePrevStep && (
                      <Button
                        classes={`${
                          hideNextStep
                            ? ''
                            : 'mx-0 px-0 max-w-fit justify-center'
                        }`}
                        onClick={handleGoBack}
                        role="transparent"
                      >
                        Previous Step
                      </Button>
                    )}
                  </div>
                )}
              </div>
              {showDisclaimer && (
                <Disclaimer phoneNum={phoneNumber} category={category} />
              )}
            </div>
          </form>
          <div className="md:px-15 mx-auto ">
            <div className="flex max-w-5xl flex-col items-center rounded-sm">
              <div className="mt-6">
                <div className="text-roboto text-gray-primary text-center">
                  Your data is encrypted, stored securely and never sold without
                  your consent.
                </div>
                {category === 'health-insurance' && step === 1 && (
                  <div className="text-roboto text-gray-primary mt-2 text-center">
                    Plans available from Humana and UnitedHealthcare®. Speak to
                    a licensed agent
                    <br /> to review plan options in your service area.
                  </div>
                )}
              </div>
              {isCallCenterOpen() ? (
                <div className="flex flex-col items-center ">
                  <div className="text-blue-navy pt-10 pb-4 text-3xl">
                    Need Help?
                  </div>
                  <div className="text-roboto text-gray-primary pb-5 text-center">
                    Speak to a licensed insurance agent to get help quickly.
                  </div>
                  <div className="flex items-center pb-7">
                    <div className="flex flex-col xl:px-8 ">
                      <a
                        href={`tel:+${phoneNumber}`}
                        className="flex text-black no-underline sm:pr-2"
                        onClick={() =>
                          handlePhoneOut({
                            phoneNumber,
                            category,
                          })
                        }
                      >
                        <img
                          alt="phone"
                          className="mr-3 max-h-6 w-8 pl-1 lg:mt-1 lg:max-h-5"
                          src="/static/icons/phone-cta-blue.svg"
                        />
                        <div className=" text-blue-primary xl:text-lg ">
                          {phoneNumber}
                          <TTY category={category} />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-6"></div>
              )}
            </div>
          </div>
        </div>
        <Footer {...getLicenseNumFromCategory(category)} />
      </div>
    </div>
  )
}
