import React from 'react'
import { SelectGridItem } from './Item'
import { Button } from '../..'
import { SearchMatch } from './SearchMatch'
import { DropdownSuggestionOption } from '../../molecules/DropdownSuggestion'
import useNavigateList from './useNavigation'
export interface Item {
  icon?: React.ReactNode
  label: string
  value: string
}

export interface SelectGridProps {
  /**
   * Specify using Tailwind classes how many columns to render at each breakpoint.
   * For example, to render 1 column on mobile, 2 columns on tablet, and 3 columns on desktop,
   * pass in `columns="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"`.
   * @default "grid-cols-1"
   */
  columns?: string
  /**
   * an optional boolean to enable the loader animation on Item click
   */
  enableLoader?: boolean
  /**
   * A 2-dimensional array where the outtermost array represents groupings
   * of options between which dividers are rendered. If only one grouping exists
   * then no dividers are rendered.
   */
  items: Item[][]
  /**
   * Classes that will be merged with or override existing styles on the Item component.
   */
  itemClasses?: string
  /**
   * Classes to be provided to the Item container to be merged with or override existing styles.
   */
  itemContainerClasses?: string
  loading?: boolean
  /**
   * Function called when an item is selected. It will be passed whatever value
   * is defined on the item.
   */
  onChange: (value: string) => void
  /**
   * index at which to render the "Show More+" button, if applicable
   */
  showMoreIndex?: number
  /**
   * Show Input Box that will trigger suggestions for the response based on a dataset
   */
  showSearchMatch?: boolean
  /**
   * @default ""
   */
  value: string
}

export function SelectGrid(props: SelectGridProps) {
  const {
    columns = 'grid-cols-1',
    items,
    itemClasses = '',
    itemContainerClasses = '',
    loading = false,
    onChange,
    showMoreIndex,
    showSearchMatch = false,
  } = props

  const [selectedVal, setSelectedVal] = React.useState<string | undefined>()
  const [showMore, setShowMore] = React.useState(false)
  const [searchMatchItems, setSearchMatchItems] =
    React.useState<DropdownSuggestionOption[]>()

  const getNumberRows = (columns: string): number => {
    if (columns.length > 0) {
      const siplitedColumns = columns.split('grid-cols-')
      return parseInt(siplitedColumns[siplitedColumns.length - 1])
    }
    return 3
  }

  const { activeIndex, useKeyboard, setUseKeyboard, itemProps } =
    useNavigateList({
      list: [...items.flat()],
      onSelect: (item: any) => {
        setSelectedVal(item.value)
        onChange(item.value)
      },
      loading,
      setShowMore,
      setSelectedVal,
      columns: getNumberRows(columns),
    })

  const handleClick = (items: Item[] | undefined, value?: string) => {
    if (value && value.length !== 0) {
      setSelectedVal(value)
      onChange(value)
    }
    setSearchMatchItems(items)
  }
  const value: string = selectedVal || props.value
  const GridGroup = (gridGroupProps: {
    divider: boolean
    items: Item[]
    activeIndex: number
    indexPadding?: number
  }) => {
    const { items, divider, activeIndex, indexPadding = 0 } = gridGroupProps
    const GridButton = (gridBtnProps: { item: Item; index: number }) => {
      const { item, index } = gridBtnProps
      return (
        <SelectGridItem
          classes={itemClasses}
          icon={item.icon}
          label={item.label}
          index={index}
          {...itemProps}
          loading={
            loading ||
            (selectedVal != null &&
              selectedVal === item.value &&
              props.enableLoader)
          }
          onChange={() => {
            setUseKeyboard(false)
            setSelectedVal(item.value)
            onChange(item.value)
          }}
          selected={useKeyboard ? activeIndex === index : value === item.value}
        />
      )
    }
    return (
      <>
        {divider && <Divider />}
        <div className={`gap-y-2-1/2 grid gap-x-2 ${columns}`}>
          {items.map((item, index) => (
            <div
              className={`c-select-grid-item-container-base ${itemContainerClasses}`}
              key={`${item.value}${
                props.value === item.value ? '--selected' : ''
              }`}
              role="presentation"
            >
              <GridButton item={item} index={index + indexPadding} />
            </div>
          ))}
        </div>
      </>
    )
  }

  const getItemsHead = () => {
    if (searchMatchItems) {
      return hasShowMoreIndex
        ? [searchMatchItems].slice(0, showMoreIndex)
        : [searchMatchItems]
    }
    return hasShowMoreIndex ? items.slice(0, showMoreIndex) : items
  }

  const getItemsTail = () => {
    if (searchMatchItems) {
      return showMoreIndex
        ? [searchMatchItems].slice(showMoreIndex)
        : [searchMatchItems]
    }
    return hasShowMoreIndex ? items.slice(showMoreIndex) : []
  }

  const hasShowMoreIndex =
    !!showMoreIndex && showMoreIndex > 0 && items.length > 1
  const itemsHead = getItemsHead()
  const itemsTail = getItemsTail()
  const renderShowMoreBtn = hasShowMoreIndex && !showMore
  const renderAllGrids = hasShowMoreIndex && showMore

  return (
    <div className="w-full" role="grid">
      {showSearchMatch && (
        <SearchMatch
          options={items.reduce(function (prev, curr) {
            return prev.concat(curr)
          })}
          autocompleteOnChange={() => undefined}
          onClick={handleClick}
        />
      )}

      {itemsHead.map((itemsGroup, index) => {
        const currentPosition = itemsHead.slice(0, index).flat().length
        return (
          <GridGroup
            indexPadding={currentPosition}
            items={itemsGroup}
            divider={index > 0}
            activeIndex={activeIndex}
            key={currentPosition}
          />
        )
      })}
      {renderShowMoreBtn && (
        <>
          <Divider />
          <Button
            classes="c-select-grid-item-base bg-white-primary border-blue-navy border-opacity-10 rounded-full"
            id="showMore_btn"
            onClick={() => {
              setTimeout(() => setShowMore(true), 50)
            }}
          >
            Show more +
          </Button>
        </>
      )}
      {renderAllGrids && (
        <>
          {itemsTail.map((item, index) => {
            const currentPosition =
              itemsHead.flat().length + itemsTail.slice(0, index).flat().length
            return (
              <GridGroup
                items={item}
                divider
                activeIndex={activeIndex}
                key={currentPosition}
                indexPadding={currentPosition}
              />
            )
          })}
        </>
      )}
    </div>
  )
}

const Divider = () => (
  <hr className="border-grayscale-dark my-4 w-full border-t border-opacity-10" />
)
