import React from 'react'
import { Img } from '@/components'
import { Sanity } from '../../../../types/sanity'

interface Props {
  isFeatured?: boolean
  quote: Sanity.Quote
}

export function Card(props: Props) {
  const { name, profile, quote: text } = props.quote

  return (
    <div
      className={`w-250px min-w-250px sm:w-81 sm:min-w-81 xxs:mr-6 relative mr-3 p-6 sm:mr-9 sm:p-8 `}
    >
      <div
        className={`bg-blue-lightblue-1 ${
          props.isFeatured ? 'opacity-1' : 'opacity-0'
        } absolute top-0 left-0 z-0 h-full w-full rounded-md transition duration-150 ease-in-out`}
      />
      <div className="mr-4-1/2 relative z-10 mb-3 flex flex-nowrap items-center justify-start sm:mb-6">
        <Img
          alt="profile"
          src={profile}
          classes="w-10-1/2 h-10-1/2 mr-5"
          skeleton={{
            variant: 'circle',
          }}
        />
        <p className="text-blue-navy text-xl font-medium">{name}</p>
      </div>
      <div className="text-blue-navy text-xl font-normal opacity-40">
        {text}
        <p className="text-blue-navy text-right text-xs opacity-80">
          - Paid endorsement
        </p>
      </div>
    </div>
  )
}
