import React from 'react'

export const BlockQuote = ({ children }: { children?: any }) => (
  <aside className="pb-4-1/2 flex sm:pb-6">
    <div className="border-blue-lightblue-3 mr-8 border-2" />
    <p className="font-roboto leading-187 text-blue-navy text-xl font-medium">
      {children}
    </p>
  </aside>
)
