import React, { ReactElement, useContext } from 'react'
import { useRouter } from 'next/router'
import { RateQuote } from '../../../../types'
import { loadRingbaScript } from '@/clients/ringba'
import * as Sentry from '@sentry/nextjs'
import { Context } from '../../../context'
import { getRingbaNumberPoolIdFromCategory } from '@/utils'

export default function RingbaController(): ReactElement {
  const ctx = useContext<RateQuote.AppState>(Context)
  const router = useRouter() 
  
  const setRingbaScript = () => {
    const numberPoolId = getRingbaNumberPoolIdFromCategory(ctx.user?.category)
    loadRingbaScript(numberPoolId)

    if (numberPoolId !== ctx.ringba.numberPoolId && ctx.user.category) {
      ctx.setState({ ...ctx, ringba: { numberPoolId } })
    }
  }

  React.useEffect(() => {
    try {
      const excludedPaths = ['/landing/u65/', '/landing/medicare/'] // Define the paths to be excluded
      if (ctx.user.category && !excludedPaths.includes(router.pathname)) {
        setRingbaScript()
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [ctx.user?.category, router.pathname])

  return <></>
}
