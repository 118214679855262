import * as React from 'react'
import { Wrapper } from './Wrapper'
import { AGE_OPTIONS, getAgeOption } from '../utils'
import { Dropdown, DropdownOption } from '../../../..'
import { getBirthDate } from '../../../../../utils'

import type { QuestionProps } from './types'

export const Age: React.FC<QuestionProps> = (props) => {
  const [value, setValue] = React.useState(getAgeOption(null, null, null))

  React.useEffect(() => {
    const birthDay = props.leadDataGetter['birthDay'] ?? null
    const birthMonth = props.leadDataGetter['birthMonth'] ?? null
    const birthYear = props.leadDataGetter['birthYear'] ?? null
    const initialValue = getAgeOption(birthDay, birthMonth, birthYear)

    setValue(initialValue)
    props.setIsValid(isValid(initialValue))
  }, [])

  const handleChange = (selectedOption: DropdownOption) => {
    const newValueIsValid = isValid(selectedOption)

    setValue(selectedOption)
    props.setIsValid(newValueIsValid)

    if (newValueIsValid) {
      const { birthDay, birthMonth, birthYear } = getBirthDate(selectedOption)

      props.leadDataSetter({ key: 'birthDay', value: birthDay })
      props.leadDataSetter({ key: 'birthMonth', value: birthMonth })
      props.leadDataSetter({ key: 'birthYear', value: birthYear })
    }
  }

  return (
    <Wrapper>
      <>
        <label className="sr-only" id="qf-age-range--label">
          Age
        </label>
        <Dropdown
          id="qf-age-range"
          labelId="qf-age-range--label"
          name="qf-age-range"
          errorMsg="Please select your age."
          onChange={handleChange}
          placeholder="Age"
          options={AGE_OPTIONS.slice(1)}
          value={value}
        />
      </>
    </Wrapper>
  )
}

function isValid(selectedOption: DropdownOption) {
  return selectedOption.value !== ''
}
