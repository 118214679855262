import { Segment } from '../../../types'
import * as Sentry from '@sentry/nextjs'
import pick from 'lodash/fp/pick'
import { hashString } from '../../utils'

export function completeOrder(
  data: Omit<Segment.EventData['Order Completed'], 'version'>,
) {
  track('Order Completed', { ...data, version: '3.4' })
}

export function identify(
  email: string | null,
  traits?: Partial<Segment.UserTraits>,
) {
  const id = email ? hashString(email) : null
  try {
    const identify = ((window as any).analytics as Segment.GlobalObj).identify
    if (id) {
      identify(id, traits)
    } else {
      traits && identify(traits)
    }
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        client: 'segment',
      },
      contexts: {
        context: {
          operation: 'identify',
          id,
        },
      },
    })
  }
}

export function initiateCheckout(
  quickFormData: Omit<Segment.EventData['Initiate Checkout'], 'version'>,
) {
  track('Initiate Checkout', { ...quickFormData, version: '1.3' })
}

export function formComplete(
  data: Omit<Segment.EventData['Form Complete'], 'version'>,
) {
  track('Form Complete', { ...data, version: '1.2' })
}
export function phoneOut(
  data: Omit<Segment.EventData['Phone Out'], 'version'>,
) {
  track('Phone Out', { ...data, version: '1.0' })
}
export function u65PhoneOut() {
  track('U65 Phone Out', { version: '1.0' })
}
export function gu65PhoneOut() {
  track('Google U65 Phone Out', { version: '1.0' })
}
export function medicarePhoneOut() {
  track('Medicare Phone Out', { version: '1.0' })
}
export function gmedicarePhoneOut() {
  track('Google Medicare Phone Out', { version: '1.0' })
}
export function medicareCallButtonClick() {
  track('Medicare_call_button_click', { version: '1.0' })
}
export function gmedicareCallButtonClick() {
  track('Google_Medicare_call_button_click', { version: '1.0' })
}
export function requestPersonalInformation() {
  track('Personal Info Requested', { version: '1.0' })
}

export function startCheckout(
  data: Omit<Segment.EventData['Checkout Started'], 'version'>,
) {
  track('Checkout Started', { ...data, version: '3.0' })
}

function track<T extends keyof Segment.EventData>(
  eventName: T,
  data: Segment.EventData[T],
): void {
  try {
    ;((window as any).analytics as Segment.GlobalObj).track(eventName, data)
  } catch (err) {
    throw new Error(JSON.stringify(err))
  }
}

export function trackQuoteInput(
  segmentData: Omit<Segment.EventData['get-quote-input'], 'version'>,
) {
  track('get-quote-input', {
    ...segmentData,
    step: segmentData.step,
    version: '5.0',
  })
}

export function updatePersonalInformation(
  email: string,
  personalInformation: {
    'do-not-sell'?: boolean
    'first-name'?: string
    'last-name'?: string
    name?: string
    email?: string
  },
) {
  const traits = pick(
    ['do-not-sell', 'first-name', 'last-name', 'name', 'email'],
    personalInformation,
  )
  identify(email, traits)
}

export function viewProductList({
  products,
  category,
}: {
  products: Omit<Segment.Product, 'price' | 'quantity'>[]
  category: string
}) {
  track('Product List Viewed', {
    category,
    nonInteraction: 1,
    products,
    version: '1.1',
  })
}
