import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { throttle } from 'lodash'

import { Img } from '../..'

export const DirectNavBar = () => {
  const navBarElement = React.useRef(null)

  const [isHeaderSticky, setIsHeaderSticky] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)

  const router = useRouter()

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent)
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
    }
  }, [])

  const handleScrollEvent = throttle(() => {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (scrollTop > 0) {
      setIsHeaderSticky(true)
    } else {
      setIsHeaderSticky(false)
    }
  }, 100)

  const sharedNavClasses =
    'text-blue-navy sm:text-grayscale-4 hover:text-blue-primary py-2 px-1 text-xl sm:text-lg md:flex lg:px-3'

  const NavItems = () => (
    <>
      <Link
        href="/about/"
        className={`${sharedNavClasses} ${
          router.pathname === '/about' ? 'c-active' : ''
        }`}
      >
        About Us
      </Link>
      <Link
        href="/partner/"
        className={`${sharedNavClasses} ${
          router.pathname === '/partner' ? 'c-active' : ''
        }`}
      >
        Partner With Us
      </Link>
    </>
  )

  return (
    <nav
      aria-label="direct main"
      className={`z-50 mx-auto w-full py-6 ${
        isHeaderSticky ? 'bg-white-bg sticky top-0 shadow-md' : ''
      }`}
      ref={navBarElement}
      style={{
        transition:
          'padding 100ms ease-in-out, margin 100ms ease-in-out, background-color 100ms ease-in-out',
      }}
    >
      <div className="max-w-8xl mr-7 flex justify-end">
        <div className="hidden space-x-8 sm:mr-12 sm:flex sm:items-center sm:space-x-12">
          <NavItems />
        </div>
        <div className="z-20 ml-7 sm:ml-0">
          <Link href="/" aria-label="Home page">
            <Img
              alt="RateQuote Direct logo"
              classes="h-12 w-12"
              skeleton={{ classes: 'h-12 w-12 bg-transparent' }}
              src="/static/logos/rq-direct-logo-no-text-mono.svg"
            />
          </Link>
        </div>
        <div className="z-20 flex grow items-center justify-end sm:hidden">
          <button
            className="text-blue-navy hover:text-blue-primary z-20 ml-2 inline-flex h-9 w-9 items-center justify-center rounded-md sm:hidden"
            onClick={() => setIsOpen(!isOpen)}
            type="button"
          >
            <div className="flex h-9 w-9 items-center justify-center">
              {isOpen ? (
                <>
                  <span className="sr-only">Close Menu</span>
                  <XIcon
                    aria-hidden="true"
                    className={`text-blue-navy z-10 h-9 w-9 fill-current`}
                  />
                </>
              ) : (
                <>
                  <span className="sr-only">Open menu</span>
                  <MenuIcon
                    aria-hidden="true"
                    className="hover:text-blue-navy z-10 h-7 w-7 fill-current"
                  />
                </>
              )}
            </div>
          </button>
        </div>
      </div>
      {isOpen && (
        <div
          className="c-bg-blur absolute top-0 z-10 min-w-full rounded-b-sm pt-32 sm:hidden"
          id="mobile-menu"
        >
          <div className="grid justify-items-center pb-10">
            <NavItems />
          </div>
        </div>
      )}
    </nav>
  )
}
