import React from 'react'
import { SelectGrid } from '../..'
import { handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

export function MarriedPanel(props: RateQuote.FormPanel) {
  const { showBtnLoader } = props

  return (
    <SelectGrid
      columns="grid-cols-1 sm:grid-cols-2"
      items={[
        [
          {
            label: 'Yes',
            value: '1',
          },
          {
            label: 'No',
            value: '0',
          },
        ],
      ]}
      loading={showBtnLoader}
      onChange={handleGridButtonSelect(props.input, props.handleInput)}
      value={props.value || ''}
    />
  )
}
