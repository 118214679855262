import Link from 'next/link'
import React from 'react'
import { useRouter } from 'next/router'

import { getLabelFromCategory, handlePhoneOut } from '@/utils'
import { Segment } from '../../../../types'
import TTY from '../../atoms/TTY'

export function Disclaimer({
  category,
  phoneNum,
}: {
  category: Segment.Category
  phoneNum: string
}) {
  const router = useRouter()
  const formattedCategory = getLabelFromCategory(category).split(' ')[0]
  const displayCategory =
    formattedCategory === 'Auto'
      ? formattedCategory.toLowerCase()
      : formattedCategory

  const isStep2 = router.query.step === '2'

  return (
    <div className="mx-auto mt-10 -mb-1 w-full  text-left text-sm">
      <label>
        <input type="hidden" id="leadid_tcpa_disclosure" />
        <span className="mb-2">
          Disclaimer:{' '}
          {category === 'health-insurance' && (
            <>
              This is a solicitation for insurance. Products and services
              include Medicare Supplement, Medicare Advantage, and Prescription
              Drug Plans. Any information we provide is limited to those plans
              we do offer in your area. Please contact{' '}
              <Link
                href="http://medicare.gov/"
                className="text-blue-primary underline"
                rel="noopener"
                target="_blank"
              >
                Medicare.gov
              </Link>{' '}
              or 1–800–MEDICARE to get information on all of your options
              <br />
            </>
          )}
          By clicking &quot;See My GreatQuotes&quot;
          {isStep2 && ' or "Next Step" '}
          above, I provide my electronic signature and express written consent
          to telemarketing calls, text messages, emails, and or postal mail from
          RateQuote, our{' '}
          <Link
            href="/marketing-partners/"
            target="_blank"
            rel="noopener"
            className="text-blue-primary underline"
          >
            marketing network
          </Link>
          , and up to ten insurance companies or their affiliates or
          representatives at the email address, postal address or phone number
          that I provided above (including wireless numbers, if applicable, even
          if previously registered on any Federal, State, or company Do Not Call
          (DNC) Registry). I consent to calls and or text messages transmitting
          insurance quotes, insurance information or seeking related additional
          information from me, using an telephone call, text, or email or
          prerecorded or artificial voices. Carrier data rates may apply. I also
          expressly consent to calls or text messages etc. I understand that my
          signature is not a condition of purchasing any goods, services, or
          property and that I may revoke my consent at any time. <br />
          By clicking on &quot;See My GreatQuotes&quot; above, and submitting
          this form I agree that I am 18+ years of age and I confirm that I have
          read and agree to the{' '}
          <Link
            href="/terms-conditions/"
            target="_blank"
            rel="noopener"
            className="text-blue-primary underline"
          >
            Terms
          </Link>{' '}
          and{' '}
          <Link
            href="/privacy-policy/"
            target="_blank"
            rel="noopener"
            className="text-blue-primary underline"
          >
            Privacy Policy
          </Link>
          .
        </span>
        <br />
        <span>
          {`If you wish to receive ${displayCategory} insurance quotes without providing the
            above consent please call`}{' '}
          <a
            className="text-blue-primary underline"
            href={`tel:+${phoneNum}`}
            onClick={() => handlePhoneOut({ category, phoneNumber: phoneNum })}
          >
            {phoneNum}
            <TTY category={category} />
          </a>
          .
        </span>
      </label>
    </div>
  )
}
