import { emailIsValid } from '@/utils'

export const checkInputValidity = (
  inputValue: unknown,
  type: string,
  errorMsg: string | undefined,
  required: boolean | undefined,
) => {
  let msg = ''
  if (inputValue) {
    // create our Ratequote patterns here whenever possible instead of passing them as props
    switch (type) {
      case 'email': {
        if (!emailIsValid(inputValue as string) || !inputValue) {
          msg = 'Invalid email address.'
        }
        break
      }
      case 'url': {
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i',
        ) // fragment locator
        if (!pattern.test(inputValue as string)) {
          msg = 'Invalid url.'
        }
        break
      }
      default: {
        break
      }
    }
  }
  if (!inputValue && required) {
    msg = errorMsg || 'Missing required field'
  }
  // Error message can come from child if want to specificy.  Otherwise parent has default validation and error messages based on type.
  if (msg !== '' && inputValue) {
    msg = errorMsg ? errorMsg : msg
  }
  return msg
}

const INPUT_MODE_OPTIONS = ['numeric', 'search', 'tel', 'text', 'url', 'email']

export const getInputMode = (type: string, specialProp: string | undefined) => {
  let mode
  INPUT_MODE_OPTIONS.map((item) => {
    if (item === type && !specialProp) {
      mode = type
    } else {
      mode = specialProp
    }
  })
  return mode
}
