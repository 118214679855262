import React from 'react'
import { DatePanel as DatePanel, SelectGrid } from '../../'
import { FormLabel } from '../../templates/Form/Label'

import { handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

interface Props_ {
  handleSelection: (val: string) => void
  userDidSubmit: boolean
}

type Props = Props_ & RateQuote.FormPanel

export const QLEPanel = (props: Props) => {
  return (
    <>
      <SelectGrid
        columns="grid-cols-1"
        enableLoader={props.value.qle === 'none'}
        itemContainerClasses="max-w-xs mx-auto"
        items={[
          [
            { label: 'Marriage or Divorce', value: 'marriage_divorce' },
            { label: 'Job Loss or Change', value: 'job_loss_change' },
            { label: 'Permanent Move', value: 'permanent_move' },
            {
              label: 'Birth or Adoption of Child',
              value: 'birth_adoption_child',
            },
            { label: 'Other', value: 'other' },
            { label: 'None', value: 'none' },
          ],
        ]}
        onChange={handleGridButtonSelect(props.input, props.handleSelection)}
        value={props.value.qle || ''}
      />
      {props.value.qle && props.value.qle !== 'none' && (
        <div className="my-6">
          <div className="mb-4">
            <FormLabel className="sm:leading-300 md:leading-302" as="label">
              Date of the event:
            </FormLabel>
          </div>
          <DatePanel
            handleInput={props.handleInput}
            userDidSubmit={props.userDidSubmit}
            value={props.value.date}
            noFutureDate
          />
        </div>
      )}
    </>
  )
}
