const ALL_PATHS = [
  {
    form: 'auto-quote',
    paths: [
      '',
      'drivers/currently-insured',
      'drivers/gender',
      'drivers/dob',
      'drivers/contact-name',
      'drivers/contact-address',
      'drivers/contact-method',
      'results',
    ],
  },
  {
    form: 'medicare-quote',
    paths: [
      '',
      'applicants/contact-name-method',
      'applicants/currently-insured',
      'applicants/coverage-type',
      'applicants/dob-gender',
      'results',
    ],
  },
  {
    form: 'health-insurance-quote',
    paths: [
      '',
      'applicants/contact-method',
      'applicants/currently-insured',
      'applicants/insurance-provider',
      'applicants/coverage-type',
      'applicants/primary/name-dob-gender',
      'applicants/income-pregnancy',
      'applicants/qle',
      'results',
    ],
  },
  {
    form: 'get-quote',
    paths: [
      '',
      'applicants/contact-method',
      'applicants/currently-insured',
      'applicants/insurance-provider',
      'applicants/coverage-type',
      'applicants/primary/name-dob-gender',
      'applicants/income-pregnancy',
      'applicants/qle',
      'results',
    ],
  },
]

export function getQuestionPaths(form: string): string[] {
  return ALL_PATHS.find((v) => v.form === form)?.paths || []
}
