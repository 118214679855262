import React from 'react'
import { SelectGrid } from '../../'
import { handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

export function CurrentlyInsuredPanel(props: RateQuote.FormPanel) {
  const { showBtnLoader, children } = props

  return (
    <div>
      <SelectGrid
        columns="grid-cols-1 sm:grid-cols-2"
        items={[
          [
            {
              label: 'Yes',
              value: '1',
            },
            {
              label: 'No',
              value: '0',
            },
          ],
        ]}
        loading={showBtnLoader}
        onChange={handleGridButtonSelect(props.input, props.handleInput)}
        value={props.value || ''}
      />
      {children && children}
    </div>
  )
}
