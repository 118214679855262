import { Segment } from '../../../types'
import { phoneOut, startCheckout } from '../../clients/segment'
import { setOrderId, addUserInformationToRingba } from '../../clients/ringba'
import { getRingbaPhoneNumber } from '../'

export function handlePhoneOut({
  phoneNumber,
  category,
}: {
  phoneNumber: string
  category: Segment.Category
}) {
  const orderId = setOrderId()
  addUserInformationToRingba()
  const data: Omit<Segment.EventData['Checkout Started'], 'version'> = {
    affiliation: 'ringba',
    category,
    label: 'phone-out',
    order_id: orderId,
    success: true,
    test: false,
    type: 'phone-out',
    value:
      getRingbaPhoneNumber() ||
      parseInt(phoneNumber.replace(/[^0-9]/g, ''), 10),
  }
  startCheckout(data)
  phoneOut({ orderId })
}
