import React, { FC } from 'react'
import { Button, Img, NavItem } from '../..'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { getCtaDestination, getPhoneNumber, handlePhoneOut } from '@/utils'
import { throttle } from 'lodash'
import { Segment } from '../../../../types/segment'
import TTY from '../../atoms/TTY'
import shouldDisplayTty247 from '@/utils/should-display-tty-247/shouldDisplayTty247'
import {
  getDefaultCallCenterValue,
  getIncrementalLiveAgents,
  isCallCenterOpen,
} from '@/utils/call-center'
export interface NavBarProps {
  category: Segment.Category | undefined
  /**
   * Navbar form will trigger the styling changes that take place in the form section.  This mostly affects the mobile view.
   */
  onForm?: boolean
  phoneNumber?: string
  hideItems?: boolean
  /**
   * The results prop is to be used after completeing the form.  The contact phone and get started button are hidden but the hamgburger color reverts to match the main navbar.
   */
  results?: boolean
}

// Define the navigation items
const NAV_ITEMS = [
  // {
  //   id: 1,
  //   href: '/',
  //   text: 'Home',
  //   activeOn: '/',
  // },
  {
    id: 2,
    href: '/health-insurance-quote/',
    text: 'Health',
    activeOn: '/health-insurance-quote',
  },
  {
    id: 3,
    href: '/medicare-quote/',
    text: 'Medicare',
    activeOn: '/medicare-quote',
  },
  {
    id: 4,
    href: '/guides/',
    text: 'Guides',
    activeOn: '/guides',
  },
  {
    id: 5,
    href: '/about/',
    text: 'About Us',
    activeOn: '/about',
  },
  {
    id: 6,
    href: '/partner/',
    text: 'Partner With Us',
    activeOn: '/partner',
  },
  // {
  //   id: 7,
  //   href: '/resources',
  //   text: 'Resources',
  //   activeOn: '/resources',
  // },
]

export const NavBar: FC<NavBarProps> = (props: NavBarProps) => {
  const { category = 'u65', onForm, results = false } = props
  const [ctaDestination, setCtaDestination] = React.useState({
    pathname: '/get-quote',
  })
  const phoneNumber = props.phoneNumber || getPhoneNumber(category)

  const navBarElement = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [liveAgents, setLiveAgents] = React.useState(0)
  const [phoneIcon, setPhoneIcon] = React.useState('phone-cta-dark')
  const [isHeaderSticky, setIsHeaderSticky] = React.useState(false)
  const router = useRouter()

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent)
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
    }
  }, [])

  React.useEffect(() => {
    const dest = getCtaDestination(category)
    setCtaDestination(dest)
    router.prefetch(dest.pathname)
  }, [category])

  const handleScrollEvent = throttle(() => {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (scrollTop > 0) {
      setIsHeaderSticky(true)
    } else {
      setIsHeaderSticky(false)
    }
  }, 100)

  React.useEffect(() => {
    setLiveAgents(getDefaultCallCenterValue())
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      if (liveAgents > 10) setLiveAgents(getIncrementalLiveAgents(liveAgents))
    }, 20000)
  }, [liveAgents])

  const GetStartedButton = ({ classes = '' }) => {
    return (
      <Button classes={classes} onClick={() => router.push(ctaDestination)}>
        Get Started
      </Button>
    )
  }

  // Render the nav items
  const navItems = NAV_ITEMS.map((item) => (
    <NavItem
      key={item.id}
      classes={router.asPath.includes(item.activeOn) ? 'c-active' : ''}
      href={item.href}
      text={item.text}
      onClick={() => setIsOpen(false)}
    />
  ))

  return (
    <>
      <nav
        aria-label="main"
        className={`xxxl:py-15 z-50 w-full py-6 ${
          isHeaderSticky
            ? 'xxxl:pb-6 xxxl:mb-9 bg-white-bg xxxl:-top-9 sticky top-0 shadow-md'
            : 'relative'
        }`}
        ref={navBarElement}
        style={{
          transition:
            'padding 100ms ease-in-out, margin 100ms ease-in-out, background-color 100ms ease-in-out',
        }}
      >
        <div className="max-w-8xl xl:px-22 mx-auto px-5">
          <div className="flex justify-between">
            <div className="z-20 flex items-center lg:grow">
              <div
                className={`${
                  onForm || results ? 'xxs:block' : 'xs:block'
                } hidden`}
              >
                <Link href="/" aria-label="Home page">
                  <Img
                    classes="w-56 h-30"
                    skeleton={{ classes: 'w-47 h-10 bg-transparent' }}
                    src="/static/logos/rq-logo.svg"
                    alt="Rate Quote"
                  />
                </Link>
              </div>
              <div
                className={`${onForm || results ? 'xxs:hidden' : 'xs:hidden'}`}
              >
                <Link href="/" aria-label="Home page">
                  <Img
                    classes="h-11 w-11"
                    skeleton={{ classes: 'w-11 h-11 bg-transparent' }}
                    src="/static/logos/rq-logo-no-text.svg"
                    alt="Rate Quote"
                  />
                </Link>
              </div>
            </div>
            {!props.hideItems && (
              <div className="xs:ml-12 xxl:space-x-6 ml-10 hidden space-x-2 text-xs sm:ml-0 lg:flex lg:items-center lg:space-x-3">
                {navItems}
              </div>
            )}
            <div className="z-20 flex grow items-center justify-end">
              <div
                className={`${
                  onForm || results ? 'hidden lg:flex' : 'flex'
                } -mr-6 w-full items-center justify-end lg:mr-0`}
              >
                {isCallCenterOpen() ? (
                  <a
                    href={`tel:+${phoneNumber}`}
                    className={`text-circular text-blue-navy hover:text-blue-primary text-md ${
                      shouldDisplayTty247(category) ? 'flex' : 'flex'
                    } items-center no-underline md:flex`}
                    onClick={() => handlePhoneOut({ phoneNumber, category })}
                    onMouseEnter={() => setPhoneIcon('phone-cta-blue')}
                    onMouseLeave={() => setPhoneIcon('phone-cta-dark')}
                  >
                    <img
                      alt="phone"
                      className="mr-2 -mt-6 w-4 xl:h-6 xl:w-4"
                      src={`/static/icons/${phoneIcon}.svg`}
                    />
                    <div>
                      <span
                        className={`${
                          !onForm ? 'mr-6' : ''
                        } xxl:text-lg sm:text-md flex text-sm`}
                      >
                        {phoneNumber} <TTY category={category} />
                        <br />
                      </span>
                      {shouldDisplayTty247(category) ? (
                        <>
                          <div className="-mt-1 text-xs">
                            Speak with a Licensed Insurance Agent
                          </div>
                        </>
                      ) : (
                        <div className="-mt-2 text-xs">&nbsp;</div>
                      )}
                      <br />
                      <div className="-mt-6 text-xs">
                        {liveAgents} live agents standing by!
                      </div>
                    </div>
                  </a>
                ) : (
                  !props.hideItems &&
                  !onForm && (
                    <div
                      className={`max-w-32 xxs:max-w-36 ${
                        isCallCenterOpen() ? '' : 'mr-10'
                      } w-full`}
                    >
                      <GetStartedButton classes="text-base xxs:text-lg px-3 xxs:px-4" />
                    </div>
                  )
                )}
              </div>
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className={`text-blue-navy hover:text-blue-primary z-20 ${
                  !onForm && 'ml-2'
                } inline-flex h-9 w-9 items-center justify-center rounded-md lg:hidden`}
              >
                <div className="w-26 flex h-9 items-center justify-center">
                  {isOpen ? (
                    <>
                      <span className="sr-only">Close Menu</span>
                      <XIcon
                        aria-hidden="true"
                        className={`text-blue-navy z-10 h-9 w-9 fill-current`}
                      />
                    </>
                  ) : (
                    <>
                      <span className="sr-only">Open menu</span>
                      <MenuIcon
                        className={`z-10 h-7 w-7 ${
                          onForm && !isHeaderSticky
                            ? 'text-white-bg hover:text-blue-navy fill-current'
                            : 'text-blue-navy fill-current'
                        }`}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
        {isOpen && (
          <div
            className="c-bg-blur absolute top-0 z-10 min-w-full rounded-b-sm pt-32 lg:hidden"
            id="mobile-menu"
          >
            <div className="grid justify-items-center pb-10">
              {navItems}
              {(onForm || shouldDisplayTty247(category)) && (
                <span
                  className={`${
                    onForm
                      ? 'lg:hidden'
                      : shouldDisplayTty247(category)
                      ? 'md:hidden'
                      : ''
                  }`}
                >
                  <Button
                    classes={`mt-5 px-5 w-47 mx-auto flex`}
                    icon={
                      <img
                        className="w-4-1/2 mr-3-3/4"
                        src="/static/icons/phone-cta-white.svg"
                      />
                    }
                    onClick={() => handlePhoneOut({ phoneNumber, category })}
                  >
                    <a
                      className={`${
                        shouldDisplayTty247(category)
                          ? 'xs:text-lg text-sm'
                          : ''
                      } text-white no-underline`}
                      href={`tel:+${phoneNumber}`}
                    >
                      {phoneNumber} <TTY category={category} />
                    </a>
                  </Button>
                  <div className="mt-2 text-center text-xs">
                    Speak with a Licensed Insurance Agent
                  </div>
                </span>
              )}
            </div>
          </div>
        )}
      </nav>
    </>
  )
}
