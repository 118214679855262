import React from 'react'

interface TextHighlightProps {
  /**
   * The title of the page
   */
  title: string
  /**
   * An array of numbers corresponding to coloring of substrings in the title
   * Example: emphasisIndex = [1,2] will colorize 'home plans' in 'Compare home plans."
   */
  emphasisIndex?: number[]
}

export const TextHighlight = (props: TextHighlightProps) => {
  const { title, emphasisIndex } = props
  return (
    <div>
      {title.split(' ').map((word, ind) => (
        <span
          key={ind}
          className={
            emphasisIndex?.includes(ind)
              ? 'text-blue-primary'
              : 'text-blue-navy'
          }
        >
          {word + ' '}
        </span>
      ))}
    </div>
  )
}
