import React from 'react'
import map from 'lodash/map'
import { Img, SelectGrid } from '../..'
import { handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

export interface VehicleMakeProps {
  makes: RateQuote.Make[]
}

type Props = VehicleMakeProps & RateQuote.FormPanel

export function VehicleMakePanel(props: Props) {
  const { showBtnLoader } = props
  const [items, setItems] = React.useState<RateQuote.Make[][]>([[]])

  // 6 Most Popular Makes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const popularMakes = props.makes.slice(0, 6)
  // Other Makes (Sorted Alphabetically)
  const otherMakes = props.makes.slice(6)
  otherMakes.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    }
    return 0
  })

  React.useEffect(() => {
    setItems([popularMakes, otherMakes])
  }, [])
  return (
    <SelectGrid
      columns={'xxs:grid-cols-2 md:grid-cols-3'}
      itemClasses="justify-start sm:px-3 text-left sm:leading-none"
      itemContainerClasses="h-16"
      items={renderMakeOption(items)}
      loading={showBtnLoader}
      onChange={handleGridButtonSelect(props.input, props.handleInput)}
      showMoreIndex={1}
      value={props.value || ''}
    />
  )
}

function fixMake(params: any) {
  if (params.name === 'gmc') {
    return 'GMC'
  } else if (params.name === 'bmw') {
    return 'BMW'
  } else {
    return params.name.replace(/(^|[\s-])\S/g, function (match: any) {
      return match.toUpperCase()
    })
  }
}

function renderMakeOption(items: RateQuote.Make[][]) {
  const [imgLoaded, setImgLoaded] = React.useState(false)

  return map(items, function (nested: any) {
    return map(nested, function (element: any) {
      return {
        icon: element.logoSrc ? (
          <Img
            classes="max-w-7 -my-8 mr-2"
            alt={element.name}
            skeleton={{ classes: 'mr-2 h-7 w-7 rounded' }}
            src={`${element.logoSrc}`}
            alreadyLoaded={imgLoaded}
            setAlreadyLoaded={setImgLoaded}
          />
        ) : (
          <div className="min-w-15 mr-2 h-full" />
        ),
        label: fixMake(element),
        value: element.name,
      }
    })
  })
}
