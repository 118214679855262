import React from 'react'
import throttle from 'lodash/throttle'
import { Button } from '@/components'
import { handlePhoneOut } from '@/utils'
import { Segment } from '../../../../types/segment'
import { isCallCenterOpen } from '@/utils/call-center'

interface StickyCTAProps {
  category: Segment.EventData['Checkout Started']['category']
  phoneNumber: string
  text: string
  tty: string
}

export const StickyCTA = (props: StickyCTAProps) => {
  const { category, phoneNumber, text, tty } = props

  const [bgColor, setBgColor] = React.useState('bg-blue-navy')
  const [textColor, setTextColor] = React.useState('text-white text-opacity-50')

  let ctaEl: HTMLElement | null
  let footerEl: HTMLElement | null

  React.useEffect(() => {
    ctaEl = document.getElementById('sticky-cta')
    footerEl = document.getElementsByTagName('footer')[0]

    setDynamicStyle()
  }, [])

  const setDynamicStyle = () => {
    if (
      ctaEl &&
      footerEl &&
      ctaEl.getBoundingClientRect().top > footerEl.getBoundingClientRect().top
    ) {
      setBgColor('bg-white')
      setTextColor('text-blue-navy text-opacity-50')
    } else {
      setBgColor('bg-blue-navy')
      setTextColor('text-white text-opacity-50')
    }
  }

  const handleScroll = throttle(setDynamicStyle, 100)

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div
      id="sticky-cta"
      className={`${bgColor} xxs:px-7-1/2 fixed bottom-0 z-20 min-h-fit w-full px-6 py-4 transition duration-200 ease-in sm:py-5`}
    >
      <div className="max-w-8xl mx-auto flex flex-col items-center justify-center md:flex-row">
        <p
          className={`${textColor} font-roboto pb-3 text-center text-lg font-normal leading-6 sm:pb-5 sm:text-xl sm:leading-normal md:pr-6 md:pb-0`}
        >
          {text}
        </p>
        {isCallCenterOpen() && (
          <div className="xs:w-fit w-full">
            <Button
              classes={`${
                tty ? 'text-base px-3 xxs:px-4 xs:text-lg' : ''
              } hover:bg-blue-primary hover:bg-opacity-90`}
              icon={
                <img
                  className={`${tty ? 'xs:mr-4 mr-2' : 'mr-4'}  h-6 w-5`}
                  alt="phone"
                  src="/static/icons/telephone-white.svg"
                />
              }
              onClick={() => handlePhoneOut({ phoneNumber, category })}
            >
              <a
                className="text-white no-underline"
                href={`tel:+${phoneNumber}`}
              >
                {phoneNumber}
                {tty}
              </a>
            </Button>
            {tty && (
              <div className={`mt-1 text-center text-xs ${textColor}`}>
                Speak with a Licensed Insurance Agent
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
