import * as React from 'react'
import { Wrapper } from './Wrapper'
import { InputBox } from '../../../..'
import type { QuestionProps } from './types'

export const FirstName: React.FC<QuestionProps> = (props) => {
  const [value, setValue] = React.useState<string | undefined>(undefined)
  const [isValid, setIsValid] = React.useState(true)
  const [isDirty, setIsDirty] = React.useState(false)

  React.useEffect(() => {
    const initialValue = props.leadDataGetter.contactFirstName ?? ''

    if (validate(initialValue)) {
      setValue(initialValue)
      setIsValid(true)
      props.setIsValid(true)
    }
  }, [])

  const handleChange = (value: string) => {
    setValue(value)
    setIsDirty(true)

    const newValueIsValid = validate(value)
    if (newValueIsValid) {
      props.leadDataSetter({ key: 'contactFirstName', value })
    }

    setIsValid(newValueIsValid)
    props.setIsValid(newValueIsValid)
  }

  return (
    <Wrapper>
      <label className="sr-only" id="qf-firstname--label">
        First Name
      </label>
      <InputBox
        id="qf-firstname"
        labelId="qf-firstname--label"
        error={isDirty && !isValid}
        errorMsg="Please enter your First Name."
        name="qf-firstname"
        inputMode="numeric"
        maxLength={100}
        onChange={handleChange}
        placeholder="First Name"
        type="name"
        value={value}
      />
    </Wrapper>
  )
}

function validate(value: string): boolean {
  return value.length > 2
}
