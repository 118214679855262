export const ALL_KEYS = [
  'autoInsuranceCurrent',
  'autoInsuranceProvider',
  'birthDay',
  'birthMonth',
  'birthYear',
  'contactAddress',
  'contactAptSteOther',
  'contactCity',
  'contactCounty',
  'contactEmail',
  'contactFirstName',
  'contactLastName',
  'contactPhoneNumber',
  'contactState',
  'contactZip',
  'contactZipSuffix',
  'creditScore',
  'dependents',
  'driverLicense',
  'driverSr22',
  'education',
  'gender',
  'healthInsuranceCurrent',
  'healthInsuranceCoverageType',
  'healthInsuranceProvider',
  'healthInsuranceDependents',
  'healthInsuranceSpouse',
  'height',
  'homeowner',
  'householdIncome',
  'incidentsAccident',
  'incidentsClaim',
  'incidentsDui',
  'incidentsSuspension',
  'incidentsTickets',
  'majorConditionAidsHiv',
  'majorConditionAlcoholDrugAbuse',
  'majorConditionAlzheimersDementia',
  'majorConditionAsthma',
  'majorConditionCancer',
  'majorConditionChronicPain',
  'majorConditionClinicalDepression',
  'majorConditionDiabetes',
  'majorConditionEmphysema',
  'majorConditionEpilepsy',
  'majorConditionHeartAttack',
  'majorConditionHeartDisease',
  'majorConditionHepatitisLiver',
  'majorConditionHighBloodPressure',
  'majorConditionHighCholesterol',
  'majorConditionKidneyDisease',
  'majorConditionMentalIllness',
  'majorConditionMultipleSclerosis',
  'majorConditionPulmonaryDisease',
  'majorConditionRespiratoryDisorder',
  'majorConditionStroke',
  'majorConditionUlcers',
  'majorConditionVascularDisease',
  'majorConditionOther',
  'married',
  'medicareCoverageType',
  'medicareInsuranceCurrent',
  'military',
  'pregnant',
  'qualifyingLifeEvent',
  'qualifyingLifeEventDay',
  'qualifyingLifeEventMonth',
  'qualifyingLifeEventYear',
  'secondVehicle',
  'spouse',
  'tobacco',
  'vehicleMake1',
  'vehicleModel1',
  'vehicleYear1',
  'vehicleMake2',
  'vehicleModel2',
  'vehicleYear2',
  'weight',
  'zipcode',
] as const
