import * as validator from 'email-validator'
import tlds from 'tlds'

export function emailIsValid(email: string): boolean {
  const emailToValidate = email.toLowerCase()
  return (
    validator.validate(emailToValidate) &&
    tlds.includes(
      emailToValidate.substring(emailToValidate.lastIndexOf('.') + 1),
    )
  )
}
