import React from 'react'

export type CheckboxItem = { label: string; name: string; value: boolean }

interface Props {
  item: CheckboxItem
  onChange: (val: boolean, key?: string) => void
}

export function Checkbox(props: Props) {
  const { item, onChange } = props

  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          checked={item.value}
          id={`${item.name}-checkbox`}
          aria-describedby={`${item.name}-description`}
          name={item.name}
          onChange={(e) => onChange(e.target.checked, item.name)}
          type="checkbox"
          className="border-grayscale-5 h-4 w-4 rounded border border-solid"
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={`${item.name}-checkbox`}
          className="font-roboto text-blue-navy text-base font-normal"
        >
          {item.label}
        </label>
        <span id={`${item.name}-description`} className="text-gray-500">
          <span className="sr-only">{item.label}</span>
        </span>
      </div>
    </div>
  )
}
