import React, { useState, useEffect } from 'react'
import { ClickawayListener, ErrorMessage } from '../..'

export interface DropdownOption {
  label?: string
  url?: string
  value: string | number
}

export interface DropdownProps {
  /**
   * Unique styling applied to the container of the entire dropdown.  Try to apply styling to the child component here when possible.
   */
  containerClasses?: string
  /**
   * Unique styling applied to the primary button portion of the dropdown.  Try to apply styling to the child component here when possible.
   */
  btnClasses?: string
  /**
   * Unique styling applied to the dropdown menu portion of the dropdown.  Try to apply styling to the child component here when possible.
   */
  dropdownClasses?: {
    wrapper?: string
    menu?: string
  }
  /**
   * Dropdown menu will not be reachable. Be sure to remove required when applying 'disabled'.
   */
  disabled?: boolean
  /**
   * Pass the error state to a single Dropdown menu that will not be reused.
   */
  error?: boolean
  /**
   * Pass a unique error message to the Dropdown menu.
   */
  errorMsg?: string
  /**
   * Pass an id for the Dropdown menu, required for accessibility.
   */
  id: string
  /**
   * Pass the id for the element that labels this dropdown, if it exists.
   */
  labelId?: string
  /**
   * Required and unqiue for each input.  In general the name should be all lowercase and is thought of as a key in the form field values.
   */
  name: string
  /**
   * The options that you want to show in the Dropdown menu.
   */
  options: DropdownOption[]
  /**
   * Optional function to handle the value entered.  You will likely setState() in the parent with this onChange.
   */
  onChange?: (selectedVal: DropdownOption) => void
  /**
   * Often thought of as helper text inside the Dropdown menu to guide them towards opening the dropdown for options.
   * */
  placeholder?: string
  /**
   * Make the dropdown menu data selection mandatory.  There is a default error message if required and not entered.  You can also create a unique error message passed via props.
   */
  required?: boolean
  /**
   * Value of input.
   */
  value: DropdownOption
}

export const Dropdown = (props: DropdownProps) => {
  const {
    containerClasses,
    btnClasses,
    dropdownClasses = { wrapper: '', menu: '' },
    disabled,
    error,
    errorMsg = 'Please select one',
    id,
    labelId,
    name,
    options,
    onChange,
    placeholder = 'Select One',
    required,
    value,
  } = props

  let typingDelayTimeout: NodeJS.Timeout

  const [open, setOpen] = useState(false)
  const [valid, setValid] = useState(true)
  const [active, setActive] = useState(false)
  const [dirty, setDirty] = useState(false)

  // searchQuery allows user to search for options via keyboard
  const [searchQuery, setSearchQuery] = useState('')
  const resetSearchQuery = () => {
    setSearchQuery('')
    clearTimeout(typingDelayTimeout)
  }
  const [selectedVal, setSelectedVal] = useState<DropdownOption>({
    label: '',
    value: '',
  })

  useEffect(() => {
    setSelectedVal(value)
  }, [value])

  useEffect(() => {
    required && !value && dirty ? setValid(false) : setValid(true)
  }, [open])

  useEffect(() => {
    if (error) {
      setValid(false)
    }
  }, [error])

  useEffect(() => {
    if (open) {
      setActive(true)
      scrollToSelected(selectedVal.value)
    } else {
      setActive(false)
    }
    setDirty(true)
    resetSearchQuery()
  }, [open])

  const traverseOptions = (num: number) => {
    setOpen(true)
    const currSelIdx = options.findIndex(
      (option) => option.value === selectedVal.value,
    )
    const nextSelIdx = currSelIdx + num
    const nextSel =
      nextSelIdx > -1 && nextSelIdx < options.length
        ? options[nextSelIdx]
        : nextSelIdx < 0
        ? {
            label: '',
            value: '',
          }
        : options[options.length - 1]
    if (open) {
      scrollToSelected(nextSel.value)
    }
    handleSelection(nextSel)
  }

  const scrollToSelected = (val: string | number) => {
    const el = document.getElementById(`${val}--option`)
    el?.scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { id } = e.target as HTMLElement
    if (!id) return
    // if key is "Enter", do nothing (Enter already opens the dropdown options)
    switch (e.key) {
      case 'Enter':
        return
      case 'Escape':
        setOpen(false)
        break
      case 'ArrowDown':
        e.preventDefault()
        // choose next item in array of options
        traverseOptions(1)
        break
      case 'ArrowUp':
        e.preventDefault()
        // choose previous item in array of options
        traverseOptions(-1)
        break
    }

    const newSearchQuery = searchQuery + e.key
    setSearchQuery(newSearchQuery)
    typingDelayTimeout = setTimeout(() => resetSearchQuery(), 1000)

    const keyboardSelection = options.find((option) =>
      option.value
        .toString()
        .toLowerCase()
        .startsWith(newSearchQuery.toLowerCase()),
    )
    if (keyboardSelection) {
      handleSelection(keyboardSelection)
    } else return
  }

  const handleSelection = (newSelectedVal: DropdownOption) => {
    setSelectedVal(newSelectedVal)
    scrollToSelected(newSelectedVal.value)
    onChange ? onChange(newSelectedVal) : null
    setActive(false)
  }

  const borderStyle = !open
    ? valid && !error
      ? 'border-grayscale-5 hover:border-blue-navy focus:border-blue-primary'
      : 'border-red-error'
    : `border-blue-primary`

  return (
    <ClickawayListener setState={setOpen}>
      <div
        className={`w-full ${
          !open ? 'rounded-xs' : 'rounded-t-xs rounded-b-none'
        } font-roboto
      ${
        disabled
          ? 'bg-grayscale-3 hover:border-grayscale-1 pointer-events-none'
          : 'bg-white'
      } ${containerClasses} `}
        id={id}
        onClick={() => setOpen(!open)}
      >
        <button
          aria-labelledby={`${labelId} ${
            selectedVal.label || selectedVal.value
          }`}
          aria-expanded={open}
          aria-haspopup="listbox"
          className={`rounded-xs relative z-10 flex h-full w-full items-center border border-solid bg-white
      px-4 py-3 focus-visible:outline-none ${borderStyle} ${
            !open ? 'rounded-b-xs' : 'rounded-b-none border-b-transparent'
          } ${btnClasses}`}
          id={`${id}-options-menu`}
          onBlur={resetSearchQuery}
          onClick={() => setOpen(!open)}
          onKeyDown={handleKeyDown}
          type="button"
          name={name}
        >
          <div className="flex w-full items-center justify-between">
            <span
              className={`truncate-width ml-0 truncate capitalize ${
                selectedVal.value ? 'text-blue-navy' : 'text-grayscale-1'
              } `}
            >
              {selectedVal.label || selectedVal.value || placeholder}
            </span>
            <span
              className={`flex w-6 ${
                open ? 'rotate-180 justify-start' : 'justify-end'
              }`}
            >
              {disabled ? (
                <img alt="" src="/static/icons/caret-gray.svg" />
              ) : (
                <img alt="" src="/static/icons/caret-black.svg" />
              )}
            </span>
          </div>
        </button>
        <div
          className={`relative z-10 -mt-0.5 bg-transparent
                   ${dropdownClasses.wrapper}
                  ${!open ? 'hidden' : 'flex'}`}
        >
          <div
            id="dropdown-menu"
            className={`rounded-b-xs absolute z-0 max-h-64
                  w-full overflow-y-scroll border border-t-0 border-solid bg-white ${borderStyle} ${dropdownClasses.menu}`}
          >
            <ul
              aria-orientation="vertical"
              aria-labelledby={labelId}
              className="z-50 w-full pt-2"
              role="listbox"
              tabIndex={-1}
              aria-activedescendant={String(selectedVal.value)}
            >
              {options.map((item) => (
                <li
                  aria-selected={item.value === selectedVal.value}
                  id={`${item.value}--option`}
                  key={item.value}
                  role="option"
                >
                  <button
                    className={`font-roboto hover:bg-blue-lightblue-6 min-h-10 hover:text-blue-lightblue-3 focus-visible:text-blue-lightblue-3 focus-visible:bg-blue-lightblue-6 w-full px-3 py-2
                   text-left text-base font-normal capitalize focus-visible:outline-none
                   ${
                     item.value === selectedVal.value
                       ? 'bg-blue-lightblue-6'
                       : 'bg-white'
                   }
                   `}
                    onClick={() => {
                      handleSelection(item)
                      setOpen(false)
                    }}
                    type="button"
                  >
                    {item.label || item.value}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {(error || !valid) && !active && <ErrorMessage message={errorMsg} />}
    </ClickawayListener>
  )
}
