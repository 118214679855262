import React from 'react'
import { SelectGrid } from '../..'
import { handleGridButtonSelect } from '@/utils'
import { ls } from '@/utils/local-storage'
import { getVehicles } from '@/clients/vehicle'

import { RateQuote } from '../../../../types'
import { useLeadData } from '@/hooks'

const MIN_GRID_ITEMS = 6

const DEFAULT_GRID_ITEMS = (() => {
  const gridItems = []
  for (let i = 0; i < MIN_GRID_ITEMS; i++) {
    gridItems.push({
      label: '',
      value: '',
    } as Item)
  }
  return gridItems
})()

interface Item {
  label: string
  value: string
  make?: string
}

export interface VehicleModelProps {
  number?: '1' | '2'
  inputName: string
}

type DataKey = 'vehicleMake1' | 'vehicleMake2' | 'vehicleYear1' | 'vehicleYear2'

type Props = VehicleModelProps & RateQuote.FormPanel

export function VehicleModelPanel(props: Props) {
  const { number = '1', showBtnLoader, inputName } = props
  const [loadingModels, setLoadingModels] = React.useState(true)
  const [gridItems, setGridItems] = React.useState([DEFAULT_GRID_ITEMS])
  const [, setAutocompleteOptions] = React.useState(DEFAULT_GRID_ITEMS)
  const [, setNoResultsForYearMake] = React.useState(false)

  const dataKeys = {
    make: `vehicleMake${number}`,
    year: `vehicleYear${number}`,
  }

  const [make] = useLeadData(dataKeys.make as DataKey)
  const [year] = useLeadData(dataKeys.year as DataKey)

  const loadModels = async () => {
    setLoadingModels(true)

    const { gridItems, modalsForAutoComplete } = await getVehicles({
      year,
      make,
      field: inputName,
    })
    if (gridItems && modalsForAutoComplete) {
      setGridItems(gridItems)
      setAutocompleteOptions(modalsForAutoComplete)
    }

    setLoadingModels(false)
  }

  const appendFallbackOptionToGridItems = (option: Item) => {
    const head = [option, ...gridItems[0]]
    gridItems[0] = head
    setGridItems([...gridItems])
  }

  React.useEffect(() => {
    loadModels()
  }, [])

  const gridIncludesOption = (option: Item) => {
    return (
      !!gridItems[0]?.find((it) => it.value === option.value) ||
      !!gridItems[1]?.find((it) => it.value === option.value)
    )
  }

  // when gridItems updates, checks if a fallback model item
  // needs to be appended or removed from local storage.
  React.useEffect(() => {
    if (gridItems[0][0]?.value !== '') {
      const fallbackGridItem = ls.getItem(`backupModelItem${number}`) || {}

      if (fallbackGridItem?.make === make) {
        if (!gridIncludesOption(fallbackGridItem)) {
          appendFallbackOptionToGridItems(fallbackGridItem)
        }
      } else {
        ls.removeItem(`backupModelItem${number}`)
      }

      gridItems[0].length === 0 && setNoResultsForYearMake(true)
    }
  }, [gridItems])

  return (
    <SelectGrid
      columns={'xxs:grid-cols-2 md:grid-cols-3'}
      itemClasses="justify-center sm:px-3 sm:leading-none"
      itemContainerClasses="h-16"
      items={gridItems}
      loading={loadingModels || showBtnLoader}
      onChange={handleGridButtonSelect(props.input, props.handleInput)}
      showMoreIndex={1}
      showSearchMatch={true}
      value={props.value || ''}
    />
  )
}
