import { parse } from 'query-string'
import pickBy from 'lodash/fp/pickBy'
import compose from 'lodash/fp/compose'
import identity from 'lodash/fp/identity'
import prop from 'lodash/fp/prop'
import toLower from 'lodash/fp/toLower'
import { RateQuote } from '../../../types'

export default function getContextFromUrl(): Pick<RateQuote.AppState, 'user'> {
  if (typeof window === 'undefined' || window.location == null)
    return { user: { anonymousId: undefined, category: '' } }

  const q = parse(window.location.search)
  const propLower = compose(toLower, prop)
  const context = {
    user: pickBy(identity, {
      category: propLower('category', q),
    }),
  } as Pick<RateQuote.AppState, 'user'>
  if (context.user.category === '' || !context.user.category)
    return undefined as unknown as Pick<RateQuote.AppState, 'user'>
  return context
}
