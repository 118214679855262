import * as React from 'react'

export interface ImgProps {
  /**
   * All images should dispaly an alt tag. Use a blank string if the image is
   * decorative.
   */
  alt: string
  /**
   * Optionally pass classes to the img.
   */
  classes?: string
  /**
   * Optionally pass classes to customize the skeleton.
   */
  skeleton?: {
    classes?: string
    variant?: 'rect' | 'circle'
  }
  src: string
  /**
   * Optional props to set the image was already loaded once to avoid rerenders
   */
  alreadyLoaded?: boolean
  /**
   * setAlreadyLoaded state
   */
  setAlreadyLoaded?: (loaded: boolean) => void
  /**
   * Gives the actual dimensions to image
   */
  autoSetDimensions?: boolean
  /**
   * Disable or enable loading effect. Default: true
   */
  loadingFx?: boolean
}

/**
 * Wraps an image tag and displays a placeholder until the image is loaded.
 */
export function Img(props: ImgProps) {
  const { skeleton = { classes: '', variant: 'rect' }, loadingFx = true } =
    props

  const [loading, setLoading] = React.useState(false)
  const [dimensions, setDimensions] = React.useState<[number, number] | null>(
    null,
  )

  React.useEffect(() => {
    const img = new Image()
    setLoading(true && !props.alreadyLoaded)

    img.onload = () => {
      setLoading(false)

      if (props.autoSetDimensions) {
        img.setAttribute('width', img.naturalWidth.toString())
        img.setAttribute('height', img.naturalHeight.toString())
        setDimensions([img.naturalWidth, img.naturalHeight])
      }

      props.setAlreadyLoaded && props.setAlreadyLoaded(true)
    }
    img.src = props.src
  }, [props.src])

  return loading && loadingFx ? (
    <div
      style={dimensions ? { width: dimensions[0], height: dimensions[1] } : {}}
      className={`${skeleton.classes || props.classes} ${
        skeleton.variant === 'circle' ? 'rounded-full' : 'rounded-none'
      } h-full w-full animate-pulse bg-slate-300`}
    />
  ) : (
    <img alt={props.alt} className={`${props.classes} block`} src={props.src} />
  )
}
