import React from 'react'
import { getLocation, STATES } from './utils'
import { RateQuote } from '../../../../types'
import { Dropdown, InputBox } from '../../'

declare global {
  interface Window {
    resolveGoogleMapsPromise?: () => void
  }
}

interface Props_ {
  handleAutofill: React.Dispatch<
    React.SetStateAction<{
      address: string
      aptSteOther: string
      city: string
      county: string
      state: string
      contactZip: string
      zipSuffix: string
    }>
  >
  setAddressAutofilled: React.Dispatch<React.SetStateAction<boolean>>
  validate: ({
    key,
    force,
  }: {
    key: string
    force?: boolean | undefined
  }) => boolean
}

type Props = Props_ & Omit<RateQuote.FormPanel, 'input'>

export function ContactAddressPanel(props: Props) {
  const { setAddressAutofilled, value: address } = props

  // function to call when user selects an address from autofill
  // const fillAddress = (autocomplete: any) => {
  //   setAddressAutofilled(true)

  //   const placeObj = autocomplete.getPlace()
  //   const addressArray = placeObj.address_components
  //   const updatedAddress = addressArray.reduce(
  //     (
  //       acc: { [x: string]: string },
  //       addressPart: {
  //         long_name: string
  //         short_name: string
  //         types: string[]
  //       },
  //     ) => {
  //       const type = addressPart.types[0]
  //       if (type === 'street_number') {
  //         acc['address'] = `${addressPart.short_name || addressPart.long_name} `
  //       } else if (type === 'route') {
  //         if (acc['address']) {
  //           acc['address'] += addressPart.short_name || addressPart.long_name
  //         } else {
  //           acc['address'] = addressPart.short_name || addressPart.long_name
  //         }
  //       } else if (type === 'locality') {
  //         acc['city'] = addressPart.long_name || addressPart.short_name
  //       } else if (type === 'administrative_area_level_1') {
  //         acc['state'] = addressPart.short_name || addressPart.long_name
  //       } else if (type === 'administrative_area_level_2') {
  //         acc['county'] = addressPart.short_name || addressPart.long_name
  //       } else if (type === 'postal_code') {
  //         acc['contactZip'] = addressPart.short_name || addressPart.long_name
  //       } else if (type === 'postal_code_suffix') {
  //         acc['zipSuffix'] = addressPart.short_name || addressPart.long_name
  //       }
  //       return acc
  //     },
  //     {
  //       address: '',
  //       city: '',
  //       county: '',
  //       state: '',
  //       contactZip: '',
  //       zipSuffix: '',
  //     },
  //   )
  //   props.handleAutofill(updatedAddress)
  // }
  // @TODO: FIX GOOGLE MAPS
  // React.useEffect(() => {
  //   // load the Google Maps API on component mount
  //   getGoogleMaps()
  //     .then((google: any) => {
  //       // attach autocomplete to the Street Address input
  //       const autocomplete = new google.maps.places.Autocomplete(
  //         document.getElementById('get-quotes--address'),
  //         {
  //           componentRestrictions: {
  //             country: 'us',
  //           },
  //           types: ['address'],
  //         },
  //       )
  //       autocomplete.setFields(['address_components'])

  //       // handle selecting an address from the dropdown
  //       autocomplete.addListener('place_changed', () =>
  //         fillAddress(autocomplete),
  //       )
  //     })
  //     .catch((err) =>
  //       Sentry.captureException('Error loading Google Maps API', err),
  //     )

  //   window.addEventListener('keydown', handleEnter)
  //   return () => window.removeEventListener('keydown', handleEnter)
  // }, [])

  // const handleEnter = (e: KeyboardEvent) => {
  //   const { id } = e.target as HTMLElement
  //   if (!id || id !== 'get-quotes--address') return

  //   if (e.key === 'Enter' || e.code === 'Enter') {
  //     const googleDOMNodes = document.getElementsByClassName('pac-container')
  //     if (googleDOMNodes.length > 0) e.preventDefault()
  //   }
  // }

  // Autofills city and state when zipcode is available on first mount
  React.useEffect(() => {
    if (address.contactZip && !address.city && !address.state) {
      const location = getLocation(address.contactZip)
      if (location) {
        props.handleAutofill({
          address: address.address,
          aptSteOther: address.aptSteOther,
          city: location.city || address.city,
          county: address.county,
          state: location.state || address.state,
          contactZip: address.contactZip,
          zipSuffix: address.zipSuffix,
        })
      }
    }
  }, [address.contactZip])

  return (
    <div className="xs:flex-row xs:flex-wrap flex flex-col space-y-3">
      <div className="xs:flex-row xs:flex-nowrap xs:space-x-3 xs:space-y-0 flex w-full flex-col space-y-3">
        <div className="xs:w-auto w-full grow">
          <div id="get-quotes-label--address" className="c-form-label">
            Street Address
          </div>
          <InputBox
            id="get-quotes--address"
            labelId="get-quotes-label--address"
            error={!props.validate({ key: 'address' })}
            errorMsg="Please enter a valid street address."
            name="address"
            disabled={false}
            value={address.address}
            onChange={(val: string) => {
              props.handleInput(val, 'address')
              setAddressAutofilled(false)
            }}
            placeholder=""
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter' || e.code === 'Enter') e.preventDefault()
            }}
            // prevents the browser autofill dropdown from appearing
            onFocus={() => {
              // const thisEl = document.getElementById('get-quotes--address')
              // if (thisEl) thisEl.setAttribute('autocomplete', 'new-password')
            }}
            spellCheck={false}
          />
        </div>
        <div className="xs:w-33 w-full shrink">
          <div id="get-quotes-label--apt-ste-other" className="c-form-label">
            Apt./Ste./Other
          </div>
          <InputBox
            id="get-quotes--apt-ste-other"
            error={!props.validate({ key: 'aptSteOther' })}
            errorMsg="Please enter a valid Apt./Ste./Other."
            labelId="get-quotes-label--apt-ste-other"
            name="apt-ste-other"
            value={address.aptSteOther}
            onChange={(val: string) => props.handleInput(val, 'aptSteOther')}
            spellCheck={false}
          />
        </div>
      </div>
      <div className="flex w-full flex-col space-y-3">
        <div className="xs:flex-row xs:flex-nowrap xs:space-x-3 xs:space-y-0 flex w-full flex-col space-y-3">
          <div id="city" className="xs:w-11/12 w-full grow">
            <div id="get-quotes-label--city" className="c-form-label">
              City
            </div>
            <InputBox
              id="get-quotes--city"
              labelId="get-quotes-label--city"
              error={!props.validate({ key: 'city' })}
              errorMsg="Please enter a valid city."
              name="city"
              value={address.city}
              onChange={(val: string) => {
                props.handleInput(val, 'city')
                setAddressAutofilled(false)
              }}
              spellCheck={false}
            />
          </div>
          <div id="state" className="xs:w-auto min-w-33 w-full shrink">
            <div id="get-quotes-label--state" className="c-form-label">
              State
            </div>
            <Dropdown
              id="get-quotes--state"
              containerClasses="h-12"
              dropdownClasses={{ menu: 'max-h-44' }}
              name="state"
              onChange={(val) => {
                props.handleInput(val.value, 'state')
                setAddressAutofilled(false)
              }}
              labelId="get-quotes-label--state"
              error={!props.validate({ key: 'state' })}
              errorMsg="Select a state"
              options={STATES}
              placeholder=""
              value={{ value: address.state }}
            />
          </div>
        </div>
        <div id="zip-code" className="xs:w-fit w-full">
          <div id="get-quotes-label--address-zip-code" className="c-form-label">
            Zip Code
          </div>
          <InputBox
            id="get-quotes--address-zip-code"
            labelId="get-quotes-label--address-zip-code"
            error={!props.validate({ key: 'zip' })}
            errorMsg="Please enter a valid zip code"
            name="address-zip-code"
            value={address.contactZip}
            onChange={(val: string) => {
              props.handleInput(val, 'contactZip')
              setAddressAutofilled(false)
            }}
            maxLength={5}
            pattern="[0-9]*"
            inputMode="numeric"
            spellCheck={false}
          />
        </div>
      </div>
    </div>
  )
}
