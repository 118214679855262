import React, { useEffect, useState } from 'react'
import { NextSeo } from 'next-seo'
import { useSiteMetadata } from '@/hooks'

interface Props {
  description: string | undefined
  title: string
  url?: string
  image?: string
}

export function SEO(props: Props) {
  const [defaultURL, setDefaultURL] = useState('')

  useEffect(() => {
    setDefaultURL(window.location.origin + window.location.pathname)
  }, [])

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    description,
    title,
    url = defaultURL,
    image = 'https://ratequote.com/static/logos/rq-logo.png',
  } = props
  const siteMetadata = useSiteMetadata()
  const metaDescription = siteMetadata.description

  return (
    <NextSeo
      title={title}
      description={`${description} ${description ?? ' | '} ${metaDescription}`}
      canonical={url}
      openGraph={{
        url: url,
        images: [
          {
            url: image,
            alt: 'Og Image Alt',
          },
        ],
        title,
        description: metaDescription || '',
        site_name: 'RateQuote',
        type: 'website',
      }}
      twitter={{
        handle: '@rate_quote',
        site: '@rate_quote',
        cardType: 'summary_large_image',
      }}
      facebook={{
        appId: '2962540850551553',
      }}
    />
  )
}

SEO.defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
}
