import React from 'react'

export function useBrowserDimensions() {
  const [browserWidth, setBrowserWidth] = React.useState(0)
  const [browserHeight, setBrowserHeight] = React.useState(0)

  React.useEffect(() => {
    setBrowserWidth(window.innerWidth)
    setBrowserHeight(window.innerHeight)

    const handleResize = () => {
      setBrowserWidth(window.innerWidth)
      setBrowserHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { browserHeight, browserWidth }
}
