import React from 'react'
import { SelectGrid } from '../../'
import { handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

export function CreditScorePanel(props: RateQuote.FormPanel) {
  const { showBtnLoader } = props

  return (
    <SelectGrid
      columns="grid-cols-1"
      itemContainerClasses="max-w-xs mx-auto"
      items={[
        [
          { label: 'Excellent (720+)', value: 'excellent' },
          { label: 'Good (680-719)', value: 'good' },
          { label: 'Fair/Average (580-679)', value: 'average' },
          { label: 'Poor (below 580)', value: 'poor' },
          { label: "Not Sure (that's okay!)", value: 'not_sure' },
        ],
      ]}
      loading={showBtnLoader}
      onChange={handleGridButtonSelect(props.input, props.handleInput)}
      value={props.value || ''}
    />
  )
}
