import React from 'react'

import { SelectGrid } from '../../..'
import { handleGridButtonSelect } from '../../../../utils'

interface ViewProps {
  anyDependents: string | null
  handleChange: (e: string) => void
  input: React.RefObject<HTMLInputElement>
  showBtnLoader?: boolean | undefined
}

export const DependentsInitialView = (props: ViewProps) => {
  const { anyDependents, handleChange, input, showBtnLoader } = props
  return (
    <SelectGrid
      columns="grid-cols-2"
      items={[
        [
          {
            label: 'Yes',
            value: '1',
          },
          {
            label: 'No',
            value: '0',
          },
        ],
      ]}
      loading={showBtnLoader}
      onChange={handleGridButtonSelect(input, handleChange)}
      value={anyDependents || ''}
    />
  )
}
