import React from 'react'
import { useRouter } from 'next/router'
import { Button } from '../../'
import { Sanity } from '../../../../types/sanity'

export const CustomButton = ({ value }: { value: Sanity.Button }) => {
  const router = useRouter()
  return (
    <Button classes="max-w-38" onClick={() => router.push(value.buttonUrl)}>
      {value.buttonText}
    </Button>
  )
}
