import React from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export const useRefDimensions = (target: React.RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })

  React.useLayoutEffect(() => {
    setDimensions({
      height: target?.current?.getBoundingClientRect().height || 0,
      width: target?.current?.getBoundingClientRect().width || 0,
    })
  }, [target])

  useResizeObserver(target, (entry) =>
    setDimensions({
      height: entry.contentRect.height,
      width: entry.contentRect.width,
    }),
  )

  return dimensions
}
