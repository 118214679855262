import React from 'react'
import { SelectGrid } from '../../'
import { handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

interface Props_ {
  userDidSubmit?: boolean
}

export function GenderPanel(props: RateQuote.FormPanel & Props_) {
  const { handleInput, input, showBtnLoader, userDidSubmit, value } = props

  return (
    <>
      <SelectGrid
        columns="grid-cols-1 sm:grid-cols-2"
        items={[
          [
            {
              label: 'Male',
              value: 'm',
            },
            {
              label: 'Female',
              value: 'f',
            },
          ],
        ]}
        loading={showBtnLoader}
        onChange={handleGridButtonSelect(input, handleInput)}
        value={value || ''}
      />
      {userDidSubmit && !value && (
        <div className="text-red-error mt-6">Please select a gender.</div>
      )}
    </>
  )
}
