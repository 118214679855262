export const getAge: (
  day: string | undefined,
  month: string | undefined,
  year: string | undefined,
) => number | null = (day, month, year) => {
  if (!day || !month || !year) {
    return null
  }

  const birthDate = new Date(+year, +month - 1, +day)
  const ageDifMs = Date.now() - birthDate.getTime()
  const ageDate = new Date(ageDifMs) // milliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}
