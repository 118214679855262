import React from 'react'

import { Button } from '../../..'

interface ViewProps {
  dependentType: React.Dispatch<React.SetStateAction<string | undefined>>
  handleInput: (key?: string) => void
  hasDependent: boolean
  label: string
  name: 'dependent' | 'spouse'
  showEditView: React.Dispatch<React.SetStateAction<boolean>>
}

export const DependentsAddView = (props: ViewProps) => {
  const {
    dependentType,
    handleInput,
    hasDependent,
    label,
    name,
    showEditView,
  } = props

  const [depType, setDepType] = React.useState('')
  const [showBtnLoader, setShowBtnLoader] = React.useState<any>({})
  const [showEdit, setShowEdit] = React.useState(false)

  React.useEffect(() => {
    dependentType(depType)
    showEditView(showEdit)
  }, [depType, showEdit])

  return (
    <div className="my-2 grid w-full grid-cols-3 sm:grid-cols-2" key={name}>
      <label className="text-blue-navy my-auto text-left text-xl md:w-1/2 md:text-2xl">
        {label}
      </label>
      <div className="col-span-2 grid grid-cols-2 gap-x-2 sm:col-span-1">
        <Button
          classes={`c-select-grid-item-base bg-white-primary border-blue-navy border-opacity-10 ${
            name === 'spouse' &&
            hasDependent &&
            'cursor-not-allowed opacity-50 disabled:bg-blue-lightblue-2'
          }`}
          disabled={name === 'spouse' && hasDependent}
          hideButtonText={showBtnLoader['add-btn']}
          id="add-btn"
          loader={showBtnLoader['add-btn']}
          loaderVariant="dark"
          onClick={() => {
            handleInput(name)
            setShowBtnLoader({ ...showBtnLoader, 'add-btn': true })
          }}
        >
          Add
        </Button>
        <Button
          classes={`c-select-grid-item-base bg-white-primary border-blue-navy border-opacity-10 ${
            !hasDependent &&
            'cursor-not-allowed disabled:opacity-50 disabled:bg-white-primary'
          }`}
          disabled={!hasDependent}
          hideButtonText={showBtnLoader['edit-btn']}
          id="edit-btn"
          loader={showBtnLoader['edit-btn']}
          loaderVariant="dark"
          onClick={() => {
            setDepType(name)
            setShowBtnLoader({ ...showBtnLoader, 'edit-btn': true })
            setShowEdit(true)
          }}
        >
          Edit/View
        </Button>
      </div>
    </div>
  )
}
