import * as React from 'react'
import { parse } from 'query-string'
import { isMobileOnly } from 'react-device-detect'
import compose from 'lodash/fp/compose'
import identity from 'lodash/fp/identity'
import pickBy from 'lodash/fp/pickBy'
import prop from 'lodash/fp/prop'
import toLower from 'lodash/fp/toLower'
import { ls, segment } from '../../utils/'
import { getLeadIdToken } from '../../utils/get-leadid-token'

export interface UserContext {
  adGroupId?: string
  acceptCookie?: boolean
  device?: string
  gclid?: string
  geolocation?: string
  mobile?: boolean
  segmentId?: string
  utmCampaign?: string
  utmContent?: string
  utmMedium?: string
  utmSource?: string
  leadId?: string
  var?: string
}

/**
 * Custom hook that both sets userContext when utm attributes are present in the
 * URL as well as gives access. It also persists userContext to localStorage and
 * uses this in concert with the URL parameters to provide an initial value so
 * that userContext will persist between pages. Attributes present in URL will
 * take precedent over values in localStorage when both are present.
 *
 * Usage:
 *
 * const [userContext, updateUserContext] = useUserContext()
 */
function useUserContext(): [
  UserContext,
  (newUserContext: UserContext) => void,
] {
  const [state, setState] = React.useState<UserContext>(getInitialValue())
  const [segmentId, setSegmentId] = React.useState<string | undefined | null>(
    getInitialValue().segmentId,
  )

  const getSegmentId = async () => {
    const id = await segment.getAnonymousId()
    await setSegmentId(id)
  }

  React.useEffect(() => {
    getSegmentId()
  }, [])

  React.useEffect(() => {
    setState(
      pickBy((i: any) => i != null, {
        ...state,
        leadId: getLeadIdToken(),
        mobile: isMobileOnly,
        segmentId,
      }),
    )
  }, [isMobileOnly, segmentId])

  React.useEffect(() => {
    ls.setItem('userContext', state)
  }, [state])

  return [
    state,
    (newContext: Partial<UserContext>) => setState({ ...state, ...newContext }),
  ]
}

function getInitialValue(): UserContext {
  return {
    ...getUserContextFromLocalStorage(),
    ...getUserContextFromUrl(),
  }
}

function getUserContextFromLocalStorage(): UserContext {
  if (typeof window === 'undefined') return {}
  const found = ls.getItem('userContext')
  return found || {}
}

function getUserContextFromUrl(): UserContext {
  if (typeof window === 'undefined' || window.location == null) return {}
  const q = parse(window.location.search)
  const propLower = compose(toLower, prop)
  return pickBy(identity, {
    adGroupId: propLower('ad_group_id', q),
    device: propLower('device', q),
    gclid: prop('gclid', q),
    geolocation: prop('geolocation', q),
    utmCampaign: propLower('utm_campaign', q),
    utmContent: propLower('utm_content', q),
    utmMedium: propLower('utm_medium', q),
    utmSource: propLower('utm_source', q),
    var: propLower('var', q),
  })
}

export { useUserContext }
