import * as Sentry from '@sentry/nextjs'
import { isJSON } from '..'

const getItem = (item: string): any => {
  try {
    const found = localStorage.getItem(item)

    if (!found || found === 'null' || found === 'undefined') {
      return null
    }
    return isJSON(found) ? JSON.parse(found) : found
  } catch (e) {
    Sentry.withScope(function (scope) {
      scope.setFingerprint(['LocalStorage Error'])
      Sentry.captureException(e)
    })
    return null
  }
}

const removeItem = (item: string): void => {
  try {
    localStorage.removeItem(item)
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setFingerprint(['LocalStorage Error'])
      Sentry.captureException(err)
    })
  }
}

const setItem = (item: string, payload: any): void => {
  try {
    if (!windowExists()) throw new Error('window undefined')
    let data = payload
    if (typeof data !== 'string') {
      data = JSON.stringify(data)
    }
    localStorage.setItem(item, data)
  } catch (e) {
    Sentry.withScope(function (scope) {
      scope.setFingerprint(['LocalStorage Error'])
      Sentry.captureException(e)
    })
  }
}

function windowExists(): boolean {
  return typeof window !== 'undefined'
}

export const ls = {
  getItem,
  removeItem,
  setItem,
}
