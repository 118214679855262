import { RateQuote } from '../../../types'

export const getAutoInsuranceCompanies: {
  label: string
  value: RateQuote.AutoInsuranceProvider
  img?: string
}[] = [
  {
    label: '21st Century',
    value: '21st_century_insurance',
    img: '21st-century',
  },
  {
    label: 'AIG',
    value: 'aig',
    img: 'aig',
  },
  {
    label: 'Allstate',
    value: 'allstate_insurance',
    img: 'allstate',
  },
  {
    label: 'American Family',
    value: 'american_family_insurance',
    img: 'american-family',
  },
  {
    label: 'Country Financial',
    value: 'country_financial',
    img: 'country',
  },
  {
    label: 'eSurance',
    value: 'esurance',
    img: 'esurance',
  },
  {
    label: 'Farmers Insurance',
    value: 'farmers_insurance',
    img: 'farmers',
  },
  {
    label: 'Geico',
    value: 'geico',
  },
  {
    label: 'Liberty Mutual',
    value: 'liberty_mutual_insurance',
    img: 'liberty',
  },
  {
    label: 'Metlife',
    value: 'metlife_auto_and_home',
    img: 'metlife',
  },
  {
    label: 'Nationwide',
    value: 'nationwide_general_insurance',
    img: 'nationwide',
  },
  {
    label: 'Progressive',
    value: 'progressive',
    img: 'progressive',
  },
  {
    label: 'State Farm',
    value: 'state_farm_county',
    img: 'statefarm',
  },
  {
    label: 'Travelers',
    value: 'travelers_insurance_company',
    img: 'travelers',
  },
  {
    label: 'USAA',
    value: 'usaa',
    img: 'usaa',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const getHealthInsuranceCompanies: {
  label: string
  value: RateQuote.HealthInsuranceProvider
  img?: string
}[] = [
  {
    label: 'Aetna',
    value: 'aetna',
  },
  {
    label: 'United HealthCare',
    value: 'united_healthcare',
  },
  {
    label: 'State Farm Insurance',
    value: 'state_farm_insurance',
  },
  {
    label: 'CIGNA',
    value: 'cigna',
  },
  {
    label: 'Blue Cross Blue Shield',
    value: 'blue_cross_blue_shield',
  },
  {
    label: 'Metlife Insurance',
    value: 'metlife_insurance',
  },
  {
    label: 'Aflac',
    value: 'aflac',
  },
  {
    label: 'Assurant',
    value: 'assurant',
  },
  {
    label: 'Farm Bureau Insurance',
    value: 'farm_bureau_insurance',
  },
  {
    label: 'American Family Insurance',
    value: 'american_family_insurance',
  },
  {
    label: 'American Republic Insurance',
    value: 'american_republic_insurance',
  },
  {
    label: 'Atlantis Health Plan',
    value: 'atlantis_health_plan',
  },
  {
    label: 'Celtic Insurance',
    value: 'celtic_insurance',
  },
  {
    label: 'Golden Rule Insurance',
    value: 'golden_rule_insurance',
  },
  {
    label: 'Health Net',
    value: 'health_net',
  },
  {
    label: 'Health Plus of America',
    value: 'health_plus_of_america',
  },
  {
    label: 'Healthmarkets',
    value: 'healthmarkets',
  },
  {
    label: 'Humana',
    value: 'humana',
  },
  {
    label: 'InSphere',
    value: 'insphere',
  },
  {
    label: 'Kaiser Permanente',
    value: 'kaiser_permanente',
  },
  {
    label: 'LifeWise Health Plan',
    value: 'lifewise_health_plan',
  },
  {
    label: 'Oxford Health Plans',
    value: 'oxford_health_plans',
  },
  {
    label: 'PacifiCare',
    value: 'pacificare',
  },
  {
    label: 'Time Insurance',
    value: 'time_insurance',
  },
  {
    label: 'Tufts Health Plan',
    value: 'tufts_health_plan',
  },
  {
    label: 'Unicare',
    value: 'unicare',
  },
  {
    label: 'United American Insurance',
    value: 'united_american_insurance',
  },
  {
    label: 'Vista Health Plan',
    value: 'vista_health_plan',
  },
  {
    label: 'Other',
    value: 'other',
  },
]
