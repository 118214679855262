/* eslint-disable no-unreachable */
import { useEffect, useMemo, useState, useCallback } from 'react'

const getDeepValue = (obj, path) => {
  const paths = path.split('.')
  let current = obj

  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined
    } else {
      current = current[paths[i]]
    }
  }
  return current
}

const useNavigateList = ({
  list,
  onSelect,
  indexPath = 'id',
  setSelectedVal,
  vertical = true,
  columns,
  loading,
  setShowMore,
}) => {
  const [cursor, setCursor] = useState(0)
  const [useKeyboard, setUseKeyboard] = useState(false)

  const prevItemKey = useMemo(
    () => (vertical ? 'ArrowUp' : 'ArrowLeft'),
    [vertical],
  )

  const nextItemKey = useMemo(
    () => (vertical ? 'ArrowDown' : 'ArrowRight'),
    [vertical],
  )

  const focus = (position, event) => {
    if (loading) return
    const value = document.querySelector(`[data-index="${position}"]`)
    if (position < list.length && !value?.focus && setShowMore) {
      setUseKeyboard(false)
      return document.getElementById('showMore_btn').focus()
    }
    if (value && value.focus) value.focus()
    if (event) event.preventDefault()
    setUseKeyboard ? setUseKeyboard(true) : null
    setCursor(position)
  }

  const downHandler = useCallback(
    (event) => {
      return //disable to avoid bugs in production
      if (
        event.key === 'Tab' &&
        String(event.target.getAttribute('data-index')).length > 0
      ) {
        setUseKeyboard ? setUseKeyboard(false) : null
        setSelectedVal(list[cursor].value)
      }
      if (event.key === 'ArrowUp') {
        const position = cursor > 0 ? Math.max(cursor - columns, 0) : cursor
        focus(position, event)
        // event.preventDefault()
      } else if (event.key === 'ArrowLeft') {
        focus(cursor > 0 ? cursor - 1 : cursor, event)
      } else if (event.key === 'ArrowRight') {
        focus(cursor < list.length - 1 ? cursor + 1 : cursor, event)
      } else if (event.key === 'ArrowDown') {
        const position =
          cursor < list.length - 1
            ? Math.min(cursor + columns, list.length)
            : cursor
        focus(position, event)

        setUseKeyboard ? setUseKeyboard(true) : null
      } else if (event.key === 'Enter') {
        if (useKeyboard) onSelect(list[cursor])
      }
    },
    [cursor, list, nextItemKey, prevItemKey],
  )

  useEffect(() => {
    document.addEventListener('keyup', downHandler)

    return () => {
      document.removeEventListener('keyup', downHandler)
    }
  }, [downHandler])

  const onHover = useCallback(
    (hoveredItem) => {
      setCursor(
        list.findIndex(
          (listItem) =>
            getDeepValue(listItem, indexPath) ===
            getDeepValue(hoveredItem, indexPath),
        ),
      )
    },
    [indexPath, list],
  )

  return useMemo(
    () => ({
      activeIndex: cursor,
      useKeyboard,
      setUseKeyboard,
      itemProps: (item) => ({
        onMouseEnter: () => onHover(item),
        onFocus: () => onSelect(item),
      }),
    }),
    [cursor, onHover],
  )
}

export default useNavigateList
