import * as Sentry from '@sentry/nextjs'
import { hashString } from '../../utils'

interface RegulationsResponse {
  errors: []
  regulate_id: string
}

export async function suppressAndDeleteUser(
  email: string,
): Promise<RegulationsResponse> {
  const userId = hashString(email)
  return fetch(`/api/suppress-and-delete-user/?user=${userId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText)
      return res.json()
    })
    .then((data) => data)
    .catch((err) => {
      Sentry.captureException(err, { extra: { userId } })
    })
}

export async function suppressUser(
  email: string,
): Promise<RegulationsResponse> {
  const userId = hashString(email)
  return fetch(`/api/suppress-user/?user=${userId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText)
      return res.json()
    })
    .then((data) => data)
    .catch((err) => {
      Sentry.captureException(err, { extra: { userId } })
    })
}
