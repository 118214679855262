import React from 'react'
import { Checkbox } from '../../'
import { CheckboxItem } from '../../atoms/Checkbox'
import { RateQuote } from '../../../../types'

interface Props_ {
  handleNoConditions: () => void
  noConditions: CheckboxItem
}

type Props = Props_ & Omit<RateQuote.FormPanel, 'input'>

export const MedicalConditions = (props: Props) => {
  const {
    handleInput,
    handleNoConditions,
    noConditions,
    value: conditions,
  } = props

  return (
    <div className="flex justify-center">
      <div className="sm:grid-rows-13 flex flex-col gap-y-3 space-y-1 sm:grid sm:grid-flow-col sm:gap-x-10 sm:space-y-0">
        {conditions.map((condition: CheckboxItem) => (
          <Checkbox
            item={condition}
            key={condition.name}
            onChange={handleInput}
          />
        ))}
        <Checkbox item={noConditions} onChange={handleNoConditions} />
      </div>
    </div>
  )
}
