import React, { FC } from 'react'

export interface ProgressBarProps {
  progress: {
    step: number
    denominator: number
  }
}

export const ProgressBar: FC<ProgressBarProps> = ({
  progress,
}: {
  progress: {
    step: number
    denominator: number
  }
}) => {
  const { step, denominator } = progress

  /**
   * `step` should never be less than 1 or greater than the `denominator`
   */
  const validatedStep = step < 1 ? 1 : step > denominator ? denominator : step
  const width = `${(validatedStep / denominator) * 100}%`

  return (
    <>
      <div className="c-bg-blur relative h-11 w-full rounded-full">
        <div className="relative h-11 w-full rounded-full border-4 border-white bg-none p-1 pr-3">
          <div className="relative h-full w-full">
            <ProgressDots denominator={denominator} />
            <div
              aria-label="form progress"
              aria-valuemax={1}
              aria-valuemin={0}
              aria-valuenow={
                Math.round((step / denominator + Number.EPSILON) * 100) / 100
              }
              className={`${
                validatedStep / denominator < 0.06 ? 'hidden md:block' : 'block'
              } bg-blue-lightblue-4 h-full ${
                validatedStep / denominator < 0.8 && validatedStep < 3
                  ? 'xs:rounded-full rounded-tl-full rounded-bl-full rounded-br-none rounded-tr-none'
                  : 'rounded-full'
              } absolute top-0`}
              role="progressbar"
              style={{
                width,
              }}
            />
            <div
              className={`bg-blue-primary border-3 absolute top-0 h-full w-7 rounded-full border-white ${
                validatedStep / denominator < 0.8 && validatedStep < 5
                  ? 'c-dynamic-ml'
                  : '-ml-5'
              }`}
              style={{
                left: width,
              }}
            />
          </div>
        </div>
      </div>
      <style jsx>
        {`
        .c-dynamic-ml {
          margin-left: ${((validatedStep + 2) * -1) / 4}rem;
        }
        @media (min-width: 480px) {
          .c-dynamic-ml {
            margin-left: -1.25rem;
        }
      `}
      </style>
    </>
  )
}

const ProgressDots = ({ denominator }: { denominator: number }) => {
  const dots = Array.from(Array(denominator).keys())
  const offset = 100 / denominator

  return (
    <div className="absolute top-0 z-0 -ml-5 hidden h-full w-full items-center md:flex">
      {dots.map((dot) => (
        <div
          key={dot}
          className="bg-blue-lightblue-5 absolute h-4 w-4 rounded-full border-4 border-white"
          style={{
            left: `${(dot / denominator) * 100 + offset}%`,
          }}
        />
      ))}
    </div>
  )
}
