import React, { useContext } from 'react'
import { PortableText, PortableTextComponents } from '@portabletext/react'
import { AdvertorialBottomCTA } from './BottomCTA'
import { AdvertorialLayout } from './Layout'
import { HeroImage } from './HeroImage'
import { MiddleCTA } from './MiddleCTA'
import { QuickForm } from './QuickForm'
import { PageHeader } from '@/components'
import { getPhoneNumber, handlePhoneOut } from '@/utils'
import { Sanity } from '../../../../types/sanity'
import { Context } from '../../../context'
import { RateQuote } from '../../../../types'
import dynamic from 'next/dynamic'

const AdvertorialTemplateSSR = (
  props: Omit<Sanity.AdvertorialPage, 'seoTitle' | 'seoDescription'>,
) => {
  const {
    _updatedAt,
    author = '',
    bottomCtaButtonText = '',
    bottomCtaInputPlaceholder = '',
    bottomCtaSubtext = '',
    bottomCtaTitle = '',
    footerLicenseNum = '',
    footerSmid = '',
    image = '',
    imageAltText = '',
    middleCtaSubtext = '',
    middleCtaSubtitle = '',
    middleCtaTitle = '',
    primaryContent,
    showQuickForm,
    quickFormQuestions = [],
    quickFormButtonText = '',
    quickFormCtaText = '',
    quickFormHeadline = '',
    secondaryContent,
    stickyFooterCtaText = '',
    title,
    tty,
    tty247,
    vertical: category, // renaming for clarity
  } = props

  const ctx = useContext<RateQuote.AppState>(Context)
  const [ttyText, setTtyText] = React.useState('')
  const phoneNumber = getPhoneNumber(category)

  const CUSTOM_COMPONENTS: PortableTextComponents = React.useMemo(
    () => ({
      block: {
        normal: (props: any) => (
          <p className="c-sanity-p mb-6">{props.children}</p>
        ),
      },
      list: {
        bullet: (props: any) => {
          return (
            <ul className="c-sanity-p text-blue-navy ml-8 mb-6 list-disc">
              {props.children?.map((child: any) => (
                <li className="mb-3" key={child?.key}>
                  {child?.props.children[0]}
                </li>
              ))}
            </ul>
          )
        },
      },
      marks: {
        // defining this here rather than as a default in SanitySerializers since
        // it requires access to the `phoneNumber`, `category`, `tty`, and `tty247` values:
        phoneNumber: () => {
          return (
            <a
              className="text-blue-primary no-underline"
              href={`tel:+${phoneNumber}`}
              onClick={() =>
                handlePhoneOut({
                  phoneNumber,
                  category,
                })
              }
            >
              {`${phoneNumber}${
                tty ? (tty247 ? ` | TTY 711, 8am - 6pm` : ` | TTY 711`) : ``
              }`}
            </a>
          )
        },
        strong: (props: any) => (
          <p className="c-sanity-p text-blue-navy font-bold">
            {props.children}
          </p>
        ),
      },
    }),
    [props.primaryContent, props.secondaryContent],
  )

  React.useEffect(() => {
    if (category && ctx.user?.category !== category) {
      ctx.setState({
        ...ctx,
        user: {
          ...ctx.user,
          category,
        },
      })
    }
  }, [category, ctx.user?.category])

  React.useEffect(() => {
    if (tty) {
      if (tty247) {
        setTtyText(` | TTY 711, 8am - 6pm`)
      } else setTtyText(` | TTY 711`)
    }
  }, [tty, tty247])

  return (
    <AdvertorialLayout
      footerLicenseNum={footerLicenseNum}
      footerSmid={footerSmid}
      stickyFooterCtaText={stickyFooterCtaText}
      tty={ttyText}
      category={category}
    >
      <div>
        <PageHeader
          author={author}
          title={title}
          variant="advertorial"
          date={_updatedAt}
        />
      </div>
      {image && (
        <div className="mb-10">
          <HeroImage altText={imageAltText} src={image} />
        </div>
      )}
      <div className="max-w-3-1/2xl mx-auto mb-4">
        {primaryContent && (
          <PortableText value={primaryContent} components={CUSTOM_COMPONENTS} />
        )}
      </div>
      {showQuickForm && (
        <div className="relative">
          {/* only including bg-triangle div if QuickForm is included. Otherwise the page is too short to accommodate it. */}
          <div className="c-bg-triangle lg:-mt-18 -mt-12 w-screen bg-right-top" />
          <div className="mt-8 mb-6">
            <QuickForm
              category={category}
              phoneNumber={phoneNumber}
              headline={quickFormHeadline}
              buttonText={quickFormButtonText}
              ctaText={quickFormCtaText}
              initialQuestions={quickFormQuestions}
            />
          </div>
        </div>
      )}
      <div className="z-10">
        <div className="mb-8">
          {middleCtaTitle && (
            <MiddleCTA
              category={category}
              title={middleCtaTitle}
              description={middleCtaSubtitle}
              phoneNumber={phoneNumber}
              subtext={middleCtaSubtext}
              tty={ttyText}
            />
          )}
        </div>
        <div className="max-w-3-1/2xl mx-auto">
          {secondaryContent && (
            <PortableText
              value={secondaryContent}
              components={CUSTOM_COMPONENTS}
            />
          )}
        </div>
      </div>
      {bottomCtaTitle && (
        <AdvertorialBottomCTA
          title={bottomCtaTitle}
          subtext={bottomCtaSubtext}
          buttonText={bottomCtaButtonText}
          category={category}
          phoneNumber={phoneNumber}
          inputPlaceholder={bottomCtaInputPlaceholder}
          tty={ttyText}
        />
      )}
    </AdvertorialLayout>
  )
}

// dynamic exporting solved the hydration bug
const AdvertorialTemplate = dynamic(Promise.resolve(AdvertorialTemplateSSR), {
  ssr: false,
})

export { AdvertorialTemplate }
