import React from 'react'
import throttle from 'lodash/throttle'
import { Context } from '../../../context'
import { useUserContext } from '@/hooks'

export const CookieDisclaimer = () => {
  const [bgColor, setBgColor] = React.useState('bg-blue-navy')
  const [textColor, setTextColor] = React.useState('text-white')
  const [closeIcon, setCloseIcon] = React.useState('light')
  const [userContext, setUserContext] = useUserContext()
  const { clientLocation } = React.useContext(Context)
  const [open, setOpen] = React.useState(false)

  const disclaimerEl: React.LegacyRef<HTMLDivElement> | undefined =
    React.useRef(null)
  let footerEl: HTMLElement | null
  let stickyCtaEl: HTMLElement | null

  React.useEffect(() => {
    if (clientLocation?.status === 'done') {
      if (
        !userContext.acceptCookie &&
        (clientLocation.state === 'CA' || !clientLocation.state)
      ) {
        setOpen(true)
      }
    }
  }, [clientLocation?.status])

  React.useEffect(() => {
    footerEl = document.getElementsByTagName('footer')[0]
    stickyCtaEl = document.getElementById('sticky-cta')
    setDynamicStyle()

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleClose = () => {
    setUserContext({ acceptCookie: true })
    setOpen(false)
  }

  const setLightBg = () => {
    setBgColor('bg-white')
    setTextColor('text-blue-navy')
    setCloseIcon('dark')
  }

  const setDarkBg = () => {
    setBgColor('bg-blue-navy')
    setTextColor('text-white')
    setCloseIcon('light')
  }

  const setDynamicStyle = () => {
    if (disclaimerEl && disclaimerEl.current && footerEl) {
      if (
        !stickyCtaEl &&
        disclaimerEl.current.getBoundingClientRect().top >
          footerEl.getBoundingClientRect().top
      ) {
        setLightBg()
      } else if (!stickyCtaEl) {
        setDarkBg()
      } else if (
        stickyCtaEl &&
        stickyCtaEl.getBoundingClientRect().top >
          footerEl.getBoundingClientRect().top
      ) {
        setDarkBg()
      } else {
        setLightBg()
      }
    }
  }

  const handleScroll = throttle(setDynamicStyle, 100)

  return (
    <>
      {open && (
        <div
          id="cookie-disclaimer"
          className={`${bgColor} lg:rounded-xs fixed left-0 bottom-0 z-30 flex w-full flex-grow flex-wrap items-center justify-start p-3 text-sm shadow-2xl transition duration-200 ease-in lg:left-2 lg:bottom-2 lg:max-w-xs lg:py-4 lg:px-5`}
          role="alert"
          ref={disclaimerEl}
        >
          <div
            className={`xs:text-center mr-8 mb-0 w-full text-left leading-normal ${textColor} lg:text-left lg:leading-5 lg:tracking-wide`}
          >
            By using our website you are consenting to our use of cookies in
            accordance with our{' '}
            <a
              className="text-blue-primary hover:underline"
              href="/privacy-policy/"
              rel="noopener"
              target="_blank"
            >
              Privacy&nbsp;Policy
            </a>
          </div>

          <button
            aria-label="close"
            className="py-auto flex-end absolute top-0 right-0 mt-2 mr-2 flex items-center opacity-50 transition-all hover:opacity-100 md:top-auto md:mt-0 lg:top-0 lg:mt-2"
            type="button"
            onClick={handleClose}
          >
            <div className="inline-block">
              <img alt="" src={`/static/icons/icon-close-${closeIcon}.svg`} />
            </div>
          </button>
        </div>
      )}
    </>
  )
}
