import React from 'react'
import { SelectGrid } from '../../'
import { handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

interface Question {
  heading: string
  key: string
  name: string
}

const QUESTIONS: Question[] = [
  {
    heading: 'Two (2) or more tickets?',
    key: 'tickets',
    name: 'incidents_tickets',
  },
  {
    heading: 'DUI',
    key: 'dui',
    name: 'incidents_dui',
  },
  {
    heading: 'Accident',
    key: 'accident',
    name: 'incidents_accident',
  },
  {
    heading: 'Claim',
    key: 'claim',
    name: 'incidents_claim',
  },
  {
    heading: 'Suspension',
    key: 'suspension',
    name: 'incidents_suspension',
  },
]
interface Props extends RateQuote.FormPanel {
  anyIncident: string | null
  handleSubmit: any
  setAnyIncident: React.Dispatch<React.SetStateAction<string | null>>
}

export function IncidentsPanel(props: Props) {
  const {
    anyIncident,
    handleInput,
    handleSubmit,
    input,
    setAnyIncident,
    showBtnLoader,
    value,
  } = props

  const handleChange = (e: string) => {
    setAnyIncident(e)
    e !== '1' && handleSubmit(e)
  }

  return (
    <>
      {anyIncident === '1' ? (
        <div>
          {QUESTIONS.map((question) => (
            <div
              className="mb-2 flex w-full flex-col items-center"
              key={question.name}
            >
              <label className="w-full text-center text-lg md:text-left">
                {question.heading}
              </label>
              <SelectGrid
                columns="grid-cols-2"
                items={[
                  [
                    {
                      label: 'Yes',
                      value: '1',
                    },
                    {
                      label: 'No',
                      value: '0',
                    },
                  ],
                ]}
                onChange={(val) => handleInput(val, question.key)}
                value={value[question.key] || ''}
              />
            </div>
          ))}
        </div>
      ) : (
        <SelectGrid
          columns="grid-cols-2"
          items={[
            [
              {
                label: 'Yes',
                value: '1',
              },
              {
                label: 'No',
                value: '0',
              },
            ],
          ]}
          loading={showBtnLoader}
          onChange={handleGridButtonSelect(input, handleChange)}
          value={anyIncident || ''}
        />
      )}
    </>
  )
}
