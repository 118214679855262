import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

interface Props {
  answer: string
  question: string
}

export const Accordion = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleToggle = () => setIsOpen(!isOpen)

  const answerRef = React.useRef<HTMLDivElement>(null)

  // this creates the slide-up-and-down effect
  React.useEffect(() => {
    if (answerRef.current) {
      if (isOpen) {
        answerRef.current.style.maxHeight =
          answerRef.current.scrollHeight + 'px'
        answerRef.current.style.marginTop = '16px'
      } else {
        answerRef.current.style.maxHeight = '0px'
        answerRef.current.style.marginTop = '0px'
      }
    }
  }, [isOpen])

  return (
    <>
      <div
        className={`bg-white-primary rounded-xs border-grayscale-6 hover:bg-blue-lightblue-6  hover:border-blue-lightblue-7 relative border px-5 py-4 shadow-none hover:shadow-lg sm:px-7`}
      >
        <div
          onClick={handleToggle}
          className="flex w-full cursor-pointer items-center justify-between"
        >
          <h3 className="text-blue-navy mr-3 text-xl font-bold leading-snug sm:leading-loose">
            {props.question}
          </h3>
          <div
            className={`${
              isOpen ? 'bg-grayscale-6 -rotate-180' : ''
            }  group min-w-9 h-9 rounded-full transition delay-75 ease-in-out hover:relative motion-reduce:transition-none`}
            role="button"
            aria-pressed={isOpen}
            tabIndex={0}
            onClick={handleToggle}
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar')
                handleToggle()
            }}
          >
            <FontAwesomeIcon
              className={`text-blue-navy absolute m-2 transition delay-75 ease-in-out motion-reduce:transition-none ${
                isOpen
                  ? 'hidden opacity-0'
                  : 'opacity-40 group-hover:opacity-100'
              }`}
              icon={faPlus}
              style={{ width: '20px', height: '20px' }}
            />
            <FontAwesomeIcon
              className={`text-blue-primary absolute top-0 m-2 transition delay-75 ease-in-out motion-reduce:transition-none ${
                isOpen ? 'opacity-100' : 'hidden opacity-0'
              }`}
              icon={faMinus}
              style={{ width: '20px', height: '20px' }}
            />
          </div>
        </div>
        <p
          ref={answerRef}
          className="text-blue-navy c-expand overflow-hidden text-xl font-normal opacity-40"
        >
          {props.answer}
        </p>
      </div>
      <style jsx>{`
        .c-expand {
          transition: max-height 0.2s ease-out, margin 0.2s ease-out;
        }
      `}</style>
    </>
  )
}
