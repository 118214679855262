import * as Sentry from '@sentry/nextjs'
import { MAKES } from './data'
import { RateQuote } from '../../../types'

// Local Constants
const CHAR_TO_REPLACE = '/'
const REPLACE_WITH = '/​'
export const VPCI_BASE_URL =
  'https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeYear/'

// Interfaces
interface Hash {
  [any: string]: RateQuote.ModelData
}

interface ModelItem {
  label: string
  value: string
}

interface MakeFilter {
  common?: boolean
}

export function makes(filter: MakeFilter = {}): RateQuote.Make[] {
  const transform = (i: RateQuote.MakeType): RateQuote.Make => ({
    ...i,
    name: i.name,
    common: i.common,
    count: 0,
    logoSrc: `/manufacturers/${i.name}/logo.svg`,
  })
  const filterPred =
    filter.common == null
      ? () => true
      : (i: RateQuote.MakeType) => i.common === filter.common
  return MAKES.filter(filterPred).map(transform)
}

// Sorts the models alphabetically for the SearchMatch component
export const getModelsForAutocomplete = (models: Array<ModelItem>) => {
  // Prevent Original Array to Mutate
  const newModels = [...models]
  // Sort Models Alphabetically for SearchMatch
  newModels.sort((a: ModelItem, b: ModelItem) =>
    a.value.toLowerCase() < b.value.toLowerCase()
      ? -1
      : a.value.toLowerCase() > b.value.toLowerCase()
      ? 1
      : 0,
  )
  return newModels
}

export const renderModelOption = (model: RateQuote.ModelData) => ({
  // Replacing / for / plus a zero width character for line breaks
  label: model.name.replace(CHAR_TO_REPLACE, REPLACE_WITH),
  value: model.name.trim(),
})

// Gets the models by make and year and mix the data with current list of models
// Fallback will be local model list
export const getModelsByMakeYear = async (
  fallbackModels: RateQuote.ModelData[],
  make: string,
  year: string,
) => {
  let newModels: RateQuote.ModelData[] = fallbackModels
  try {
    if (make && year) {
      // Fetch the Model List by Make and Year
      const { Results } = await fetchModels(make as string, year as string)
      // Match Vehicle Models with fallback Models to add Click Count
      const apiModels: RateQuote.ModelData[] = []
      Results.forEach((apiModel: { Model_Name: string }, index: number) => {
        apiModels.push({
          id: index,
          name: apiModel.Model_Name,
          count: 0,
        })
      })
      // Then merge models to avoid duplicates and missing ones
      newModels = mergeModelsSources(apiModels, fallbackModels)
    }
  } catch (err) {
    Sentry.captureException(err)
    // Popuplates the list with local data as fallback
    newModels = fallbackModels
  }
  // Sort the List based on Clicks
  newModels &&
    newModels.sort(
      (a: RateQuote.ModelData, b: RateQuote.ModelData) => b.count - a.count,
    )
  return newModels
}
const fetchModels = async (make: string, year: string) => {
  return await fetch(
    `${VPCI_BASE_URL}make/${make}/modelyear/${year}?format=json`,
  )
    .then((res) => {
      if (res.status >= 400) {
        throw new Error(`[ERR]: ${res.statusText}`)
      }
      return res
    })
    .then((res) => res.json())
    .catch((err) => {
      throw new Error(err)
    })
}

// Merging sourceA and sourceB keeping unique values with O(n) complexity
const mergeModelsSources = (
  sourceA: Array<RateQuote.ModelData>,
  sourceB: Array<RateQuote.ModelData>,
) => {
  const hash: Hash = {}
  let x
  for (x = 0; x < sourceA.length; x++) {
    hash[sourceA[x].name] = sourceA[x]
  }
  for (x = 0; x < sourceB.length; x++) {
    // Ensure to only merge models that exist on sourceA
    if (hash[sourceB[x].name]) {
      hash[sourceB[x].name] = {
        ...hash[sourceB[x].name],
        ...sourceB[x],
      }
    }
  }
  return Object.values(hash).sort()
}

export const getVehicles = async ({
  year,
  make,
  field,
}: {
  year: any
  make: any
  field: string
}) => {
  const data = await fetch('/api/vehicle-model/', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ year, make, field }),
  })
    .then((result) => {
      return result.json()
    })
    .catch((err) => Sentry.captureException(err))

  return data
}
