import * as Sentry from '@sentry/nextjs'
import qs from 'query-string'
import snakeCase from 'lodash/fp/snakeCase'
import { getQuestionPaths } from './'

const KEYS_TO_EXCLUDE_FROM_URL = Object.freeze([
  'aptSteOther',
  'address',
  'city',
  'contactAddress',
  'contactAptSteOther',
  'contactCity',
  'contactCounty',
  'contactState',
  'contactZip',
  'contactZipSuffix',
  'contactFirstName',
  'contactLastName',
  'contactPhoneNumber',
  'contactEmail',
  'county',
  'majorConditionAidsHiv',
  'majorConditionAlcoholDrugAbuse',
  'majorConditionAlzheimersDementia',
  'majorConditionAsthma',
  'majorConditionCancer',
  'majorConditionChronicPain',
  'majorConditionClinicalDepression',
  'majorConditionDiabetes',
  'majorConditionEmphysema',
  'majorConditionEpilepsy',
  'majorConditionHeartAttack',
  'majorConditionHepatitisLiver',
  'majorConditionHighBloodPressure',
  'majorConditionHighCholesterol',
  'majorConditionKidneyDisease',
  'majorConditionMentalIllness',
  'majorConditionMultipleSclerosis',
  'majorConditionNone',
  'majorConditionOther',
  'majorConditionRespiratoryDisorder',
  'majorConditionStroke',
  'majorConditionUlcers',
  'majorConditionVascularDisease',
  'state',
  'zipcode',
  'zipSuffix',
])

/**
 * Return the relative path (example: './get-quote/driver/gender') for the next
 * question in the form flow.
 */
export function pathForNextStep(
  currentStep: number,
  form: string,
  params?: any,
  subpath?: string,
) {
  const basePath = getQuestionPaths(form)[currentStep]
  if (basePath == null || typeof window === 'undefined') {
    Sentry.captureException('Could not find next path for step')
    return {
      pathname: `/${form}`,
    }
  }

  const path = subpath ? `${basePath}/${subpath}` : basePath

  const previousValues = JSON.parse(
    JSON.stringify(qs.parse(location.search) || {}),
  )

  const entries = params
    ? Object.entries(params).reduce((acc, val) => {
        const [key, value] = val
        if (KEYS_TO_EXCLUDE_FROM_URL.includes(key)) {
          return acc
        }
        acc[snakeCase(key)] =
          typeof value === 'object' ? JSON.stringify(value) : value
        return acc
      }, previousValues)
    : previousValues

  return {
    pathname: `/${form}/${path}`,
    query: {
      ...entries,
      step: currentStep + 1,
    },
  }
}
