import React from 'react'
import { Img } from '@/components/atoms'

export interface HeroImgProps {
  altText: string
  src: string
}

export const HeroImage = ({ altText, src }: HeroImgProps) => {
  return (
    <div className="max-w-6-1/2xl lg:max-h-500px max-h-258px -mx-5 h-full rounded-lg sm:-mx-0">
      <Img
        alt={altText}
        classes="lg:max-h-500px max-h-258px w-full rounded-lg mx-auto object-contain"
        skeleton={{
          classes: 'lg:min-h-500px min-h-258px rounded-lg',
        }}
        src={src}
      />
    </div>
  )
}
