/**
 * Convert an object representing query parameters to a query string. If any
 * nullish values are encountered they are omitted from the query string.
 *
 *  @example
 *  paramsToQueryString({ foo: 'baz', fizz: 'buzz' }) // 'foo=baz&fizz=buzz'
 */
export function paramsToQueryString<
  T extends Record<string, string | string[]>,
>(params: T): string {
  const encoder = (value: string | string[] | number) =>
    typeof value === 'string'
      ? value
      : typeof value === 'number'
      ? String(value)
      : value.join(',')

  return Object.keys(params)
    .map((k) => (params[k] == null ? undefined : `${k}=${encoder(params[k])}`))
    .filter((i) => i != null)
    .join('&')
}
