import React from 'react'
import { SelectGrid } from '../..'
import { handleGridButtonSelect } from '@/utils'
import { RateQuote } from '../../../../types'

interface Props_ {
  required?: boolean
  userDidSubmit?: boolean
}

export function TobaccoPanel(props: RateQuote.FormPanel & Props_) {
  const { showBtnLoader } = props

  return (
    <>
      <SelectGrid
        columns="grid-cols-1 sm:grid-cols-2"
        items={[
          [
            {
              label: 'Yes',
              value: '1',
            },
            {
              label: 'No',
              value: '0',
            },
          ],
        ]}
        loading={showBtnLoader}
        onChange={handleGridButtonSelect(props.input, props.handleInput)}
        value={props.value || ''}
      />
      {props.userDidSubmit && !props.value && props.required && (
        <div className="text-red-error mt-6">Please select Yes or No.</div>
      )}
    </>
  )
}
