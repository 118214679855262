import React from 'react'
import { SelectGrid } from '../../'
import { handleGridButtonSelect } from '@/utils'
import { RateQuote, Segment } from '../../../../types'

interface Props extends RateQuote.FormPanel {
  category: Segment.Category
  children?: React.ReactNode
}

export function CoverageType(props: Props) {
  const { showBtnLoader, category, children } = props

  const items: {
    'health-insurance': { label: string; value: string }[]
    u65: { label: string; value: string }[]
  } = {
    'health-insurance': [
      { label: 'Medicare Advantage', value: 'medicare_advantage' },
      { label: 'Medicare Supplemental', value: 'medicare_supplemental' },
      { label: 'Medicare Part D', value: 'medicare_part_d' },
      { label: 'Other Medicare', value: 'other_medicare' },
    ],
    u65: [
      { label: 'Individual Family', value: 'individual_family' },
      { label: 'Short Term', value: 'short_term' },
      { label: 'COBRA', value: 'cobra' },
      { label: 'Discount Plan', value: 'discount_plan' },
      { label: 'Medicaid', value: 'medicaid' },
      { label: 'Maternity Only', value: 'maternity_only' },
      { label: 'Dental Only', value: 'dental_only' },
      { label: 'Vision Only', value: 'vision_only' },
      { label: 'Prescription Only', value: 'prescription_only' },
      { label: 'Other Health', value: 'other_health' },
    ],
  }

  return (
    <div>
      <SelectGrid
        columns={
          category === 'health-insurance'
            ? 'grid-cols-1'
            : 'grid-cols1 xs:grid-cols-2'
        }
        itemContainerClasses="max-w-xs mx-auto"
        items={[items[category as keyof typeof items]]}
        loading={showBtnLoader}
        onChange={handleGridButtonSelect(props.input, props.handleInput)}
        value={props.value || ''}
      />
      {children && children}
    </div>
  )
}
