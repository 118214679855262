import { getQuestionPaths } from '.'

export function progress(
  path: string,
  form: string,
): { step: number; denominator: number } {
  const step = getQuestionPaths(form).indexOf(path)
  const denominator = getQuestionPaths(form).length

  return {
    step: step + 1,
    denominator: denominator - 1,
  }
}
