import React from 'react'

export function TailwindIndicator() {
  const isProduction = process.env.NODE_ENV === 'production'

  if (isProduction) return null

  return (
    <div className="fixed bottom-1 left-1 z-50 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800 p-3 font-mono text-xs text-white">
      <div className="xs:hidden block">2xs</div>
      <div className="xs:block hidden sm:hidden">xs</div>
      <div className="hidden sm:block md:hidden">sm</div>
      <div className="hidden md:block lg:hidden">md</div>
      <div className="hidden lg:block xl:hidden">lg</div>
      <div className="xxl:hidden hidden xl:block">xl</div>
      <div className="xxl:block xxxl:hidden hidden">2xl</div>
      <div className="xxxl:block hidden">3xl</div>
    </div>
  )
}
