import React from 'react'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { RateQuote } from '../../../../types'
import { InputBox } from '../../'

interface Props_ {
  validate: ({
    key,
    force,
  }: {
    key: string
    force?: boolean | undefined
  }) => boolean
}

type Props = Props_ & Omit<RateQuote.FormPanel, 'input'>

export function ContactNamePanel(props: Props) {
  const questions = [
    {
      label: 'first name',
      id: 'first-name',
      name: 'fName',
    },
    {
      label: 'last name',
      id: 'last-name',
      name: 'lName',
    },
  ]

  return (
    <div className="flex w-full flex-col items-center">
      {questions.map((field) => (
        <div
          key={field.id}
          className="max-w-84 mb-4 flex w-full flex-col items-start"
        >
          <InputBox
            id={`get-quotes--${field.id}`}
            error={!props.validate({ key: field.id })}
            errorMsg={`Please enter your ${field.label}.`}
            name={`${field.id}`}
            type="text"
            value={props.value[field.name]}
            onChange={(val: string) => {
              props.handleInput(val, field.name)
            }}
            placeholder={startCase(toLower(field.label))}
          />
        </div>
      ))}
    </div>
  )
}
