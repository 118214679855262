import React from 'react'
import { startCase, toLower } from 'lodash'

import { InputBox } from '../../'
import { phoneMask } from '@/utils'
import { RateQuote } from '../../../../types'

interface Props_ {
  handleInput: (val: string, key?: string | undefined) => void
  validate: ({
    key,
    force,
  }: {
    key: string
    force?: boolean | undefined
  }) => boolean
}

interface questionsType {
  id: string
  inputMode:
    | 'decimal'
    | 'email'
    | 'none'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url'
    | undefined
  label: string
  mask?: string | ((val: string) => string)
  name: string
  pattern?: string
  type?: 'email' | 'name' | 'tel'
  error?: string
}

type Props = Props_ & Omit<RateQuote.FormPanel, 'input'>

export const ContactNameMethodPanel = (props: Props) => {
  const { validate, value } = props
  const questions: questionsType[] = [
    {
      id: 'first-name',
      inputMode: 'text',
      label: 'First Name',
      name: 'fName',
      type: 'name',
      error: 'Please enter your first name.',
    },
    {
      id: 'last-name',
      inputMode: 'text',
      label: 'Last Name',
      name: 'lName',
      type: 'name',
      error: 'Please enter your last name.',
    },
    {
      id: 'email',
      inputMode: 'email',
      label: 'Email Address',
      name: 'email',
      type: 'email',
      error: 'Please enter a valid email address.',
    },
    {
      id: 'phone',
      inputMode: 'numeric',
      label: 'Phone Number',
      mask: phoneMask,
      name: 'phone',
      pattern: '[0-9]*',
      type: 'tel',
      error: 'Please enter a valid phone number.',
    },
  ]

  return (
    <div className="flex w-full flex-col items-center">
      {questions.map((field: questionsType) => (
        <div
          key={field.id}
          className="max-w-84 mb-4 flex w-full flex-col items-start"
        >
          {field.label}
          <InputBox
            error={!validate({ key: field.id })}
            errorMsg={field.error}
            id={`get-quotes--${field.id}`}
            inputMode={field.inputMode}
            mask={field.mask}
            name={`${field.id}`}
            onChange={(val: string) => {
              props.handleInput(val, field.name)
            }}
            pattern={field.pattern}
            placeholder={startCase(toLower(field.label))}
            type={field.type}
            value={value[field.name]}
          />
        </div>
      ))}
    </div>
  )
}
