// eslint-disable-next-line @typescript-eslint/no-var-requires
const crypto = require('crypto')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { encryptedRQ } = require('./gcp-rq.env')
const algorithm = 'aes-128-cbc'

const getDecryptedPrivateKey = () => {
  const decipher = crypto.createDecipheriv(
    algorithm,
    process.env.SERVICE_ENCRYPTION_KEY,
    process.env.SERVICE_ENCRYPTION_IV,
  )
  let decrypted = decipher.update(encryptedRQ, 'base64', 'utf8')
  decrypted += decipher.final('utf8')
  return decrypted
}

module.exports = {
  getDecryptedPrivateKey,
}
