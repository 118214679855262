export function isCallCenterOpen(): boolean {
  const currentTime = new Date()
  // Convert local time to UTC, then adjust for EST (UTC-5)
  const estDate = new Date(currentTime)
  estDate.setUTCHours(currentTime.getUTCHours() - 5)

  const estDay = estDate.getUTCDay()
  const estHours = estDate.getUTCHours()
  // Check if the call center is open based on EST
  if (estHours >= 9 && estHours < 19) {
    // Between 9 am and 6 pm
    switch (estDay) {
      case 0: // Sunday
        return true
      case 1: // Monday
      case 2: // Tuesday
      case 3: // Wednesday
      case 4: // Thursday
      case 5: // Friday
      case 6: // Saturday
        return true
      default:
        throw new Error('Unhandled day')
    }
  } else {
    return false
  }
}

export function getDefaultCallCenterValue(): number {
  return Math.floor(Math.random() * (50 - 40) + 40)
}

export function getIncrementalLiveAgents(liveAgents: number): number {
  const operator = Math.floor(Math.random() * (200 - 1) + 1) % 2 === 0 ? 1 : -1
  const randomIncrement = Math.floor(Math.random() * (3 - 1) + 1)
  return liveAgents + randomIncrement * operator
}
