export function dobMask(value: string): string {
  const numericValue = value.replace(/\D/g, '').substring(0, 8)

  const month = numericValue.substring(0, 2)
  const day = numericValue.substring(2, 4)
  const year = numericValue.substring(4)

  return (
    (month.length === 2 ? month + '/' : month) +
    (day.length === 2 ? day + '/' : day) +
    year
  )
}
